import React, { useEffect, useState } from "react";
import {
  TextField,
  InputLabel,
  Button,
  MenuItem,
  FormControl,
  Grid,
  Box,
  Tooltip
} from "@mui/material";
import { API_ENDPOINT } from "../constants";
import {
  exporterSetAsset,
  exporterGetAsset,
  exporterDeleteAsset,
} from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { DeleteOutline, EmailRounded, LinkRounded, } from "@material-ui/icons";

import "../app.css";

function Grower({ companyId, companyName, companyType }) {
  const [newAsset, setNewAsset] = useState({});
  const dispatch = useDispatch();

  const assets = useSelector((state) => state.assets.growers) || [];
  useEffect(() => {
    dispatch(exporterGetAsset("grower", companyId));
  }, [companyId]);

  const saveAsset = () => {
    dispatch(exporterSetAsset("grower", newAsset, companyId));
  };

  const deleteAsset = (id) => {
    dispatch(exporterDeleteAsset("grower", id, companyId));
  };

  const inviteGrower = (id) => {};

  const generatePermaLink = (growerId) => {};

  return (
    <>
      <Box width={"80%"}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="filled-basic"
              label="Código"
              variant="filled"
              style={{ margin: "4px" }}
              value={newAsset.code || ""}
              inputProps={{ maxLength: 15 }}
              onInput={(e) => {
                const newValue = e.target.value;
                setNewAsset({
                  ...newAsset,
                  code: newValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              id="filled-basic"
              label="Razón social"
              variant="filled"
              style={{ margin: "4px" }}
              value={newAsset.name || ""}
              onInput={(e) => {
                const newValue = e.target.value;
                setNewAsset({
                  ...newAsset,
                  name: newValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="filled-basic"
              label="RUT"
              variant="filled"
              style={{ margin: "4px" }}
              value={newAsset.rut || ""}
              onInput={(e) => {
                const newValue = e.target.value;
                setNewAsset({
                  ...newAsset,
                  rut: newValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="filled-basic"
              label="Correos electrónicos"
              variant="filled"
              style={{ margin: "4px" }}
              value={newAsset.rut || ""}
              onInput={(e) => {
                const newValue = e.target.value;
                setNewAsset({
                  ...newAsset,
                  email: newValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                saveAsset();
              }}
            >
              Crear
            </Button>
          </Grid>
        </Grid>
        {/*  */}
      </Box>
      <table className="table" style={{ width: "80%" }}>
        <thead>
          <tr>
            <th>Código</th>
            <th>Razón social</th>
            <th>RUT</th>
            <th>Email</th>
            <th>Eliminar</th>
            <th>Invitar</th>
            <th>URL Acceso</th>
          </tr>
        </thead>
        <tbody>
          {assets.map((a, i) => {
            return (
              <tr key={i}>
                <td>{a.code}</td>
                <td>{a.name}</td>
                <td>{a.rut}</td>
                <td>{a.email}</td>
                <td>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      deleteAsset(a.id);
                    }}
                  >
                    <DeleteOutline />
                  </Button>
                </td>
                <td>
                  {a.company_ref_id > 0 ? (
                    "Vinculado a " + a.company_ref.display_name
                  ) : (
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => {
                        inviteGrower(a.id);
                      }}
                    >
                      <EmailRounded />
                    </Button>
                  )}
                </td>
                <td>
                  <Tooltip title="Genera un vinculo permanante para dar acceso a los documentos">
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => {
                        generatePermaLink(a.id);
                      }}
                    >
                      <LinkRounded />
                    </Button>
                  </Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default Grower;
