import React, { useEffect, useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { API_ENDPOINT } from "../constants";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { CardActionArea } from "@mui/material";

interface RoleCardProps {
  role: IRole;
  onRoleSelected: Function;
}

interface IRole {
  companyName: string;
  companyTypeId: number;
  companyId: number;
  accessTypeId: number;
}

function asignColor(seed: number) {
  const colors = [
    "#1F7A8C",
    "#0057B8",
    "#003865",
    "#8C86AA",
    "#1F7A8C",
    "#DD8136",
  ];
  return colors[seed % colors.length];
}

export default function RoleCard({ role, onRoleSelected }: RoleCardProps) {
  const [logoUri, setLogoUri] = React.useState<string | null>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    axios
      .get(`${API_ENDPOINT}${"company/"}${role.companyId}${"/logo"}`)
      .then(function (response) {
        setLogoUri(response.data.url);
      })
      .catch(() => setLogoUri("broken.png"));
  }, []);

  return (
    <Card
      sx={{
        maxWidth: 240,
        minWidth: 240,
        backgroundColor: asignColor(role.companyTypeId),
        height: "30vh",
        display: "flex",
        flexDirection: "column",
        ml: "8px",
        mr: "8px",
        borderRadius: "8px",
        boxShadow: "5px 0px 5px 0px rgba(0,0,0,0.75)",
      }}
    >
      <CardActionArea onClick={() => onRoleSelected()} sx= {{ height: "100%"}}>
        {logoUri != null ? (
          <Avatar
            sx={{
              bgcolor: asignColor(role.companyTypeId),
              height: "8vh",
              width: "8vh",
              margin: 2,
              objectFit: "cover",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)"
            }}
            alt={role.companyName}
            src={logoUri}
          >
            <Typography variant="h4" component="h4" color={"white"}>
              {role.companyName[0]}
            </Typography>
          </Avatar>
        ) : (
          <Skeleton variant="rectangular" height={140} />
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" color={"white"}>
            {role.companyName}
          </Typography>
          <Typography variant="body1"  color={"white"}>
            Type: {t("common.companyType." + role.companyTypeId)}
          </Typography>
          <Typography variant="body1"  color={"white"}>
            Access: {t("common.userAccessType." + role.accessTypeId)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
