import { Route } from "react-router-dom";

import SignInSide from './../containers/login-side'
import SignInForm from './../components/auth-signin-form'
import SignUpForm from './../components/auth-signup-form'
import ConfirmCodeForm from './../components/auth-confirm-code'
import ChangePasswordForm from './../components/auth-change-password'

export default  <Route path="/auth">
                        <Route exact path="/auth" element={<SignInSide />}>
                            <Route index element={
                                        <SignInForm />
                                } />
                                
                              <Route path="login" element={
                                    <SignInForm />
                              }/>
                              <Route path="register" element={
                                    <SignUpForm />
                              }/>
                             <Route path="confirmRegister" element={
                                    <ConfirmCodeForm />
                              }/>
                              <Route path="passwordRecovery" element={
                                    <ChangePasswordForm />
                              }/>
                        </Route>
                </Route>
