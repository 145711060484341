import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setLayout } from '../actions';
import { API_ENDPOINT } from '../constants'
import axios from 'axios'
import moment from 'moment'

import { saveAs } from 'file-saver';
import * as Excel from "exceljs";

const getCellByName = (worksheet, name) => {
  let match
  worksheet.eachRow(row => row.eachCell(cell => {
    if (cell.names.find(n => n === name)) {
      match = cell
    }
  }))
  return match
}

function getBase64Image(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  var dataURL = canvas.toDataURL("image/png");
  return dataURL
}

const generateExcel = async (boarding, logo) => {
  var wb = new Excel.Workbook();

  let response = await fetch('/proforma_template.xlsx');
  console.log("Template fetched.");
  let originalData = await response.arrayBuffer();

  await wb.xlsx.load(originalData);

  const ws = wb.getWorksheet("PROFORMA")

  //Agregar el logo
  var base64Logo = ""
  try {
    base64Logo = getBase64Image(document.getElementById("logoImg"));
    if (base64Logo !== 'data:,') {
      console.log("Logo base64 ok.")

      const logoId = wb.addImage({
        filename: base64Logo,
        extension: 'png',
      });

      ws.addImage(logoId, 'C8:E11');
    }
  }
  catch {
    console.log("Base64", base64Logo.substring(0, 50))
    console.log("No se pudo cargar el logo de la empresa");
  }


  //Fecha de emisión = fecha en packing
  ws.getCell('K11').value = moment(boarding.dateInPacking).format("DD-MM-YYYY");
  ws.getCell('C5').value = boarding.exporter && boarding.exporter.name;
  ws.getCell('C6').value = boarding.exporter && boarding.exporter.address;
  ws.getCell('C7').value = "RUT: " + (boarding.exporter && boarding.exporter.rut);

  ws.getCell('K6').value = "RUT: " + (boarding.exporter && boarding.exporter.rut);

  //Consignee
  ws.getCell('C12').value = boarding.consignee && boarding.consignee.name;
  ws.getCell('C14').value = boarding.consignee && boarding.consignee.address;

  //Numero proforma y embarque son iguales
  ws.getCell('K8').value = boarding.code;
  ws.getCell('K12').value = boarding.code;

  //Primera linea
  ws.getCell('C21').value = boarding.saleType;
  ws.getCell('E21').value = boarding.saleClause;
  ws.getCell('I21').value = boarding.paymentType;
  ws.getCell('K21').value = boarding.transport;

  //Segunda linea
  ws.getCell('C26').value = boarding.harbourIn && boarding.harbourIn.name;
  ws.getCell('E26').value = boarding.countryFrom && boarding.countryFrom.name;
  ws.getCell('I26').value = boarding.harbourOut && boarding.harbourOut.name;
  ws.getCell('K26').value = boarding.countryTo && boarding.countryTo.name;

  //Tercera linea
  ws.getCell('C31').value = boarding.vessel && boarding.vessel.name;
  ws.getCell('E31').value = boarding.container;
  const totalNetWeight = boarding.details && boarding.details
    .map((x) => { return (Math.round(x.packaging && x.packaging.net_weight * x.boxes * 100) / 100) })
    .reduce((a, b) => {
      return a + b
    }, 0);

  const totalRawWeight = boarding.details && boarding.details
    .map((x) => { return (Math.round(x.packaging && x.packaging.raw_weight * x.boxes * 100) / 100) })
    .reduce((a, b) => {
      return a + b
    }, 0);
  ws.getCell('I31').value = totalNetWeight;
  ws.getCell('K31').value = totalRawWeight;


  const startRow = 36;
  let currentRowIndex = 0;
  for (let k in boarding.details) {
    const row = startRow + currentRowIndex;
    const entry = boarding.details[k];

    ws.getCell('C' + row).value = entry.specie && entry.specie.name;
    ws.getCell('D' + row).value = entry.variety && entry.variety.name;
    ws.getCell('E' + row).value = entry.packaging && entry.packaging.version;
    ws.getCell('F' + row).value = entry.size && entry.size.name;
    ws.getCell('G' + row).value = entry.boxes;
    ws.getCell('H' + row).value = entry.packaging && entry.packaging.net_weight;
    ws.getCell('I' + row).value = entry.packaging && entry.packaging.raw_weight;
    ws.getCell('J' + row).value = 'CAT1'
    ws.getCell('K' + row).value = entry.unitPrice;

    currentRowIndex = currentRowIndex + 1;
  }

  const buffer = await wb.xlsx.writeBuffer();
  var blob = new Blob([buffer]);
  saveAs(blob, 'daiahub-proforma-' + boarding.id + '.xlsx');
}

export default function BoardingBill({ boardingId }) {
  const dispatch = useDispatch();

  const company = useSelector(state => state.currentUser.selectedRoleDisplay.company);
  const companyId = company.id;

  const [boarding, setBoarding] = useState({});
  const [companyLogo, setCompanyLogo] = useState("broken.png");

  const loadTargetBoarding = () => {
    axios.get(`${API_ENDPOINT}${'exporter/asset/company/'}${companyId}${'/boarding/'}${boardingId}`)
      .then(function (response) {
        setBoarding(response.data);
      });
  }

  useEffect(() => {
    dispatch(setLayout("print"));
    loadTargetBoarding();

    axios
      .get(`${API_ENDPOINT}${"company/"}${companyId}${"/logo"}`)
      .then(function (response) {
        setCompanyLogo(response.data.url);
      })
      .catch(() => setCompanyLogo("broken.png"));
  }, [boardingId]);


  return (
    <div>
      <img id="logoImg" src={companyLogo} height={100} hidden="hidden"></img>
      <button type="button" onClick={() => { generateExcel(boarding); }}>Descargar proforma</button>
    </div>
  )
}
