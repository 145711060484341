import React, { Component } from 'react'
import { API_ENDPOINT } from '../constants'
import axios from 'axios'
import '../app.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCheck } from '@fortawesome/free-solid-svg-icons'

class RUTPicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rutFormat: false,
            company: {}
        }
    }

    validateRUT(e) {
        let rut = e.target.value
        let self = this

        if (rut.length > 8) {
            if (rut.indexOf('-') > 0) {
                this.setState({ ...self.state, rutFormat: true })
                axios.get(`${API_ENDPOINT}${'company/rut'}`, {
                    params: {
                        rut: rut
                    }
                }
                ).then(function (response) {
                    //Encontramos una empresa
                    if (response.status === 200) {
                        self.setState({ ...self.state, validation: self.props.mustExists, company: response.data })
                        if (typeof (self.props.onValidate) === "function") {
                            self.props.onValidate(rut)
                        }
                    }
                }).catch(function (err) {
                    self.setState({ ...self.state, validation: self.props.checkDuplicity, company: err.response.data })
                    if (typeof (self.props.onValidate) === "function") {
                        self.props.onValidate(rut)
                    }
                })
            }
        }
    }

    render() {
        return (
            <div className='input-group mb-3'>
                <input type="text" placeholder="XX.XXX.XXX-X" aria-describedby="rutHelp" className='form-control' onInput={(e) => { this.validateRUT(e) }} />
                <div className="input-group-append">
                    <span className="">
                        {!this.state.validation &&
                            <button type="button" className="btn btn-outline-secondary">
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </button>
                        }
                        {
                            this.props.mustExists && this.state.rutFormat && !this.state.validation &&
                            <span className="alert alert-warning">
                                El RUT ingresado no existe.
                            </span>
                        }
                        {
                            this.props.checkDuplicity && this.state.rutFormat && !this.state.validation &&
                            <span className="alert alert-warning">
                                El RUT ingresado ya existe.
                            </span>
                        }

                        {this.state.validation &&
                            <button type="button" className="btn btn-success muted">
                                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            </button>
                        }
                    </span>
                </div>
            </div>
        )
    }
}

export default RUTPicker;