import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppBarOptions, setPeriod } from "../actions";
import ExporterNav from "./exporter-nav";
import "../exporter.css";
import { Box, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { API_ENDPOINT } from "../constants";

function ExporterMain({ children }) {
  const dispatch = useDispatch();
  const periodId = useSelector((state) => state.currentUser.periodId);
  const companyId = useSelector(
    (state) => state.currentUser.selectedRoleDisplay.company.id
  );

  const buildAppBarOptions = (periods) => {
    //Ultimo periodo agregado
    const firstEnabled = Math.max(
      periods.filter((x) => x.enabled === 1).map((x) => x.id)
    );
    return [
      <Box display={"flex"} flexDirection={"row"}>
        <InputLabel
          id="period-select"
          color="primary"
          variant="outlined"
          sx={{ paddingRight: 4, color: "white" }}
        >
          Periodo
        </InputLabel>
        <Select
          sx={{ backgroundColor: "white", width: "240px", height: "48px" }}
          id="period-select"
          value={periodId || firstEnabled}
          onChange={(e) => {
            dispatch(setPeriod(e.target.value));
          }}
        >
          {periods.map((x) => (
            <MenuItem key={x.id} value={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </Box>,
    ];
  };

  useEffect(() => {
    //@ts-ignore
    axios
      .get(
        `${API_ENDPOINT}${"/exporter/asset/company/"}${companyId}${"/period"}`
      )
      .then(function (payload) {
        const periods = payload.data;
        const appBarOptions = buildAppBarOptions(periods);

        dispatch(setAppBarOptions(appBarOptions));
        if (periodId == null) {
          const firstEnabled = periods.filter((x) => x.enabled === 1)[0].id;
          dispatch(setPeriod(firstEnabled));
        }
      });
  }, [periodId]);

  return (
    <React.Fragment>
      <ExporterNav />
      {children}
    </React.Fragment>
  );
}
export default ExporterMain;
