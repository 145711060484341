import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  Grid,
  Typography,
  Divider,
  Box,
  Paper,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import GenericLoader from "../components/generic-loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  DirectionsBoatOutlined,
  VerticalAlignTopSharp,
  PublicSharp,
  LocationOnSharp,
} from "@material-ui/icons";
import { API_ENDPOINT } from "../constants";
import moment from "moment";
import axios from "axios";

const VesselMarker = ({ lat, lng, name }) => (
  <>
    <Button variant="contained" size="small">
      <DirectionsBoatOutlined fontSize="small" />
    </Button>
  </>
);

interface Tracking {
  id: number;
  ETA: string;
  vessel: VesselData;
  portIn: string;
  portOut: string;
  epoch: number;
}

interface VesselData {
  lng: number;
  lat: number;
  name: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ArrivalsHeaders = () => {
  return (
    <>
      <Grid item sm={2}>
        <Typography variant={"h6"} color={"primary"}>
          Vessel
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography variant={"h6"} color={"primary"}>
          ETA
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography variant={"h6"} color={"primary"}>
          Port
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography variant={"h6"} color={"primary"}>
          Documents
        </Typography>
      </Grid>
      <Grid item sm={4}></Grid>
    </>
  );
};

const ArrivalItem = ({ track, companyId, locateVessel }) => {
  if (track.vessel) {
    return (
      <>
        <Grid item sm={2}>
          {track.vessel.name}
        </Grid>
        <Grid item sm={2}>
          {moment(track.ETA).format("Y-MM-DD")}
        </Grid>
        <Grid item sm={2}>
          {track.portOut}
        </Grid>
        <Grid item sm={2}>
          {track.documents}
        </Grid>
        <Grid item sm={4}>
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <Link
                to={"/modules/exporter/boarding/" + track.id + "/documents"}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" color="primary">
                  View
                </Button>
              </Link>
            </Grid>
            <Grid item sm={6}>
              <Button
                variant="contained"
                color="info"
                onClick={() => locateVessel(track.vessel.imo, companyId)}
              >
                <LocationOnSharp />
                Locate vessel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid item sm={3}>
          Loading...
        </Grid>
        <Grid item sm={3}></Grid>
        <Grid item sm={3}></Grid>
        <Grid item sm={3}></Grid>
      </>
    );
  }
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/**
      Renvoyer a https://www.searates.com/container/tracking/?container=TTNU8117753&sealine=AUTO
      */

function ExporterTracking() {
  const defaultProps = {
    center: {
      lat: 46.07873955245157,
      lng: 4.7733603901823445,
    },
    zoom: 0,
  };

  const company = useSelector(
    // @ts-ignore: No tenemos interfaz para definir el objeto state de redux
    (state) => state.currentUser.selectedRoleDisplay.company
  );
  const companyId = company.id;

  const [isLoading, setLoading] = useState(false);
  const [trackings, setTrackings] = useState<Tracking[]>([]);
  const [mapCenterAndZoom, setMapCenterAndZoom] = useState({
    ...defaultProps,
    refresh: 0,
  });

  const [value, setValue] = React.useState(0);
  const [fullScreenArrivales, setFullScreenArrivals] = React.useState(true);

  const toggleFullscreenArrivals = () => {
    setFullScreenArrivals(!fullScreenArrivales);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const locateVessel = (vesselImo, companyId) => {
    //Solictamos datos actualizados de una nave
    ///company/<int:company_id>/track/vessel/imo/<int:vessel_imo>
    setLoading(true);
    axios
      .get(
        `${API_ENDPOINT}${
          "track/vessel?imo=" + vesselImo + "&cmp=" + companyId
        }`
      )
      .then(function (response) {
        const vesselData = response.data;
        //Mostramos el mappa y lo centramos sobre la nave
        setMapCenterAndZoom({
          center: { lat: vesselData.lat, lng: vesselData.lng },
          zoom: 8,
          refresh: mapCenterAndZoom.refresh + 1,
        });
        setFullScreenArrivals(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    //Los ultimos 90 días
    setLoading(true);
    axios
      .get(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/tracking?ETA="}${moment()
          .add(-90, "days")
          .format("YYYY-MM-DD")} `
      )
      .then(function (response) {
        setTrackings(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <GenericLoader
        title={"Loading..."}
        buttons={{ cancel: "Cancelar" }}
        openState={isLoading}
      />
      <div
        style={{
          height: "50vh",
          width: "100%",
          display: (fullScreenArrivales && "none") || "block",
        }}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <i>
            This view is still a work in progress, vessels positions might be
            imprecise or non-fonctional.
          </i>
        </div>
        <GoogleMapReact
          key={mapCenterAndZoom.refresh}
          bootstrapURLKeys={{ key: "AIzaSyCnvUu7pnv0OZr02fmVmwPmMVeBCkSRMIA" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={mapCenterAndZoom.center}
          zoom={mapCenterAndZoom.zoom}
        >
          {/* Mapea las naveas a un item en el mapa */}
          {trackings
            .filter((t) => t.vessel.lat)
            .map((t, i) => (
              <VesselMarker
                lat={t.vessel.lat}
                lng={t.vessel.lng}
                name={t.vessel.name}
              />
            ))}
        </GoogleMapReact>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 4 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ marginTop: 1 }}
        >
          <Tab label="Next 5 days" {...a11yProps(0)} />
          <Tab label="Next 30 days" {...a11yProps(1)} />
          <Tab label="All" {...a11yProps(2)} />
          <Button
            variant="contained"
            sx={{ right: 0, position: "absolute" }}
            onClick={() => {
              toggleFullscreenArrivals();
            }}
          >
            {(fullScreenArrivales && <PublicSharp />) || (
              <VerticalAlignTopSharp />
            )}
          </Button>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={1}>
          <ArrivalsHeaders />
          {trackings
            .filter((t) => moment().diff(moment(t.ETA), "days") > -5)
            .filter((t) => moment().diff(moment(t.ETA), "days") <= 0)
            .map((t, i) => (
              <ArrivalItem
                track={t}
                companyId={companyId}
                locateVessel={locateVessel}
              />
            ))}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={1}>
          <ArrivalsHeaders />
          {trackings
            .filter((t) => moment().diff(moment(t.ETA), "days") > -30)
            .filter((t) => moment().diff(moment(t.ETA), "days") <= -5)
            .map((t, i) => (
              <ArrivalItem
                track={t}
                companyId={companyId}
                locateVessel={locateVessel}
              />
            ))}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={1}>
          <ArrivalsHeaders />
          {trackings.map((t, i) => (
            <ArrivalItem
              track={t}
              companyId={companyId}
              locateVessel={locateVessel}
            />
          ))}
        </Grid>
        <i>This shows all arrivals including those of the last 90 days.</i>
      </TabPanel>
    </>
  );
}

export default ExporterTracking;
