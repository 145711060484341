import React, { Component } from 'react';

class ExporterIndex extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return <div> Bienvenido! </div>
    }
}

export default ExporterIndex;