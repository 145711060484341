import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { TextField, Button, MenuItem, FormControl, colors } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from 'react-redux';
import { exporterGetAllAssets, exporterSaveBoardingDoc } from '../actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { API_ENDPOINT, BOARDING_STATES } from '../constants'
import { useTranslation } from "react-i18next";
import axios from 'axios'
import '../app.css';

function BoardingWizard(props) {
  const { t } = useTranslation();
  const companyId = useSelector(state => state.currentUser.selectedRoleDisplay.company.id);
  const periodId = useSelector(state => state.currentUser.periodId);

  const formato = props.format || 'edit';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Documento principal
  const loadTargetBoarding = () => {
    axios.get(`${API_ENDPOINT}${'exporter/asset/company/'}${companyId}${'/boarding/'}${props.boardingId}`)
      .then(function (response) {
        //Eliminamos las propriedades con el _id
        let boarding = Object.keys(response.data)
          .filter(key => !key.endsWith('_id') || key === 'state_id')
          .reduce((obj, key) => {
            obj[key] = response.data[key];
            return obj;
          }, {});
        //Formateo
        boarding.details = formatDetailsForMUI(boarding.details);
        setBoardingDoc(boarding);
      })
      .catch(() => {
        setBoardingDoc({});
      });
  }

  /* Formatea los datos recibidos para mostrarlos en la datagrid */
  const formatDetailsForMUI = (details) => {
    return details.map((d) => {
      return {
        id: d.id,
        specie: { 'value': d.specie_id },
        packaging: { 'value': d.packaging_id },
        size: { 'value': d.size_id },
        color: { 'value': d.color_id },
        folio: { 'value': d.folio},
        container: { 'value': d.container},
        variety: { 'value': d.variety_id },
        boxes: { 'value': d.boxes },
        pallets: { 'value': d.pallets },
        unitPrice: { 'value': d.unitPrice },
      }
    })
  }


  const [boardingDoc, setBoardingDoc] = useState({ period_id: periodId });
  const readOnly = false; //props.boardingId != null && boardingDoc.stateId > 1;
  //Recuperamos todos los assets
  const assets = useSelector(state => state.assets);

  const packings = assets.packings || [];
  const exporters = assets.exporters || [];
  const brokers = assets.brokers || [];
  const consignees = assets.consignees || [];
  const customsAgents = assets.customAgents || [];
  const harbours = assets.harbours || [];
  const countries = assets.countries || [];
  const shippingAgents = assets.shippingAgents || [];
  const shippingCompanies = assets.shippingCompanies || [];
  const notifies = assets.notifies || [];
  const packagings = assets.packagings || [];
  const sizes = assets.sizes || [];
  const colors = assets.colors || [];
  const species = assets.species || [];
  const varieties = assets.varieties || [];
  const carriers = assets.carriers || [];
  const consolidas = assets.consolidas || [];
  const forwarders = assets.forwarders || [];
  const vessels = assets.vessels || [];
  const deposits = assets.deposits || [];

  //Separamos los contenedores por ;
  const containers = boardingDoc.container ? boardingDoc.container.split(';') : [boardingDoc.container];

  /* Configuración de las columnas */
  const columnsProductDetails = [
    {
      field: "container",
      type: 'singleSelect',
      valueOptions: containers.map((p) => { return { value: p, label: p, id: p } }),
      headerName: t("boarding.grid.container"),
      width: 120,
      editable: true,
      valueGetter: (params) => {
        return params.row.container && params.row.container.value || '';
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option && option.label || '';
      },
    },
    {
      field: "specie",
      type: 'singleSelect',
      valueOptions: species.map((p) => { return { value: p.id, label: p.name, id: p.id } }),
      headerName: t("assets.specie"),
      width: 120,
      editable: true,
      valueGetter: (params) => {
        return params.row.specie && params.row.specie.value || '';
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option && option.label || '';
      },
    },
    {
      field: "variety",
      type: 'singleSelect',
      valueOptions: varieties.map((p) => { return { value: p.id, label: p.name, id: p.id } }),
      headerName: t("assets.variety"),
      width: 120,
      editable: true,
      valueGetter: (params) => {
        return params.row.variety && params.row.variety.value || '';
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option && option.label || '';
      },
    },
    {
      field: "size",
      type: 'singleSelect',
      valueOptions: sizes.map((p) => { return { value: p.id, label: p.code } }),
      headerName: t("assets.size"),
      width: 90,
      editable: true,
      valueGetter: (params) => {
        return params.row.size && params.row.size.value || '';
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option && option.label || '';
      },
    },
    {
      field: "color",
      type: 'singleSelect',
      valueOptions: colors.map((p) => { return { value: p.id, label: p.code } }),
      headerName: t("assets.color"),
      width: 90,
      editable: true,
      valueGetter: (params) => {
        return params.row.color && params.row.color.value || '';
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option && option.label || '';
      },
    },
    {
      field: "packaging",
      headerName: t("assets.packaging"),
      valueOptions: packagings.map((p) => { return { value: p.id, label: p.code + " " + p.name } }),
      type: 'singleSelect',
      width: 180,
      editable: true,
      valueGetter: (params) => {
        return params.row.packaging && params.row.packaging.value || '';
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option && option.label || '';
      },
    },
    {
      field: "label",
      headerName: t("assets.label"),
      width: 120,
      valueGetter: (params) => {
        if (params.row.packaging) {
          const packaging = packagings.filter((p) => { return p.id == params.row.packaging.value })[0];
          if (packaging) {
            return packaging.version;
          }
          else {
            return "";
          }
        }
        else {
          return "";
        }
      }
    },
    {
      field: "folio", type: 'text', headerName: "Folio", width: 150, editable: true, valueGetter: (params) => {
        return params.row.folio && params.row.folio.value || "";
      }
    },
    {
      field: "boxes", type: 'number', headerName: "Cajas", width: 90, editable: true, valueGetter: (params) => {
        return params.row.boxes && params.row.boxes.value || 0;
      }
    },
    {
      field: "pallets", type: 'number', headerName: "Pallets", width: 90, editable: true, valueGetter: (params) => {
        return params.row.pallets && params.row.pallets.value || 0;
      }
    },
    {
      field: "unitPrice", type: 'number', headerName: "USD/Cajas", width: 90, editable: true, valueGetter: (params) => {
        return params.row.unitPrice && params.row.unitPrice.value || 0;
      }
    },
    {
      field: "netWeightUnit",
      headerName: "KG Neto Unit",
      width: 90,
      valueGetter: (params) => {
        if (params.row.packaging) {
          const packaging = packagings.filter((p) => { return p.id == params.row.packaging.value })[0];
          if (packaging) {
            return packaging.net_weight;
          }
          else {
            return 0.0;
          }
        }
        else {
          return 0.0;
        }
      }
    },
    {
      field: "rawWeightUnit",
      headerName: "KG Bruto Unit",
      width: 90,
      valueGetter: (params) => {
        if (params.row.packaging) {
          const packaging = packagings.filter((p) => { return p.id == params.row.packaging.value })[0];
          if (packaging) {
            return packaging.raw_weight;
          }
          else {
            return 0.0;
          }
        }
        else {
          return 0.0;
        }
      }
    },
    {
      field: "totalNet",
      headerName: "Total Kilos Neto",
      sortable: false,
      width: 120,
      valueGetter: (params) => {
        let newWeight = 0;
        if (params.row.packaging) {
          const packaging = packagings.filter((p) => { return p.id == params.row.packaging.value })[0];
          if (packaging) {
            newWeight = packaging.net_weight;
          }
        }

        return (parseFloat(params.row.boxes.value) || 0) *
          (parseFloat(newWeight) || 0)
      }
    },
    {
      field: "totalRaw",
      headerName: "Total Kilos Bruto",
      sortable: false,
      width: 120,
      valueGetter: (params) => {
        let rawWeight = 0;
        if (params.row.packaging) {
          const packaging = packagings.filter((p) => { return p.id == params.row.packaging.value })[0];
          if (packaging) {
            rawWeight = packaging.raw_weight;
          }
        }

        return (parseFloat(params.row.boxes.value) || 0) *
          (parseFloat(rawWeight) || 0)
      }
    },
    {
      field: "total",
      headerName: "Total USD",
      sortable: false,
      width: 120,
      valueGetter: (params) =>
        (parseFloat(params.row.boxes.value) || 0) *
        (parseFloat(params.row.unitPrice.value) || 0)
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          handleDeleteRow(params.id);
        };

        return <Button variant="contained" color="warning" onClick={onClick}>X</Button>;
      }
    },
  ];

  const docStates = BOARDING_STATES;

  /* Modalidad de venta */
  const saleTypes = [
    { id: 'Consignación Libre', desc: 'Consignación Libre' },
    { id: 'A firme', desc: 'A firme' },
    { id: 'Bajo condición', desc: 'Bajo condición' },
    { id: 'Consignación con mínimo a firme', desc: 'Consignación con mínimo a firme' },
  ]

  const saleClauses = [
    { id: 'FOB', desc: 'FOB' },
    { id: 'CIF', desc: 'CIF' },
    { id: 'FCA', desc: 'FCA' },
    { id: 'FOT', desc: 'FOT' },
    { id: 'CFR', desc: 'CFR' },
    { id: 'DAP', desc: 'DAP' },
    { id: 'DDP', desc: 'DDP' },
    { id: 'DAV', desc: 'DAV' },
    { id: 'CIP', desc: 'CIP' },
    { id: 'CPT', desc: 'CPT' },
    { id: 'DAF', desc: 'DAF' },
    { id: 'DDU', desc: 'DDU' },
    { id: 'DEQ', desc: 'DEQ' },
    { id: 'DES', desc: 'DES' },
    { id: 'FAS', desc: 'FAS' },
    { id: 'DPU', desc: 'DPU' },
    { id: 'EXW', desc: 'EXW' },
  ]

  const paymentTypes = [
    { id: 'COB1', desc: 'Cobranza hasta 1 año' },
    { id: 'ACRED', desc: 'Acreditivo hasta 1 año' },
    { id: 'CBOF', desc: 'Crédito de bancos y Org. financieros más de 1 año' },
    { id: 'ANTICIPO', desc: 'Pago anticipado a la fecha de embarque' },
    { id: 'ANTICIPO-5', desc: 'Embarque 5% y 95 % con cobranza' },
    { id: 'ANTICIPO-50', desc: 'Embarque 50% y 50 % con cobranza' },
    { id: 'ANTICIPO-50', desc: 'Embarque 50% y 50 % con cobranza' },
    { id: 'AR-100', desc: '100% al arribo' },
    { id: 'DOC-50', desc: '50% contra documentos y 50% despues de 15 días' },
    { id: 'DOC-100', desc: '100% contra factura' },
  ]

  /* Modelo de datos y hooks para recueprar los cambios de la DataGrid */
  const [editRowsModel, setEditRowsModel] = useState({});
  const [editRowData, setEditRowData] = useState({});
  const [lastEditedId, setLastEditedId] = useState(0);
  const [step, setStep] = useState(1);

  const handleEditRowsModelChange = React.useCallback(
    (model) => {
      const editedIds = Object.keys(model);

      // user stops editing when the edit model is empty
      if (editedIds.length === 0) {
        //Eligir la linea que fue modificada
        const targetRow = boardingDoc.details.filter((r) => { return r.id == lastEditedId })[0];
        //La eliminamos de la lista para volver a agregarla con la información modificada
        const newList = boardingDoc.details.filter((r) => { return r.id != lastEditedId });
        setBoardingDoc({
          ...boardingDoc,
          details: [...newList, { ...targetRow, ...editRowData }]
        });
      } else {
        setEditRowData(model[editedIds[0]]);
        setLastEditedId(editedIds[0]);
      }
      setEditRowsModel(model);
    },
    [editRowData]
  );

  const handleDeleteRow = (id) => {
    const newList = boardingDoc.details.filter((r) => { return r.id != id });
    setBoardingDoc({
      ...boardingDoc,
      details: [...newList]
    });
  }

  useEffect(() => {
    //Actualizamos todos los assets 
    dispatch(exporterGetAllAssets(companyId));
    if (props.boardingId) {
      //Si se especifica un id de boarding, lo cargamos
      loadTargetBoarding();
    }
  }, [props.boardingId]);


  const saveBoarding = (printAfter = false) => {
    dispatch(exporterSaveBoardingDoc(companyId, boardingDoc, () => {
      if (printAfter) {
        printBoarding()
      }
      else {
        goToBoardingList()
      }
    }));
  }

  const goToBoardingList = () => {
    navigate("/modules/exporter/boarding", { replace: true });
  }

  const printBoarding = () => {
    navigate("/modules/exporter/boarding/" + props.boardingId + "/print");
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {/* LocalizationProvider para specificar que las fechas se procesarán con Moment() */}
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '80%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: 5 }}>
          {step > 1 ? <Button variant="contained" color="info" onClick={() => { setStep(step - 1); }}> {"< Atrás"}</Button> : <Button variant="contained" disabled={true} color="secondary">{"< Atrás"}</Button>}
          Etapa {step} / 6

          {step <= 5 ? <Button variant="contained" color="info" onClick={() => { setStep(step + 1); }}>{"Siguiente >"}</Button> : <Button variant="contained" disabled={true} color="secondary">{"Siguiente >"}</Button>}
        </div>
        <FormControl>
          {step === 1 &&
            <div>
              {/* Estado */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField select
                  id="state-select"
                  value={(boardingDoc && boardingDoc.state_id) || 1}
                  label={t("boarding.grid.state")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const stateDisplay = docStates.filter((s) => { return s.id == e.target.value })[0];
                    setBoardingDoc({
                      ...boardingDoc,
                      state_id: e.target.value,
                      state: stateDisplay
                    })
                  }}
                >
                  {docStates.map((e) => {
                    return <MenuItem key={e.id} value={e.id}>{e.desc}</MenuItem>
                  })}
                </TextField>

                <TextField fullWidth id="filled-basic"
                  label={t("boarding.grid.code_alt")} variant="filled"
                  type="text"
                  value={boardingDoc.code || ''}
                  onInput={(e) => {
                    const newValue = e.target.value;
                    setBoardingDoc({
                      ...boardingDoc,
                      code: newValue
                    })
                  }}
                />
              </div>
              {/* Exportador */}
              <div className="form-group-compact">
                <TextField select
                  id="exporter-select"
                  requiered="true"
                  value={boardingDoc.transport_type || 'SEA'}
                  label={'Tipo de despacho'}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setBoardingDoc({
                      ...boardingDoc,
                      transport_type: newValue
                    })
                  }}
                >
                  <MenuItem value={'SEA'}>{'Marítimo'}</MenuItem>
                  <MenuItem value={'AIR'}>{'Aéreo'}</MenuItem>
                  <MenuItem value={'TER'}>{'Terrestre'}</MenuItem>
                  <MenuItem value={'OTR'}>{'Otro'}</MenuItem>
                </TextField>
              </div>

              {/* Exportador */}
              <div className="form-group-compact">
                <TextField select
                  id="exporter-select"
                  requiered="true"
                  value={boardingDoc.exporter && boardingDoc.exporter.id || ''}
                  label={t("assets.exporter")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const exporter = exporters.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      exporter: exporter
                    })
                  }}
                >
                  {exporters.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.exporter && boardingDoc.exporter.address || ''} id="filled-basic" label={t('common.address')} variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.exporter && boardingDoc.exporter.rut || ''} id="filled-basic" label={t('common.RUT')} variant="filled" style={{ margin: '4px' }} />
                </div>
              </div>

              {/* Packing */}
              <div className="form-group-compact">
                <TextField select
                  requiered="true"
                  id="packing-select"
                  value={boardingDoc.packing && boardingDoc.packing.id || ''}
                  label={t("assets.packing")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const packing = packings.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      packing: packing
                    })
                  }}
                >
                  {packings.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}

                </TextField>
              </div>

              {/* Broker */}
              <div className='form-group-compact'>
                <TextField select
                  id="broker-select"
                  requiered="true"
                  value={boardingDoc.broker && boardingDoc.broker.id || ''}
                  label={t("assets.broker")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const broker = brokers.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      broker: broker
                    })
                  }}
                >
                  {brokers.map((e) => {
                    return <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.broker && boardingDoc.broker.address || ''} id="filled-basic" label={t('common.address')} variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.broker && boardingDoc.broker.code || ''} id="filled-basic" label={t('common.VAT')} variant="filled" style={{ margin: '4px' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.broker && boardingDoc.broker.country || ''} id="filled-basic" label={t('common.country')} variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.broker && boardingDoc.broker.email || ''} id="filled-basic" label={t('common.contact')} variant="filled" style={{ margin: '4px' }} />
                </div>
              </div>
            </div>}
          {step === 2 &&
            <div>
              <div className='form-group-compact'>
                { /* Inspección, Empresa transporte */}
                <TextField select
                  id="inspection-select"
                  value={boardingDoc.inspection || ''}
                  label={t('boarding.fitoState')}
                  style={{ width: '100%' }}
                  onChange={(newValue) => {
                    setBoardingDoc({
                      ...boardingDoc,
                      inspection: newValue.target.value
                    })
                  }}
                >
                  <MenuItem value={"SAG"}>{"SAG"}</MenuItem>
                  <MenuItem value={"USDA"}>{"USDA"}</MenuItem>
                  <MenuItem value={"SAG-USDA"}>{"SAG-USDA"}</MenuItem>
                  <MenuItem value={"Otro"}>{"Otro"}</MenuItem>
                </TextField>
              </div>
              <div className='form-group-compact'>
                { /* Empresa transporte */}
                <TextField select
                  id="broker-select"
                  requiered="true"
                  value={boardingDoc.carrier && boardingDoc.carrier.id || ''}
                  label={t("assets.carrier")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const carrier = carriers.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      carrier: carrier
                    })
                  }}
                >
                  {carriers.map((e) => {
                    return <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
              </div>

              {/*
        * Hora en planta / puerto
        * Los pickers devuelven un objeto Moment()
        * https://momentjs.com/docs/
        */}
              <div className='form-group-compact' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <DateTimePicker label={t("boarding.grid.dateInPacking")}
                  inputFormat="DD-MM-YYYY HH:mm"
                  value={boardingDoc.dateInPacking || ''}
                  onChange={(e) => {
                    const newValue = e.toISOString();
                    setBoardingDoc({
                      ...boardingDoc,
                      dateInPacking: newValue
                    })
                  }}
                  renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                />

                <DateTimePicker label={t("boarding.dateInHarbour")}
                  inputFormat="DD-MM-YYYY HH:mm"
                  value={boardingDoc.dateInHarbour || ''}
                  onChange={(e) => {
                    const newValue = e.toISOString();
                    setBoardingDoc({
                      ...boardingDoc,
                      dateInHarbour: newValue
                    })
                  }}
                  renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                />
              </div>

              {/* Consolidación */}
              <TextField select
                id="broker-select"
                requiered="true"
                value={boardingDoc.consolida && boardingDoc.consolida.id || ''}
                label={t("assets.consolida")}
                style={{ width: '100%' }}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const consolida = consolidas.filter((x) => { return x.id == newValue })[0]
                  setBoardingDoc({
                    ...boardingDoc,
                    consolida: consolida
                  })
                }}
              >
                {consolidas.map((e) => {
                  return <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                })}
              </TextField>

              {/* Agente aduana */}
              <div className="form-group-compact">
                <TextField select
                  id="customs-select"
                  value={boardingDoc.customsAgent && boardingDoc.customsAgent.id || ''}
                  label={t("assets.customsAgent")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const customsAgent = customsAgents.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      customsAgent: customsAgent
                    })
                  }}
                >
                  {customsAgents.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.customsAgent && boardingDoc.customsAgent.code || ''} id="filled-basic" label="COD Agente" variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.customsAgent && boardingDoc.customsAgent.rut || ''} id="filled-basic" label="RUT/VAT" variant="filled" style={{ margin: '4px' }} />
                </div>
              </div>
            </div>}
          {step === 3 &&
            <div>
              {/* Agente Embarcador */}
              <div className="form-group-compact">
                <TextField select
                  id="shippingAgent-select"
                  value={boardingDoc.shippingAgent && boardingDoc.shippingAgent.id || ''}
                  label={t("assets.shippingAgent")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const shippingAgent = shippingAgents.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      shippingAgent: shippingAgent
                    })
                  }}
                >
                  {shippingAgents.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.shippingAgent && boardingDoc.shippingAgent.code || ''} id="filled-basic" label="COD Agente" variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.shippingAgent && boardingDoc.shippingAgent.rut || ''} id="filled-basic" label="RUT/VAT" variant="filled" style={{ margin: '4px' }} />
                </div>
              </div>

              {/* Puertos */}
              <div className='form-group-compact' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Autocomplete
                  id="harbourIn-select"
                  value={boardingDoc.harbourIn && boardingDoc.harbourIn.name || ''}
                  label={t("boarding.harbourIn")}
                  style={{ width: '100%' }}
                  size="medium"
                  onChange={(e, value) => {
                    /* value = object */
                    const harbour = harbours.filter((x) => { return x.id == value.id })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      harbourIn: harbour
                    })
                  }}
                  options={harbours.map((e) => {
                    return { label: e.name, id: e.id, key: 'pe' + e.id }
                  })}
                  renderInput={(params) => <TextField {...params} label="Puerto embarque" />}
                >
                </Autocomplete>
                <Autocomplete
                  id="harbourOut-select"
                  value={boardingDoc.harbourOut && boardingDoc.harbourOut.name || ''}
                  label={t("boarding.harbourOut")}
                  style={{ width: '100%' }}
                  size="medium"
                  options={harbours.map((e) => {
                    return { label: e.name, id: e.id, key: 'pd' + e.id }
                  })}
                  onChange={(e, value) => {
                    const harbour = harbours.filter((x) => { return x.id == value.id })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      harbourOut: harbour
                    })
                  }}
                  renderInput={(params) => <TextField {...params} label="Puerto descarga" />}
                />

                <Autocomplete
                  id="harbourEntry-select"
                  value={boardingDoc.harbourEntry && boardingDoc.harbourEntry.name || ''}
                  style={{ width: '100%' }}
                  label={t("boarding.harbourEntry")}
                  options={harbours.map((e) => {
                    return { label: e.name, id: e.id, key: 'ps' + e.id }
                  })}
                  onChange={(e, value) => {
                    const harbour = harbours.filter((x) => { return x.id == value.id })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      harbourEntry: harbour
                    })
                  }}
                  renderInput={(params) => <TextField {...params} label="Puerto entrega" />}
                />
              </div>

              { /* Paises */}
              <div className='form-group-compact' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <TextField select
                  id="countryFrom-select"
                  value={boardingDoc.countryFrom && boardingDoc.countryFrom.id || ''}
                  label={t("boarding.countryFrom")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const countryFrom = countries.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      countryFrom: countryFrom
                    })
                  }}
                >
                  {countries.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
                <TextField select
                  id="countryTo-select"
                  value={boardingDoc.countryTo && boardingDoc.countryTo.id || ''}
                  label={t("boarding.countryTo")}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const countryTo = countries.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      countryTo: countryTo
                    })
                  }}
                >
                  {countries.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
              </div>

              { /* ETD / ETDA */}
              <div className='form-group-compact' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <DatePicker label="ETD"
                  inputFormat="DD-MM-YYYY"
                  value={boardingDoc.ETD || ''}
                  onChange={(e) => {
                    const newValue = e.toISOString();
                    setBoardingDoc({
                      ...boardingDoc,
                      ETD: newValue
                    })
                  }}
                  renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                />

                <DatePicker label="ETA"
                  inputFormat="DD-MM-YYYY"
                  value={boardingDoc.ETA || ''}
                  onChange={(e) => {
                    const newValue = e.toISOString();
                    setBoardingDoc({
                      ...boardingDoc,
                      ETA: newValue
                    })
                  }}
                  renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                />
              </div>

              <div className='form-group-compact'>
                { /* Agente logistico */}
                <TextField select
                  id="forwarder-select"
                  value={boardingDoc.forwarder && boardingDoc.forwarder.id || ''}
                  label={t('assets.forwarder')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const forwarder = forwarders.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      forwarder: forwarder
                    })
                  }}
                >
                  {forwarders.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>

                { /* Deposito */}
                <TextField select
                  requiered="true"
                  id="deposit-select"
                  value={boardingDoc.deposit && boardingDoc.deposit.id || ''}
                  label={t('assets.deposit')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const deposit = deposits.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      deposit: deposit
                    })
                  }}
                >
                  {deposits.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
              </div>
              {/* Naviera / Aerolinea */}
              <div className='form-group-compact'>
                <TextField select
                  id="customs-select"
                  value={boardingDoc.shippingCompany && boardingDoc.shippingCompany.id || ''}
                  label={t('assets.shippingCompany')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const shippingCompany = shippingCompanies.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      shippingCompany: shippingCompany
                    })
                  }}
                >
                  {shippingCompanies.map((e) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>
                  })}
                </TextField>
              </div>

            </div>}
          {step === 4 &&
            <div>
              <div className='form-group-compact'>
                { /* Nave/Vuelo + Booking */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <TextField select
                    id="vessels-select"
                    value={boardingDoc.vessel && boardingDoc.vessel.id || ''}
                    label={t('assets.vessel')}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const vessel = vessels.filter((x) => { return x.id == newValue })[0]

                      setBoardingDoc({
                        ...boardingDoc,
                        vessel: vessel,
                        cutOff: vessel.cutoff,
                        stackingFrom: vessel.stacking_from,
                        stackingTo: vessel.stacking_to,
                      })
                    }}
                  >
                    {vessels.map((e) => {
                      return <MenuItem value={e.id}>{e.name}</MenuItem>
                    })}
                  </TextField>
                  <TextField fullWidth id="filled-basic"
                    label="Booking" variant="filled"
                    style={{ margin: '4px' }}
                    value={boardingDoc.booking || ''}
                    onInput={(e) => {
                      const newValue = e.target.value;
                      setBoardingDoc({
                        ...boardingDoc,
                        booking: newValue
                      })
                    }}
                  />
                </div>

                { /* Fecha Cut Off, Stacking Inicio, Stacking Final */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <DateTimePicker label="Cut Off"
                    inputFormat="DD-MM-YYYY HH:mm"
                    value={boardingDoc.cutOff || ''}
                    onChange={(e) => {
                      const newValue = e.toISOString();
                      setBoardingDoc({
                        ...boardingDoc,
                        cutOff: newValue
                      })
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                  />
                  <DateTimePicker label="Stacking Inicial"
                    inputFormat="DD-MM-YYYY HH:mm"
                    value={boardingDoc.stackingFrom || ''}
                    onChange={(e) => {
                      const newValue = e.toISOString();
                      setBoardingDoc({
                        ...boardingDoc,
                        stackingFrom: newValue
                      })
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                  />
                  <DateTimePicker label="Stacking Final"
                    inputFormat="DD-MM-YYYY HH:mm"
                    value={boardingDoc.stackingTo || ''}
                    onChange={(e) => {
                      const newValue = e.toISOString();
                      setBoardingDoc({
                        ...boardingDoc,
                        stackingTo: newValue
                      })
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                  />
                </div>

                { /* Contenedor, Temp., Ventilación */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <TextField fullWidth id="filled-basic"
                    label={boardingDoc.transport_type == 'AIR' ? 'AWB' : t('boarding.grid.container')} variant="filled"
                    style={{ margin: '4px' }}
                    value={(boardingDoc.container || '')}
                    onInput={(e) => {
                      const newValue = e.target.value;
                      setBoardingDoc({
                        ...boardingDoc,
                        container: newValue
                      })
                    }}
                  />
                  <TextField fullWidth id="filled-basic"
                    label="Temperatura" variant="filled"
                    style={{ margin: '4px', width: '400px' }}
                    value={(boardingDoc.temperature || '') + '°C'}
                    onInput={(e) => {
                      const newValue = e.target.value.replace('°C', '');
                      setBoardingDoc({
                        ...boardingDoc,
                        temperature: newValue
                      })
                    }}
                  />
                  <TextField fullWidth id="filled-basic"
                    label="Ventilación" variant="filled"
                    style={{ margin: '4px', width: '450px' }}
                    value={(boardingDoc.vent || '') + 'CBM/H'}
                    onInput={(e) => {
                      const newValue = e.target.value.replace('CBM/H', '');;
                      setBoardingDoc({
                        ...boardingDoc,
                        vent: newValue
                      })
                    }}
                  />

                  <TextField fullWidth id="filled-basic"
                    label="Tara del contenedor" variant="filled"
                    style={{ margin: '4px', width: '450px' }}
                    value={(boardingDoc.grossWeight || '') + 'KG'}
                    onInput={(e) => {
                      const newValue = e.target.value.replace('KG', '');
                      setBoardingDoc({
                        ...boardingDoc,
                        grossWeight: newValue
                      })
                    }}
                  />

                  <TextField fullWidth id="filled-basic"
                    label="Sello" variant="filled"
                    style={{ margin: '4px' }}
                    value={(boardingDoc.seal || '')}
                    onInput={(e) => {
                      const newValue = e.target.value;
                      setBoardingDoc({
                        ...boardingDoc,
                        seal: newValue
                      })
                    }}
                  />
                </div>
              </div>

              <div className='form-group-compact'>
                <TextField fullWidth id="filled-basic"
                  label="SPS" variant="filled"
                  style={{ margin: '4px' }}
                  value={boardingDoc.sps || ''}
                  onInput={(e) => {
                    const newValue = e.target.value;
                    setBoardingDoc({
                      ...boardingDoc,
                      sps: newValue
                    })
                  }}
                />
                <TextField fullWidth id="filled-basic"
                  label="DUS" variant="filled"
                  style={{ margin: '4px' }}
                  value={boardingDoc.dus || ''}
                  onInput={(e) => {
                    const newValue = e.target.value;
                    setBoardingDoc({
                      ...boardingDoc,
                      dus: newValue
                    })
                  }}
                />
              </div>
            </div>}
          {step === 5 &&
            <div>
              <div className='form-group-compact'>
                { /* Mod. Venta, Clausula, Pago */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <TextField fullWidth id="filled-basic"
                    label="Nombre del chófer" variant="filled"
                    style={{ margin: '4px' }}
                    value={(boardingDoc.driver || '')}
                    onInput={(e) => {
                      const newValue = e.target.value;
                      setBoardingDoc({
                        ...boardingDoc,
                        driver: newValue
                      })
                    }}
                  />

                  <TextField fullWidth id="filled-basic"
                    label="Telefono del chófer" variant="filled"
                    style={{ margin: '4px' }}
                    value={(boardingDoc.driverPhone || '')}
                    onInput={(e) => {
                      const newValue = e.target.value;
                      setBoardingDoc({
                        ...boardingDoc,
                        driverPhone: newValue
                      })
                    }}
                  />
                </div>
              </div>

              <div className='form-group-compact'>
                { /* Mod. Venta, Clausula, Pago */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                  <TextField select
                    id="sellType-select"
                    requiered="true"
                    value={boardingDoc.saleType || ''}
                    label="Modalidad de venta"
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setBoardingDoc({
                        ...boardingDoc,
                        saleType: newValue
                      })
                    }}
                  >
                    {saleTypes.map((e) => {
                      return <MenuItem key={e.id} value={e.id}>{e.desc}</MenuItem>
                    })}
                  </TextField>
                  <TextField select
                    id="sellType-select"
                    requiered="true"
                    value={boardingDoc.saleClause || ''}
                    label="Clausula de venta"
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setBoardingDoc({
                        ...boardingDoc,
                        saleClause: newValue
                      })
                    }}
                  >
                    {saleClauses.map((e) => {
                      return <MenuItem key={e.id} value={e.id}>{e.desc}</MenuItem>
                    })}
                  </TextField>
                  <TextField select
                    id="sellType-select"
                    requiered="true"
                    value={boardingDoc.paymentType || ''}
                    label="Forma de pago"
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setBoardingDoc({
                        ...boardingDoc,
                        paymentType: newValue
                      })
                    }}
                  >
                    {paymentTypes.map((e) => {
                      return <MenuItem key={e.id} value={e.id}>{e.desc}</MenuItem>
                    })}
                  </TextField>
                </div>

                { /* Condición de flete */}
                <TextField fullWidth id="filled-basic"
                  label="Condición de flete" variant="filled"
                  style={{ margin: '4px' }}
                  value={boardingDoc.transport || ''}
                  onInput={(e) => {
                    const newValue = e.target.value;
                    setBoardingDoc({
                      ...boardingDoc,
                      transport: newValue
                    })
                  }}
                />
              </div>

              {/* Consignee */}
              <div className='form-group-compact'>
                <TextField select
                  id="consignee-select"
                  requiered="true"
                  value={boardingDoc.consignee && boardingDoc.consignee.id || ''}
                  label="Consignee"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const consignee = consignees.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      consignee: consignee
                    })
                  }}
                >
                  {consignees.map((e) => {
                    return <MenuItem key={e.id} value={e.id}>{e.code} {e.name}</MenuItem>
                  })}
                </TextField>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.consignee && boardingDoc.consignee.address || ''} id="filled-basic" label="Dirección" variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.consignee && boardingDoc.consignee.code || ''} id="filled-basic" label="VAT" variant="filled" style={{ margin: '4px' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.consignee && boardingDoc.consignee.country || ''} id="filled-basic" label="Pais" variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.consignee && boardingDoc.consignee.email || ''} id="filled-basic" label="Contacto" variant="filled" style={{ margin: '4px' }} />
                </div>
              </div>

              {/* Notify */}
              <div className='form-group-compact'>
                <TextField select
                  id="notify-select"
                  requiered="true"
                  value={boardingDoc.notify && boardingDoc.notify.id || ''}
                  label="Notify"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const notify = notifies.filter((x) => { return x.id == newValue })[0]
                    setBoardingDoc({
                      ...boardingDoc,
                      notify: notify
                    })
                  }}
                >
                  {notifies.map((e) => {
                    return <MenuItem key={e.id} value={e.id}>{e.code} {e.name}</MenuItem>
                  })}
                </TextField>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.notify && boardingDoc.notify.address || ''} id="filled-basic" label="Dirección" variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.notify && boardingDoc.notify.code || ''} id="filled-basic" label="VAT" variant="filled" style={{ margin: '4px' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <TextField fullWidth readOnly value={boardingDoc.notify && boardingDoc.notify.country || ''} id="filled-basic" label="Pais" variant="filled" style={{ margin: '4px' }} />
                  <TextField fullWidth readOnly value={boardingDoc.notify && boardingDoc.notify.email || ''} id="filled-basic" label="Contacto" variant="filled" style={{ margin: '4px' }} />
                </div>
              </div>

              <div className='form-group-compact'>
                <TextField multiline fullWidth id="comment"
                  label="Observación" variant="filled"
                  style={{ margin: '4px' }}
                  value={boardingDoc.comment || ''}
                  onInput={(e) => {
                    const newValue = e.target.value;
                    setBoardingDoc({
                      ...boardingDoc,
                      comment: newValue
                    })
                  }}
                />
              </div>
            </div>}

        </FormControl>
        {step === 6 &&
          <div style={{ height: 400, width: "100%" }}>
            {!readOnly && <Button variant="outlined" color="success" onClick={
              () => {
                setBoardingDoc({
                  ...boardingDoc,
                  details: [...boardingDoc.details || [], { id: Date.now(), container: '', specie: '', variety: '', size: '', color: '', folio: '', container: '', packaging: '', boxes: 0, unitPrice: 0 }]
                });
              }}>Nueva linea</Button>}
            <DataGrid
              rows={boardingDoc.details || []}
              columns={columnsProductDetails}
              pageSize={7}
              rowsPerPageOptions={[7]}
              editRowsModel={editRowsModel}
              onEditRowsModelChange={handleEditRowsModelChange}
            />
          </div>}
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '48px', justifyContent: 'space-around' }}>
          <Button variant="contained" color="secondary" onClick={() => { goToBoardingList(); }}>{readOnly ? 'Volver' : 'Cancelar'}</Button>
          {!readOnly && <Button variant="contained" color="success" onClick={() => { saveBoarding(); }}>Guardar</Button>}
          {!readOnly && <Button variant="contained" color="success" onClick={() => {
            saveBoarding(true);
          }}>Guardar e imprimir</Button>}
        </div>
      </div>
    </LocalizationProvider>)
}

export default BoardingWizard;
