import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { TextField, InputLabel, ListItemText, ListItemIcon, ListItem, Drawer } from "@mui/material";
import {
    AccountCircleOutlined,
    CardMembershipOutlined,
    AttachMoneyOutlined,
    ArrowBackOutlined,
    BookOutlined
} from "@material-ui/icons";
import '../exporter.css'

const menu = [
    { name: "Usuarios", short: "Embarques", icon: <AccountCircleOutlined />, link: '/modules/admin/users' },
    { name: "Empresas", icon: <AttachMoneyOutlined />, link: '/modules/admin/companies' },
    { name: "Acesos", icon: <CardMembershipOutlined />, link: '/modules/admin/access' },
    { name: "Logs", short: "Logs", icon: <BookOutlined />, link: '/modules/admin/logs' },
    { name: "Volver", icon: <ArrowBackOutlined />, link: '/dashboard' },

];

function AdminNav() {
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const [current, setCurrent] = React.useState("");

    return (
        <Drawer open={drawerOpen} variant={'permanent'} anchor={"left"} onClose={() => setDrawerOpen(false)}>
            <div style={{ width: "200px" }}>
                <ListItem button>                    
                    <ListItemText primary={'SUP > ' + current} />
                </ListItem>
                {menu.map((item, index) => (
                    <Link to={item.link} key={index} style={{ textDecoration: 'none', color: 'black' }}>
                        <ListItem button onClick={() => { setCurrent(item.short || item.name) }}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    </Link>
                ))
                }
            </div>
        </Drawer>)
}


export default AdminNav;