import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { API_ENDPOINT } from "../constants";
import { useNavigate } from "react-router-dom";
import GenericLoader from "./generic-loader";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "../exporter.css";
import { Box, Button, Grid } from "@mui/material";

export function ProcessImport({ onClose }) {
  const navigate = useNavigate();
  const [payloadFile, setPayload] = React.useState(null);
  const [assetsErrors, setErrors] = React.useState({ checkResults: [{ assetType: '', results: [] }], status: -1 });
  const [shippings, setShippings] = React.useState([]);
  const [loaderState, setLoaderState] = React.useState({
    isLoading: false,
    message: "",
  });

  const companyId = useSelector(
    (s) => s.currentUser.selectedRoleDisplay.company.id
  );

  const { t } = useTranslation();

  const getProcessProperties = () => {
    return [
      "Packing",
      "Fecha",
      "Cliente",
      "Packing",
      "Productor",
      "Proceso",
      "Especie",
      "Variedad",
      "Calibre",
      "Color",
      "Categoria",
      "Embalaje",
      "Version",
      "Bultos",
      "CU_01",
      "CU_02",
      "CU_03",
    ];
  };

  const handleFileUpload = (content) => {
    setLoaderState({ isLoading: true, message: "Subiendo archivo..." });
    var formData = new FormData();

    formData.append("processFile", content);

    axios
      .post(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/shipping"}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        //Redirecionar
        onClose();
        navigate("/modules/exporter/prices?state=open&&success=1");
      })
      .catch((e) => {
        const errorMessage = e.response.data;
        alert(errorMessage);
      })
      .finally(() => {
        setLoaderState({ isLoading: false, message: "" });
      });
  };

  const fileReader = new FileReader();
  //6 primeras propriedades para describir, m�s al consultar proceso

  const handleBulkPayload = (e) => {
    e.preventDefault();
    if (payloadFile) {
      //Si tenemos un archivo selecionado
      fileReader.onload = function (event) {
        //Al leer el CSV, separar por salto de linea y agregar cada item al listado
        const csvOutput = event.target.result;
        checkForMissingAssets(csvOutput);
        //handleFileUpload(csvOutput);
      };

      fileReader.readAsText(payloadFile);
    }
  };

  const confirmUploadPayload = () => {
    if (payloadFile) {
      //Si tenemos un archivo selecionado
      fileReader.onload = function (event) {
        //Al leer el CSV, separar por salto de linea y agregar cada item al listado
        const csvOutput = event.target.result;
        //checkForMissingAssets(csvOutput);
        handleFileUpload(csvOutput);
      };

      fileReader.readAsText(payloadFile);
    }
  }

  const checkForMissingAssets = (csvOutput) => {
    //Antes de enviar el archivo, recuperamos los recursas al cual hace referencia para revisar que no falte ninguno
    let csvLines = csvOutput.split("\r\n");

    //Saltamos la primera linea que deben ser los encabezados
    csvLines.shift();

    let uniqueEntries = {};
    //Columnas que contienen datos que deben estar declarados
    const assetCols = [0, 2, 4, 6, 7, 8, 9, 11];
    const maxAssetId = 11;

    for (const i in csvLines) {
      const cols = csvLines[i].split(";");
      //Si tenemos menos columna que el ultimo recurso, terminamos
      if (cols.length < maxAssetId) break;
      for (const k in assetCols) {
        //Por cada columna, anotamos los valores unicos que se registran
        const assetId = assetCols[k];
        const asset = cols[assetId];

        if (asset.length > 0) {
          //Primera vez que pasamos ese recurso, hay que inicializar el diccionario
          if (uniqueEntries[assetId] === undefined) {
            uniqueEntries[assetId] = [asset];
            //No es primera vez, revisamos si ya regristramos ese recurso
          } else if (!uniqueEntries[assetId].includes(asset)) {
            uniqueEntries[assetId].push(asset);
          } else {
            continue;
          }
        }
      }
    }

    //Consultamos la API para ver si nos falta algunos de los recursos
    setLoaderState({ isLoading: true, message: "Revisando recursos" });
    axios
      .post(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/assetsCheck"}`,
        uniqueEntries
      )
      .then(function (response) {
        //Redirecionar
        setErrors(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        const errorMessage = e.response.data;
        alert(errorMessage);
      })
      .finally(() => {
        setLoaderState({ isLoading: false, message: "" });
      });
  };

  const handleOnPayloadFileChange = (e) => {
    setPayload(e.target.files[0]);
  };

  return (
    <Box>
      <GenericLoader
        title={loaderState.message}
        buttons={{ cancel: "Cancelar" }}
        openState={loaderState.isLoading}
      />

      <Grid container spacing={2}>
        <Grid item sm={2}>
          <Button variant="contained" component="label" color="secondary" onClick={() => {
            axios
              .get(
                `${API_ENDPOINT}${"exporter/asset/masiva/format"}`
              ).then(function (response) {
                const csvFormatData = "data:text/csv;charset=utf-8," + response.data;
                var encodedUri = encodeURI(csvFormatData);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "daiahub_masiva.csv");
                document.body.appendChild(link); // Required for FF
                
                link.click(); // This will download the data file named "my_data.csv".
              })
          }}>
            Obtener formato masiva
          </Button>
        </Grid>
        <Grid item sm={3}>
          <Button variant="contained" component="label" color="info">
            Seleccionar archivo
            <input
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              hidden
              onChange={(e) => {
                handleOnPayloadFileChange(e);
              }}
            />
          </Button>
        </Grid>
        <Grid item sm={6}>
          Seleccionado: {payloadFile?.name}
        </Grid>
        <Grid item sm={12}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={(e) => {
              handleBulkPayload(e);
            }}
          >
            Revisar masiva
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            Recurso
          </Grid>
          <Grid item sm={2}>
            Errores
          </Grid>
          <Grid item sm={6}>
            Mensaje
          </Grid>
          {assetsErrors?.checkResults.map((e) => (
            <>
              {" "}
              <Grid item sm={4}>
                {t("assets." + e.assetType)}
              </Grid>
              <Grid item sm={2}>
                {e.results.length}
              </Grid>
              <Grid item sm={6}>
                {e.results.length > 0 && "Elementos no encontrados: " + e.results.join(",")}
              </Grid>
            </>
          ))}
        </Grid>
      </Box>

      <Button type="button" variant="contained" color="success" disabled={assetsErrors.status != 0} onClick={() => confirmUploadPayload()}>
        Subir masiva
      </Button>
    </Box>
  );
}
