import React, {useState} from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import {
    LibraryBooksOutlined,
    DeleteForeverOutlined
} from "@material-ui/icons";

import AdminCompanyAccess from './admin-company-access';

import MUIDataGrid from "./MUI_DataGrid"
import '../app.css'

function AdminCompanyList({ companies, display }) {
    /* Propriedades de la data grid */
    const [selected, setSelected] = useState();
    const [show, setShowAccess] = useState(false);
    
    const columns = [
        { field: 'id', headerName: '#', minWidth: 50,},
      { field: 'display_name', headerName: 'Nombre', minWidth: 200,},
      { field: 'rut', headerName: 'RUT/VAT', minWidth: 200,},
      { field: 'email', headerName: 'Email', minWidth: 200,},
      { field: 'company_type_id', headerName: 'Tipo', minWidth: 200,},
      {
        field: "actionView",
        headerName: "Ver accesos",
        sortable: false,
        minWidth: 50,
        renderCell: (params) => {
            return <Button variant="outlined" onClick={() => showAccess(companies.filter(U => U.id === params.id)[0])}>
                <LibraryBooksOutlined />
            </Button>;
        }
    },
    {
        field: "actionDelete",
        headerName: "Eliminar",
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
            return <Link to={'/modules/admin/company/' + params.id + '/delete'}>
                <DeleteForeverOutlined />
            </Link>;
        }
    },
    ];

    const showAccess = (company) => {
        setSelected(company);
        setShowAccess(true);
    }

    return (<div>
        <AdminCompanyAccess company={selected} open={show} handleClose={() => setShowAccess(false)} companyList={companies || []}/>
            <div style={{ height: '450px' }}>
                <MUIDataGrid rows={companies} columns={columns} lang={'eng'} />
            </div>
        </div>)
}

export default AdminCompanyList;
