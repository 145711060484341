import { API_ENDPOINT, RESERVATION_URL, RESERVATION_ITEM_URL } from '../constants'
import axios from 'axios'

axios.defaults.withCredentials = true;

export function getReservation(reservationCode, companyId, callback = (data) => { console.log(data) }) {
    axios.get(`${API_ENDPOINT}${RESERVATION_URL}`, {
        params: {
            reservation_code: reservationCode,
            company_id: companyId
        }
    })
        .then(function (response) {
            callback(response)
        })
        .catch((err) => {
            callback(err)
        })
}

const TASK_QUERY_INTERVAL_MS = 500

/*
    Utilizado para obtener respuesta asincrona (Celery)
*/
export function waitOnResponse (taskId, callback) {
  const interval = setInterval(() => {
    console.log("Checking task " + taskId + " ...");
    axios
    .get(
      `${API_ENDPOINT}${
        "celery/" + taskId + "/result"
      }`
    )
    .then((result) => {
      const payload = result.data;
      if (payload.hasOwnProperty("notReady")) {
        //Tenemos que esperar más
      }
      else {
        //Tenemos una respuesta
        clearInterval(interval)
        callback(payload)
      }
    })
    .catch(() => clearInterval(interval));
  }, TASK_QUERY_INTERVAL_MS);
}