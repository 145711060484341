import React, { Component } from "react";
import { API_ENDPOINT } from "../constants";
import { Button, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import "../app.css";
import axios from "axios";

class Document extends Component {
  handleFileView(fileKey) {
    const safeKey = encodeURIComponent(fileKey);
    if (this.props.display === "boarding") {
      //Documento de embarque
      axios
        .get(`${API_ENDPOINT}${"document/key/"}${safeKey}${"?type=boarding"}`)
        .then(function (response) {
          window.open(response.data.url, "_blank");
        });
    } else {
      axios
        .get(`${API_ENDPOINT}${"document/key/"}${safeKey}`)
        .then(function (response) {
          window.open(response.data.url, "_blank");
        });
    }
  }

  render() {
    if (this.props.document !== undefined && this.props.document.id > 0) {
      let document = this.props.document;
      //Mostrar documento
      if (this.props.display && this.props.display === "row") {
        return (
          <tr key={document.id}>
            <td>{document.specie && document.specie.name}</td>
            <td>{document.type && document.type.display_name}</td>
            <td>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  this.handleFileView(document.file_fullpath);
                }}
              >
                {this.props.t("document.view")}
              </Button>
            </td>
            <td>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  this.props.onDelete(document);
                }}
              >
                {this.props.t("document.delete")}{" "}
              </Button>
            </td>
          </tr>
        );
      } else if (this.props.display && this.props.display === "boarding") {
        return (
          <Grid container key={document.id}>
            <Grid item sm={3}>
              <b>{this.props.t("document.file")}:</b>
            </Grid>
            <Grid item sm={9}>
              <label>{this.props.document.display_name}</label>
            </Grid>
            <Grid item sm={3}>
              <b>{this.props.t("document.user")}:</b>
            </Grid>
            <Grid item sm={9}>
              <label>{this.props.document.uploaded_by.email}</label>
            </Grid>
            <Grid item sm={6}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  this.handleFileView(document.file_fullpath);
                }}
              >
                {this.props.t("document.view")}
              </Button>
            </Grid>
            <Grid item sm={6}>
              {document.can_delete && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    this.props.onDelete(document);
                  }}
                >
                  {this.props.t("document.delete")}
                </Button>
              )}
            </Grid>
          </Grid>
        );
      } else {
        return (
          <div className="list-group-item list-g" key={document.id}>
            <div className="flex-row">
              <label>{document.specie && document.specie.name}</label>
              <label>{document.type && document.type.display_name}</label>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  this.handleFileView(document.file_fullpath);
                }}
              >
                {this.props.t("document.view")}{" "}
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  this.props.onDelete(document);
                }}
              >
                {this.props.t("document.delete")}{" "}
              </Button>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="list-group-item">
          <div className="flex-row">
            <label>{this.props.documentType}</label>
          </div>
          <div className="flex-row">
            <input
              type="file"
              name="file"
              onChange={(e) =>
                this.props.onFileChanged(e, this.props.documentKey)
              }
            />
          </div>
        </div>
      );
    }
  }

  handleChange(event) {
    this.setState({ documentState: "Validado" });
  }
}

export default withTranslation()(Document);
