import { GET_RESERVATION, SET_RESERVATION_OK } from '../actions/index'
import Reservation from '../models/reservation'


interface ReservationReducerState {
    list: Reservation[],
    mustRefresh: boolean
}

const initialState:ReservationReducerState = { list: [], mustRefresh: false }

export default function ReservationsReducer (state=initialState, action): ReservationReducerState {
    switch (action.type) {
        case GET_RESERVATION:
            //...state = el resto del state para no borrarlo
            return {
                ...state,
                list: action.payload,
                mustRefresh: false
            }
        case SET_RESERVATION_OK:
            return {
                ...state,
                mustRefresh: true
            }
        default:
            return state
    }
}