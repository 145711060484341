import React from "react";
import { Route } from "react-router-dom";

import RequireAuth from "./router_require_auth";
import ProcessDocumentsView from "../components/grower-process";
import GrowerPortal from "../containers/grower-portal";
import RequirePayment from "./router_require_payment";

const GROWER_LICENSE_TYPE = "gportal_lic";

export default (
  <Route path="/modules/grower">
    <Route
      path="dashboard"
      element={
        <RequirePayment licenseType={GROWER_LICENSE_TYPE}>
          <RequireAuth>
            <div>Dashboard</div>
          </RequireAuth>
        </RequirePayment>
      }
    />
    <Route
      path="portal"
      element={
        <RequirePayment licenseType={GROWER_LICENSE_TYPE}>
          <RequireAuth>
            <GrowerPortal mode={"exporter"} />
          </RequireAuth>
        </RequirePayment>
      }
    />
    <Route
      path="quality"
      element={
        <RequirePayment licenseType={GROWER_LICENSE_TYPE}>
          <RequireAuth>
            <div>Quality</div>
          </RequireAuth>
        </RequirePayment>
      }
    />
    <Route
      path="process"
      element={
        <RequirePayment licenseType={GROWER_LICENSE_TYPE}>
          <RequireAuth>
          <GrowerPortal mode={"grower"} />
          </RequireAuth>
        </RequirePayment>
      }
    />
    <Route
      path="documents"
      element={
        <RequirePayment licenseType={GROWER_LICENSE_TYPE}>
          <RequireAuth>
            <div>Document</div>
          </RequireAuth>
        </RequirePayment>
      }
    />
  </Route>
);
