import {
  API_ENDPOINT,
  RESERVATION_URL,
  LOGIN_URL,
  LOGOUT_URL,
  COMPANY_URL,
  COMPANY_ASSOCIATE_URL,
} from "../constants";

import { waitOnResponse } from "./utils";
import axios from "axios";

export const SWITCH_PAGE = "SWITCH_PAGE";
export const SET_PERIOD_ID = "SET_PERIOD_ID";

export const SET_APP_BAR_OPTIONS = "SET_APP_BAR_OPTIONS";
export const ACTION_TYPE_SELECT_RESERVATION = "RESERVATION_SELECTED";
export const GET_RESERVATION = "GET_RESERVATION";
export const SET_RESERVATION_OK = "SET_RESERVATION_SUCCESS";
export const SET_RESERVATION_NOK = "SET_RESERVATION_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_USER = "GET_USER";
export const CHANGE_NOTIFICATIONS = "CHANGE_NOTIFICATIONS";

export const GET_COMPANIES = "GET_COMPANIES";
export const ASSOCIATE_COMPANY_SUCCESS = "A_COMP_SUCCESS";
export const ASSOCIATE_COMPANY_FAIL = "A_COMP_FAIL";
export const VALIDATE_ASSOCIATION_REQUEST = "V_A_REQ";
export const GET_ASSOCIATION_REQUEST = "GET_A_REQU";

export const GET_ASSET = "GET_ASSET";
export const GET_ALL_ASSET = "GET_ALL_ASSET";
export const GET_BOARDING = "GET_BOARDING";
export const GET_CURRENT_BOARDING_MESSAGES = "GET_CURRENT_BOARDING_MESSAGES";
export const GET_BOARDINGS = "GET_BOARDINGS";
export const SET_ASSET = "SET_ASSET";

export const SELECT_ROLE = "SELECT_ROLE";

export const SET_LAYOUT = "SET_LAYOUT";
export const GET_GROWER_DOCUMENT = "GET_GROWER_DOCUMENT";


export const CLOSE_ERROR_MESSAGE = "CLOSE_ERROR_MESSAGE";
export const OPEN_ERROR_MESSAGE = "OPEN_ERROR_MESSAGE";

axios.defaults.withCredentials = true;

//Axios interceptor para desconectar el usuario si no esta logueado
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      //No estamos conectados, renviar a la pagina de inicio
      if (error.response.status === 403) {
        console.log("Session expired.");
        localStorage.removeItem("user");
        localStorage.removeItem("selectedRole");
        document.location = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export function setLayout(newLayout) {
  return function (dispatch) {
    dispatch({ type: SET_LAYOUT, payload: newLayout });
  };
}

export function getSpecies() {
  return function (dispatch) {
    axios
      .get(`${API_ENDPOINT}${"specie"}`)
      .then(function (response) {
        const payload = { assetType: "specie", assets: response.data };
        dispatch({ type: GET_ASSET, payload: payload });
      });
  };
}

export function selectReservation(reservation, callbackSuccess) {
  return function (dispatch) {
    axios
      .get(`${API_ENDPOINT}${RESERVATION_URL}${/id/}${reservation.id}`)
      .then(function (response) {
        dispatch({
          type: ACTION_TYPE_SELECT_RESERVATION,
          payload: response.data,
        });
        callbackSuccess(reservation);
      });
  };
}

export function getReservation(reservationId, callback) {
  return function (dispatch) {
    axios
      .get(`${API_ENDPOINT}${RESERVATION_URL}${"/id/" + reservationId}`)
      .then(function (reservation) {
        callback(reservation);
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else if (error.response.status === 401) {
            callback(error.response);
          }
        } else {
          dispatch({ type: GET_RESERVATION, payload: { reservations: [] } });
        }
      });
  };
}

export function getReservations(companyId) {
  return function (dispatch) {
    axios
      .get(`${API_ENDPOINT}${RESERVATION_URL}`, {
        params: {
          company_id: companyId,
        },
      })
      .then(function (response) {
        dispatch({ type: GET_RESERVATION, payload: response.data });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: GET_RESERVATION, payload: { reservations: [] } });
          }
        }
      });
  };
}

export function deleteReservation(reservationId) {
  return function (dispatch) {
    axios
      .delete(`${API_ENDPOINT}${RESERVATION_URL}${"/id/" + reservationId}`)
      .then(function (response) {
        dispatch({ type: GET_RESERVATION, payload: response.data });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {
   
          } else {
            dispatch({ type: GET_RESERVATION, payload: { reservations: [] } });
          }
        }
      });
  };
}

export function deleteReservationDocument(documentId) {
  return function (dispatch) {
    axios
      .delete(`${API_ENDPOINT}${RESERVATION_URL}${"/document/" + documentId}`)
      .then(function (response) {
        dispatch({ type: GET_RESERVATION, payload: response.data });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: GET_RESERVATION, payload: { reservations: [] } });
          }
        }
      });
  };
}

export function setReservationState(reservationId, stateId) {
  return function (dispatch) {
    axios
      .put(
        `${API_ENDPOINT}${RESERVATION_URL + "/"}${reservationId + "/"}${
          "state/" + stateId
        }`
      )
      .then(function (response) {
        dispatch({ type: SET_RESERVATION_OK, payload: response.data });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({
              type: SET_RESERVATION_NOK,
              payload: { reservations: [] },
            });
          }
        }
      });
  };
}

export function exporterGetAsset(assetType, companyId) {
  return function (dispatch) {
    axios
      .get(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${
          "/" + assetType
        }`
      )
      .then(function (response) {
        const payload = { assetType: assetType, assets: response.data };
        dispatch({ type: GET_ASSET, payload: payload });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: GET_ASSET, payload: {} });
          }
        }
      });
  };
}

export function exporterDeleteAsset(assetType, assetId, companyId) {
  return function (dispatch) {
    axios
      .delete(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${
          "/" + assetType
        }`,
        {
          params: {
            asset_id: assetId,
          },
        }
      )
      .then(function (response) {
        const payload = { assetType: assetType, assets: response.data };
        dispatch({ type: GET_ASSET, payload: payload });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: GET_ASSET, payload: {} });
          }
        }
      });
  };
}

export function exporterSetAsset(assetType, asset, companyId) {
  return function (dispatch) {
    const json = JSON.stringify(asset);
    axios
      .post(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${
          "/" + assetType
        }`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        const payload = { assetType: assetType, assets: response.data };
        dispatch({ type: GET_ASSET, payload: payload });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: GET_ASSET, payload: {} });
          }
        }
      });
  };
}

export function exporterGetAllAssets(companyId) {
  return function (dispatch) {
    axios
      .get(`${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/all"}`, {
        params: {
          company_id: companyId,
        },
      })
      .then(function (response) {
        const assetArrays = response.data;
        dispatch({ type: GET_ALL_ASSET, payload: assetArrays });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: GET_ASSET, payload: {} });
          }
        }
      });
  };
}

export function exporterAddBoardingDetail(companyId, boardingId, detail) {
  return function (dispatch) {
    const json = JSON.stringify(detail);
    axios
      .post(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/boarding/"}${boardingId}${"/details"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {});
  };
}

export function exporterGetBoadring(companyId, boardingId, callback) {
  return function (dispatch) {
    axios
      .get(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/boarding/"}${boardingId}`
      )
      .then(function (response) {
        dispatch({ type: GET_BOARDING, payload: response.data });
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((err) => {});
  };
}

export function exporterGetBoardingMessages(companyId, boardingId) {
  return function (dispatch) {
    axios
      .get(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/boarding/"}${boardingId}${"/messages"}`
      )
      .then(function (response) {
        dispatch({
          type: GET_CURRENT_BOARDING_MESSAGES,
          payload: response.data,
        });
      })
      .catch((err) => {});
  };
}

export function exporterSendBoardingMessages(companyId, boardingId, message) {
  return function (dispatch) {
    const jsonPayload = JSON.stringify({ message: message });
    axios
      .post(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/boarding/"}${boardingId}${"/messages"}`,
        jsonPayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: GET_CURRENT_BOARDING_MESSAGES,
          payload: response.data,
        });
      })
      .catch((err) => {});
  };
}

export function exporterGetBoardings(companyId, callback, periodId=0) {
  return function (dispatch) {
    axios
      .get(
        `${API_ENDPOINT}${"exporter/company/"}${companyId}${"/async/boarding?limit=100&format=tableView&period="}${periodId}`
      )
      .then(function (response) {
        waitOnResponse(response.data, (result) => 
        {
          dispatch({ type: GET_BOARDINGS, payload: result })
          if (typeof callback === "function") {
            callback();
          }
        });        
      })
      .catch((err) => {});
  };
}

export function exporterDeleteBoadring(companyId, boardingId, onDeleteDone) {
  return function (dispatch) {
    axios
      .delete(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/boarding/"}${boardingId}`
      )
      .then(function () {
        if (typeof onDeleteDone === "function") {
          onDeleteDone()
        }
      })
      .catch((err) => {});
  };
}

export function exporterSaveBoardingDoc(companyId, boarding, callback) {
  return function (dispatch) {
    const json = JSON.stringify(boarding);
    axios
      .post(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/boarding"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

           } else if (error.response.status === 401) {
            dispatch(showErrorMessage(error.response.data.error));
          } else {
            callback(error.response);
          }
        }
      });
  };
}

export function getGrowerDocument(documentType, companyId) {
  return function(dispatch) {
    // /document/type/<string:document_type>
    axios.get(`${API_ENDPOINT}${"document/type/"}${documentType}${"?company_id=" + companyId}`).then((response) => {
      dispatch({ type: GET_GROWER_DOCUMENT, payload: response.data });
    });
  }
}

export function doLogin(credentials, callbackSuccess, callbackError) {
  return function (dispatch) {
    axios
      .post(`${API_ENDPOINT}${LOGIN_URL}`, credentials)
      .then(function (response) {
        localStorage.setItem("user", JSON.stringify(response.data));
        dispatch({ type: LOGIN_SUCCESS, payload: response.data });
        callbackSuccess();
      })
      .catch((err) => {
        dispatch({ type: LOGIN_FAILED, payload: {} });
        callbackError(err);
      });
  };
}

export function updateNotificationState(notificationStates) {
  return function (dispatch) {
    dispatch({ type: CHANGE_NOTIFICATIONS, payload: notificationStates });
  };
}

export function selectRole(role, callbackSuccess) {
  return function (dispatch) {
    localStorage.setItem("selectedRole", JSON.stringify(role));
    dispatch({ type: SELECT_ROLE, payload: role });
    callbackSuccess();
  };
}

export function doLogOut() {
  return function (dispatch) {
    axios.put(`${API_ENDPOINT}${LOGOUT_URL}`).then(function (response) {
      localStorage.removeItem("user");
      localStorage.removeItem("selectedRole");
      document.location = "/login";
    });
  };
}

export function getCompanies(
  companies_ids,
  showAll = false,
  company_type_id = 0
) {
  return function (dispatch) {
    axios
      .get(`${API_ENDPOINT}${COMPANY_URL}`, {
        params: {
          company_ids: companies_ids,
          all: showAll,
          company_type_id: company_type_id,
        },
      })
      .then(function (response) {
        dispatch({ type: GET_COMPANIES, payload: response.data });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: GET_COMPANIES, payload: { companies: [] } });
          }
        }
      });
  };
}

export function associateToCompany(company_id, request_company_id) {
  return function (dispatch) {
    //to/<int:company_id>/for/<int:request_company_id>
    axios
      .put(
        `${API_ENDPOINT}${COMPANY_ASSOCIATE_URL}${"/to/"}${company_id}${"/for/"}${request_company_id}`
      )
      .then(function (response) {
        dispatch({ type: ASSOCIATE_COMPANY_SUCCESS, payload: response.data });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: ASSOCIATE_COMPANY_FAIL, payload: [] });
          }
        }
      });
  };
}

export function getAssociationRequest(company_id, request_company_id = 0) {
  return function (dispatch) {
    //to/<int:company_id>/for/<int:request_company_id>
    axios
      .get(
        `${API_ENDPOINT}${COMPANY_ASSOCIATE_URL}${"/to/"}${company_id}${"/for/"}${request_company_id}`
      )
      .then(function (response) {
        dispatch({ type: GET_ASSOCIATION_REQUEST, payload: response.data });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: GET_ASSOCIATION_REQUEST, payload: [] });
          }
        }
      });
  };
}

export function acceptAssociationRequest(request_id) {
  return function (dispatch) {
    //to/<int:company_id>/for/<int:request_company_id>
    axios
      .put(
        `${API_ENDPOINT}${COMPANY_ASSOCIATE_URL}${"/"}${request_id}${"/accept"}`
      )
      .then(function (response) {
        dispatch({ type: GET_ASSOCIATION_REQUEST, payload: response.data });
      })
      .catch(function (error) {
        if (error.response) {
          //No estamos conectados, renviar a la pagina de inicio
          if (error.response.status === 403) {

          } else {
            dispatch({ type: VALIDATE_ASSOCIATION_REQUEST, payload: [] });
          }
        }
      });
  };
}

export function closeErrorMessage() {
  return function (dispatch) {
    dispatch({ type: CLOSE_ERROR_MESSAGE, payload: [] });
  };
}

export function showErrorMessage(error) {
  return function (dispatch) {
    console.log("Called");
    dispatch({ type: OPEN_ERROR_MESSAGE, payload: error });
  };
}

export function setAppBarOptions(options) {
  return function (dispatch) {
    dispatch({ type: SET_APP_BAR_OPTIONS, payload: options });
  };
}

export function setPeriod(periodId) {
  return function (dispatch) {
    dispatch({ type: SET_PERIOD_ID, payload: periodId });
  };
}
