import React from 'react';
import AdminNav from './admin-nav'
import '../exporter.css'

function AdminMain({ children }) {
    return (
        <React.Fragment>
            <AdminNav />
                {children}
        </React.Fragment>)
}
export default AdminMain;