import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import {
  EditOutlined,
  PrintOutlined,
  AttachMoneyOutlined,
  LibraryBooksOutlined,
  DeleteForeverOutlined,
  MonetizationOnSharp,
  InsertDriveFile,
} from "@material-ui/icons";
import MUIDataGrid from "./MUI_DataGrid";
import GenericDialog from "./generic-dialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { exporterDeleteBoadring } from "../actions/index";
import { API_ENDPOINT } from "../constants";
import axios from "axios";
import { saveAs } from "file-saver";
import * as Excel from "exceljs";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "../app.css";
import { waitOnResponse } from "../actions/utils";
import GenericLoader from "./generic-loader";

const generateExcel = async (boardingCollection) => {
  if (boardingCollection.length > 0) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Export. Boardings.");

    worksheet.columns = Object.keys(boardingCollection[0]).map((k) => {
      return { header: k, key: k };
    });
    worksheet.addRows(boardingCollection);

    const buffer = await workbook.xlsx.writeBuffer();
    var blob = new Blob([buffer]);
    saveAs(blob, "export-boarding.xlsx");
  }
};

function BoardingView({ boardings, display, isLoaded, refreshBoardings }) {
  /* Propriedades de la data grid */
  const [selectedForDelete, setSelected] = React.useState<number>(0);
  const [selectedRowContext, setSelectedRow] = React.useState<number>(0);
  const [billReportCreated, setBillReportCreated] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const company = useSelector(
    (state) => state.currentUser.selectedRoleDisplay.company
  );

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleGenerateCost = (boardingId, shipping_id) => {
    //Exporta el embarque a un informe de costos o, si ya fue hecho, mostrar el informe
    if (shipping_id) {
      //Tenemos un despacho/costo asociado, lo mostramos
      navigate("/modules/cost/prices/" + shipping_id);
    }
    else {
      //No hay despacho/costo asociado, lo creamos
      setBillReportCreated(false);
      axios
        .put(
          `${API_ENDPOINT}${"/exporter/asset/company/" + company.id + "/boarding/" + boardingId + "/cost"}`
        )
        .then((result) => {
          //Nos devuelve un id de tarea que corresponde a la generación
          waitOnResponse(result.data, (shippingId) => {
            //Escondemos el loader y reenviamos a la pagina de costos
            setBillReportCreated(true);
            navigate("/modules/cost/prices/" + shippingId);
          })
        });
    }
  }

  const doCloneRow = () => {
    setContextMenu(null);
    if (selectedRowContext) {
      cloneBoarding(selectedRowContext);
    }
  };

  const columns = [
    { field: "code", headerName: t("boarding.grid.code"), width: 75 },
    {
      field: "dateInPacking",
      headerName: t("boarding.grid.dateInPacking"),
      width: 110,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return moment(value).format("YYYY-MM-DD");
      },
    },
    {
      field: "packingName",
      headerName: t("boarding.grid.packingName"),
      width: 150,
    },
    {
      field: "receiverName",
      headerName: t("boarding.grid.receiverName"),
      width: 150,
    },
    {
      field: "container",
      headerName: t("boarding.grid.container"),
      width: 100,
    },
    { field: "state", headerName: t("boarding.grid.state"), width: 100 },
    {
      field: "actionEdit",
      headerName: t("boarding.grid.edit"),
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Link to={"/modules/exporter/boarding/" + params.id + "/edit"}>
            <EditOutlined />
          </Link>
        );
      },
    },
    {
      field: "actionPrint",
      headerName: t("boarding.grid.print"),
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Link
            to={"/modules/exporter/boarding/" + params.id + "/print"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PrintOutlined />
          </Link>
        );
      },
    },
    {
      field: "actionBill",
      headerName: t("boarding.grid.bill"),
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Link
            to={"/modules/exporter/boarding/" + params.id + "/bill"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InsertDriveFile />
          </Link>
        );
      },
    },
    {
      field: "actionView",
      headerName: t("boarding.grid.attach"),
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Link to={"/modules/exporter/boarding/" + params.id + "/documents"}>
            <LibraryBooksOutlined />
          </Link>
        );
      },
    },
    {
      field: "actionCost",
      headerName: "Valorizar",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Button
            variant={"text"}
            onClick={() => {
              handleGenerateCost(params.id, params.shipping_id)
            }}
          >
            <MonetizationOnSharp />
          </Button>
        );
      },
    },
    {
      field: "actionDelete",
      headerName: t("boarding.grid.delete"),
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Button
            variant={"contained"}
            onClick={() => {
              setSelected(params.id);
            }}
          >
            <DeleteForeverOutlined />
          </Button>
        );
      },
    },
  ];

  const deleteBoarding = (targetBoardingid: number) => {
    //Eliminamos y enviamos un refresh una vez terminado
    dispatch(exporterDeleteBoadring(company.id, targetBoardingid, () => refreshBoardings()));
  };

  const getBoardingForExcel = () => {
    axios
      .get(
        `${API_ENDPOINT}${"/exporter/company/" + company.id + "/async/boarding?format=export"
        }`
      )
      .then((result) => {
        waitOnResponse(result.data, (result) => generateExcel(result));
      });
  };

  const cloneBoarding = (targetBoardingid: number) => {
    axios
      .put(
        `${API_ENDPOINT}${"/exporter/asset/company/" +
        company.id +
        "/boarding/" +
        targetBoardingid +
        "?copyDet=0"
        }`
      )
      .then((result) => {
        //Recuperamos el boarding clonado y lo abremos
        const newId = result.data.new_id;
        navigate("/modules/exporter/boarding/" + newId + "/edit?clone=1");
      });
  };

  const formatedRows = boardings.map((b) => {
    //Traducción del estado
    const stateDisplay = t("boarding.states." + b.state_id);

    return {
      id: b.id,
      code: b.code,
      dateInPacking: b.dateInPacking,
      packingName: b.packingName,
      receiverName: b.receiverName,
      container: b.container,
      state_id: b.state_id,
      shipping_id: b.shipping_id,
      state: stateDisplay,
    };
  });

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/modules/exporter/boarding/create")}
      >
        {t("boarding.createBoarding")}
      </Button>
      <div style={{ height: "450px" }}>
        <MUIDataGrid
          rows={formatedRows}
          lang={"es"}
          columns={columns}
          exportToExcel={getBoardingForExcel}
          isLoaded={isLoaded}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
            },
          }}
        />

        {/* Menu contextual */}
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={doCloneRow}>{t("common.actions.copy")}</MenuItem>
        </Menu>
      </div>

      <GenericDialog
        message={"Seguro desea eliminar el instructivo selecionado?"}
        title="Eliminar instructivo"
        buttons={{ cancel: "Cancelar", confirm: "Confirmar" }}
        openState={selectedForDelete > 0}
        assetId={selectedForDelete}
        onClose={(status) => {
          if (status === 1) {
            deleteBoarding(selectedForDelete);
          }
          setSelected(-1);
        }}
      />

      <GenericLoader
        title={"Generando informe de costos..."}
        buttons={{ cancel: "Cancelar" }}
        openState={!billReportCreated}
        message={""} onClose={() => { }} assetId={1} />
    </div>
  );
}

export default BoardingView;
