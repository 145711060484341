import React from 'react';
import { Route } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";
import RequireAuth from './router_require_auth'
import ReservationList from './../containers/reservation-list'
import ReservationWizard from './../containers/reservation-wizard'
import DocumentForm from './../containers/document-form'
import ReservationView from './../containers/reservation-view'


function DocumentFormSearch() {
  const searchParams = useSearchParams()[0];
  let companyId = searchParams.get("cpy")
  let growerCSG = searchParams.get("csg")
  return <DocumentForm searchQuery={{ csg: growerCSG, company: companyId }} />
}

function ReservationViewCall() {
  let { reservationId } = useParams();
  return (<ReservationView reservationId={reservationId} />)
}

function ReservationWizardCall() {
  let { reservationId, mode } = useParams();

  if (mode === 'edit') {
      return (<ReservationWizard editionMode={true} reservationId={reservationId} />)
  }
  else if (mode === 'create') {
      return (<ReservationWizard />)
  }
  else {
      return (<ReservationWizard delete={true} reservationId={reservationId} />)
  }
}

export default (
  <Route path="/modules/packing">
    <Route
      path="dashboard"
      element={
        <RequireAuth>
          <ReservationList
            displayCount="5"
            reservationStateFilter="ultimas"
          />
        </RequireAuth>
      }
    />

    <Route path="documents">
      <Route
        index
        element={
          <RequireAuth>
            <DocumentForm />
          </RequireAuth>
        }
      />
      <Route
        path="search"
        element={
          <RequireAuth>
            <DocumentFormSearch />
          </RequireAuth>
        }
      />
    </Route>

    <Route path="reservation">
      <Route
        index
        element={
          <RequireAuth>
            <ReservationList displayStyle="table" />
          </RequireAuth>
        }
      />
      <Route
        path="create"
        element={
          <RequireAuth>
            <ReservationWizardCall />
          </RequireAuth>
        }
      />
      <Route
        path=":reservationId/:mode"
        element={
          <RequireAuth>
            <ReservationWizardCall />
          </RequireAuth>
        }
      />
      <Route
        path=":reservationId"
        element={
          <RequireAuth>
            <ReservationViewCall />
          </RequireAuth>
        }
      />
    </Route>
  </Route>
);
