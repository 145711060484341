import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useOutletContext, useNavigate } from "react-router-dom";

function SignUpForm() {
  const navigate = useNavigate();
  //@ts-ignore
  const [
    t,
    credencial,
    setCredencial,
    signIn,
    passwordRecovery,
    signUp,
    confirmSignUp,
    resendConfirmCode,
    confirmNewPassword,
  ] = useOutletContext();

  React.useEffect(() => {
    //Utilizar el usuario en parametro si existe
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    if (email) {
      setCredencial({
        ...credencial,
        username: email,
      });
    }
    const companyInvite = params.get("ci");
    if (companyInvite) {
      setCredencial({
        ...credencial,
        companyInvite: companyInvite,
      });
    }
  }, []);

  const enforcePasswordPolicy = (password, confirm) => {
    //Validamos que tenga mayuscula, minuscula, cifras y 8 caracteres
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    return strongRegex.test(password) && password === confirm;
  };
  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label={t("singin.email")}
        name="email"
        autoComplete="email"
        value={credencial.username}
        autoFocus
        onChange={(e) => {
          setCredencial({
            ...credencial,
            username: e.target.value,
          });
        }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label={t("singin.password")}
        type="password"
        id="password"
        autoComplete="password-new"
        value={credencial.password}
        onChange={(e) => {
          setCredencial({
            ...credencial,
            password: e.target.value,
          });
        }}
      />
      <i>{t("signUp.passwordPolicy")}</i>

      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label={t("signUp.confirm")}
        type="password"
        id="passwordConfirm"
        autoComplete="new-password"
        value={credencial.confirm}
        onChange={(e) => {
          setCredencial({
            ...credencial,
            confirm: e.target.value,
          });
        }}
      />
      <Button
        type="button"
        fullWidth
        variant="contained"
        disabled={
          !enforcePasswordPolicy(credencial.password, credencial.confirm)
        }
        sx={{ mt: 3, mb: 2 }}
        onClick={() => signUp()}
      >
        {t("signUp.create")}
      </Button>

      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => navigate("/auth/confirmRegister")}
      >
        {t("signUp.alreadyHaveAcode")}
      </Button>

      <Button
        type="button"
        fullWidth
        variant="outlined"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => navigate(-1)}
      >
        {t("common.goBack")}
      </Button>
    </>
  );
}

export default SignUpForm;
