import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, AppBar, Toolbar, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import "../app.css";
import MainNav from "../components/topNav_mui";
import AccountMenu from "../components/menu-account";
import GenericErrorPopup from "../components/generic-error-popup";

function App() {
  const layout = useSelector((state) => state.layout);
  const user = useSelector((state) => state.currentUser);
  const company = (user && user.selectedRoleDisplay.company) || {};
  const appBarOptions =
    useSelector((state) => state.layout.appBarOptions) || [];

  const errorMessage = useSelector((state) => state.layout.error) || null;

  const [title, setTitle] = useState("");

  const marginDrawer = window.innerWidth > 720 ? 200 : 75;
  const drawerWidth = layout.type === "menu" ? marginDrawer : 0;

  return (
    <React.Fragment>
      {layout.type === "print" ? (
        <Outlet />
      ) : (
        <>
          <AppBar
            position="static"
            sx={{
              width: "100%",
            }}
          >
            <Toolbar variant="dense" sx={{ justifyContent: "right" }}>
              <Box sx={{left: drawerWidth + 24, position: 'absolute'}}>
                {appBarOptions.map((option, index) => {
                  return option;
                })}
              </Box>
              <Box sx={{ justifyContent: "right" }}>
                {company && <AccountMenu company={company} user={user} />}
              </Box>
            </Toolbar>
          </AppBar>
          <MainNav
            setTitle={(title) => {
              setTitle({
                title: title,
              });
            }}
            width={drawerWidth}
          />
          <Box
            sx={{
              padding: "12px",
              marginLeft: { xs: "75px", sm: "200px" },
              maxWidth: "100%",
            }}
          >
            <GenericErrorPopup errorMessage={errorMessage} />
            <Outlet />
          </Box>
        </>
      )}
    </React.Fragment>
  );
}

export default App;
