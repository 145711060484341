import { Route } from "react-router-dom";

import RequireAuth from './router_require_auth'
import BrokerBoarding from './../containers/broker-boarding'
import ExporterTracking from './../containers/exporter-tracking'

export default  <Route path="/modules/broker">
                              <Route path="boarding" element={
                                <RequireAuth>
                                    <BrokerBoarding />
                                </RequireAuth>
                              }/>

                              <Route path="tracking" element={
                                <RequireAuth>
                                    <ExporterTracking />
                                </RequireAuth>
                              }/>
                              </Route>
