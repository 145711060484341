import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useOutletContext } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function SignInForm() {
  const navigate = useNavigate();

  //@ts-ignore
  const [t, credencial, setCredencial, signIn, passwordRecovery, signUp, confirmSignUp, resendConfirmCode, confirmNewPassword] = useOutletContext();

  return (<Box
    sx={{
      my: 8,
      mx: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Box component="form" noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label={t('singin.email')}
        name="email"
        autoComplete="email"
        value={credencial.username}
        autoFocus
        onChange={(e) => {
          setCredencial({
            ...credencial,
            username: e.target.value
          })
        }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label={t('singin.password')}
        type="password"
        id="password"
        autoComplete="current-password"
        value={credencial.password}
        onChange={(e) => {
          setCredencial({
            ...credencial,
            password: e.target.value
          })
        }}
      />

      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => signIn()}
      >
        {t('singin.doSignIn')}
      </Button>
      <Grid container>
        <Grid item xs>

          <Button
            type="button"
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => passwordRecovery()}
          >
            {t('singin.passwordRecovery')}
          </Button>

        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => navigate("/auth/register")}
          >
            {t('singin.createAccount')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Box>)
}

export default SignInForm;