import React from "react";

function Icon({ size }) {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
      version="1.1"
      viewBox="0 0 135.467 135.467"
    >
      <defs>
        <filter
          id="filter306"
          width="1.75"
          height="2.798"
          x="-0.375"
          y="-0.899"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodColor="#FD70AE"
            floodOpacity="1"
            result="result4"
          ></feFlood>
          <feGaussianBlur
            in="SourceAlpha"
            result="result1"
            stdDeviation="15"
          ></feGaussianBlur>
          <feComposite
            in="result1"
            in2="result4"
            k2="4"
            k4="-2"
            operator="arithmetic"
            result="result3"
          ></feComposite>
          <feColorMatrix
            result="result2"
            values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 10 -3"
          ></feColorMatrix>
          <feComposite
            in="result4"
            in2="result2"
            operator="in"
            result="fbSourceGraphic"
          ></feComposite>
          <feBlend
            in="fbSourceGraphic"
            in2="SourceGraphic"
            mode="normal"
            result="result5"
          ></feBlend>
        </filter>
      </defs>
      <g>
        <g
          fontFamily="sans-serif"
          transform="matrix(1.36543 0 0 1.15486 -18.627 29.818)"
        >
          <text
            xmlSpace="preserve"
            style={{
              lineHeight: 0.328011,
              InkscapeFontSpecification: "'sans-serif, Normal'",
              fontVariantLigatures: "normal",
              fontVariantCaps: "normal",
              fontVariantNumeric: "normal",
              fontVariantEastAsian: "normal",
            }}
            x="4.498"
            y="64.823"
            fill="#424242"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeWidth="0.34"
            filter="url(#filter306)"
            fontSize="51.687"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="normal"
            transform="matrix(.71433 0 0 .69894 22.339 15.44)"
          >
            <tspan
              x="4.498"
              y="64.823"
              style={{
                InkscapeFontSpecification: "'Open Sans Light'",
                fontVariantLigatures: "normal",
                fontVariantCaps: "normal",
                fontVariantNumeric: "normal",
                fontVariantEastAsian: "normal",
              }}
              fill="#424242"
              fillOpacity="1"
              strokeDasharray="none"
              strokeMiterlimit="4"
              strokeWidth="0.34"
              fontFamily="Open Sans"
              fontSize="51.687"
              fontStretch="normal"
              fontStyle="normal"
              fontVariant="normal"
              fontWeight="300"
            >
              H
              <tspan
                style={{}}
                fontFamily="Open Sans"
                fontSize="43.735"
                fontStretch="normal"
                fontStyle="normal"
                fontVariant="normal"
                fontWeight="300"
              >
                U
              </tspan>
              B
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "1.25" }}
            x="109.016"
            y="56.953"
            strokeWidth="0.211"
            fontSize="41.86"
            transform="scale(.91966 1.08735)"
          ></text>
        </g>
        <ellipse
          cx="-75.763"
          cy="32.134"
          fill="#fff"
          fillOpacity="0.996"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="0.012"
          rx="12.473"
          ry="12.19"
          transform="rotate(-125.62)"
        ></ellipse>
        <g transform="matrix(.81569 0 0 .80333 10.17 29.32)">
          <ellipse
            cx="70.247"
            cy="22.622"
            fill="#222544"
            fillOpacity="1"
            stroke="#fff"
            strokeLinejoin="round"
            strokeWidth="0.012"
            rx="23.68"
            ry="24.209"
          ></ellipse>
          <path
            fill="#fff"
            fillRule="evenodd"
            stroke="#000"
            strokeWidth="0.265"
            d="M65.104 19.972c-1.148-1.244-.45-3.185.819-4.032 2.024-1.352 4.718-.298 5.855 1.696 1.583 2.777.15 6.267-2.574 7.678-3.522 1.825-7.82.003-9.5-3.452-2.073-4.262.141-9.378 4.329-11.322 5-2.323 10.938.285 13.145 5.206 2.575 5.74-.43 12.5-6.084 14.968-6.476 2.828-14.06-.573-16.79-6.961-3.083-7.214.715-15.624 7.838-18.614 7.95-3.337 17.187.859 20.436 8.717a16.673 16.673 0 011.156 8.133"
            transform="matrix(1.29767 .26635 -.20735 1.24222 -13.419 -21.384)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;