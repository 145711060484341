import React, {useState} from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

import {
    LibraryBooksOutlined,
    BlockOutlined,
    VpnKeyOutlined,
    DeleteForeverOutlined
} from "@material-ui/icons";

import AdminUserAccess from './admin-user-access';

import MUIDataGrid from "./MUI_DataGrid"
import '../app.css'

function AdminUserList({ users, display }) {
    /* Propriedades de la data grid */
    const [selectedUser, setSelected] = useState();
    const [showAccess, setShowAccess] = useState(false);
    
    const columns = [
        { field: 'id', headerName: '#', minWidth: 50,},
      { field: 'display_name', headerName: 'Nombre', minWidth: 200,},
      { field: 'email', headerName: 'Email', minWidth: 200,},
      { field: 'last_connection_date', headerName: 'Creación', minWidth: 200,},
      {
        field: "actionView",
        headerName: "Ver accesos",
        sortable: false,
        minWidth: 50,
        renderCell: (params) => {
            return <Button variant="outlined" onClick={() => showUserAccess(users.filter(U => U.id === params.id)[0])}>
                <LibraryBooksOutlined />
            </Button>;
        }
    },
    {
        field: "actionBlock",
        headerName: "Bloquear",
        sortable: false,
        minWidth: 50,
        renderCell: (params) => {
            return <Link to={'/modules/admin/user/' + params.id + '/block'}>
                <BlockOutlined />
            </Link>;
        }
    },
    {
        field: "actionReset",
        headerName: "Resetear Contraseña",
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
            return <Link to={'/modules/admin/user/' + params.id + '/resetPass'}>
                <VpnKeyOutlined />
            </Link>;
        }
    },
    {
        field: "actionDelete",
        headerName: "Eliminar",
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
            return <Link to={'/modules/admin/user/' + params.id + '/delete'}>
                <DeleteForeverOutlined />
            </Link>;
        }
    },
    ];

    const showUserAccess = (user) => {
        setSelected(user);
        setShowAccess(true);
    }

    return (<div>
        <AdminUserAccess user={selectedUser} open={showAccess} handleClose={() => setShowAccess(false)}/>
            <div style={{ height: '450px' }}>
                <MUIDataGrid rows={users} columns={columns} lang={'eng'} />
            </div>
        </div>)
}

export default AdminUserList;
