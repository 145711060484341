import React from "react";
import { Link } from "react-router-dom";
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  Drawer,
  TextField,
  MenuItem,
} from "@mui/material";
import {
  CloudCircleOutlined,
  BorderColorOutlined,
  DirectionsBoatOutlined,
  MapOutlined,
  AttachMoneyOutlined,
  ArrowBackOutlined,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import "../exporter.css";

function CostNav() {
  const { t } = useTranslation();
  const menu = [
    {
      name: t("menu.expoter-menu.prices"),
      icon: <AttachMoneyOutlined />,
      link: "/modules/cost/prices",
    },
    {
      name: t("menu.expoter-menu.assets"),
      icon: <BorderColorOutlined />,
      link: "/modules/cost/asset",
    },
    {
      name: t("common.goBack"),
      icon: <ArrowBackOutlined />,
      link: "/dashboard",
    }
  ];
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [current, setCurrent] = React.useState("");

  return (
    <Drawer
      open={drawerOpen}
      variant={"permanent"}
      anchor={"left"}
      onClose={() => setDrawerOpen(false)}
    >
      <div style={{ width: "200px" }}>
        <ListItem button>
          <ListItemText primary={"Cost > " + current} />
        </ListItem>
        {menu.map((item, index) => (
          <Link
            to={item.link}
            key={index}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem
              button
              onClick={() => {
                setCurrent(item.short || item.name);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </Link>
        ))}
      </div>
    </Drawer>
  );
}

export default CostNav;
