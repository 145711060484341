import React from "react";
import { Route, useParams } from "react-router-dom";

import RequireAuth from "./router_require_auth";
import RequirePayment from "./router_require_payment";
import CostMain from "./../containers/cost-main";
import ExporterPriceMatrix from "./../containers/exporter-price-matrix";
import ShippingPrice from "./../components/exporter-shipping-price";

//Assets
import AssetView from "./../containers/exporter-asset-view";
import Grower from "./../containers/exporter-asset-grower";
import Packaging from "./../containers/exporter-asset-packaging";
import Variety from "./../containers/exporter-asset-variety";
import Customer from "./../containers/exporter-asset-customer";
import ShippingCompany from "./../containers/exporter-asset-shippingCompany";
import CustomsAgent from "./../containers/exporter-asset-customsAgent";
import Exporter from "./../containers/exporter-asset-exporter";
import Packings from "./../containers/exporter-asset-packings";
import Consignee from "./../containers/exporter-asset-consignee";
import ShippingAgent from "./../containers/exporter-asset-shippingAgent";
import Size from "./../containers/exporter-asset-size";
import Color from "./../containers/exporter-asset-color";
import Cost from "./../containers/exporter-asset-cost";
import Vessel from "./../containers/exporter-asset-vessel";
import Carrier from "./../containers/exporter-asset-carrier";
import Consolida from "./../containers/exporter-asset-consolida";
import Forwarder from "./../containers/exporter-asset-forwarder";
import Notify from "./../containers/exporter-asset-notify";
import Deposit from "./../containers/exporter-asset-deposit";


const COST_LICENSE_TYPE = "cost_lic";

export default (
  <Route path="/modules/cost">
    <Route
      path="portal"
      element={
        <RequireAuth>
          <RequirePayment licenseType={COST_LICENSE_TYPE}>
            <CostMain>

            </CostMain>
          </RequirePayment>
        </RequireAuth>
      }
    />
    <Route path="asset">
      <Route
        index
        element={
          <RequireAuth>
            <RequirePayment licenseType={COST_LICENSE_TYPE}>
              <CostMain>
                <AssetView assetModule={'cost'} />
              </CostMain>
            </RequirePayment>
          </RequireAuth>
        }
      />

      <Route
        path=":asset_type"
        element={
          <RequireAuth>
            <RequirePayment licenseType={COST_LICENSE_TYPE}>
              <CostMain>
                <ExporterAssetSwitch />
              </CostMain>
            </RequirePayment>
          </RequireAuth>
        }
      />
    </Route>
    <Route path="prices">
      <Route
        index
        element={
          <RequireAuth>
            <RequirePayment licenseType={COST_LICENSE_TYPE}>
              <CostMain>
                <ExporterPriceMatrix />
              </CostMain>
            </RequirePayment>
          </RequireAuth>
        }
      />
      <Route
        path=":shippingId"
        element={
          <RequireAuth>
            <RequirePayment licenseType={COST_LICENSE_TYPE}>
              <CostMain>
                <ShippingPrice />
              </CostMain>
            </RequirePayment>
          </RequireAuth>
        }
      />
    </Route>
  </Route>
);

function ExporterAssetSwitch() {
  let { asset_type } = useParams();
  const selectedRole = localStorage.getItem("selectedRole")
    ? JSON.parse(localStorage.getItem("selectedRole"))
    : {};

  switch (asset_type) {
    case "grower":
      return <Grower companyId={selectedRole.company.id} />;
    case "packaging":
      return <Packaging companyId={selectedRole.company.id} />;
    case "variety":
      return <Variety companyId={selectedRole.company.id} />;
    case "broker":
      return <Customer companyId={selectedRole.company.id} />;
    case "packing":
      return <Packings companyId={selectedRole.company.id} />;
    case "exporter":
      return <Exporter companyId={selectedRole.company.id} />;
    case "customsAgent":
      return <CustomsAgent companyId={selectedRole.company.id} />;
    case "shippingCompany":
      return <ShippingCompany companyId={selectedRole.company.id} />;
    case "consignee":
      return <Consignee companyId={selectedRole.company.id} />;
    case "shippingAgent":
      return <ShippingAgent companyId={selectedRole.company.id} />;
    case "size":
      return <Size companyId={selectedRole.company.id} />;
    case "color":
      return <Color companyId={selectedRole.company.id} />;
    case "cost":
      return <Cost companyId={selectedRole.company.id} />;
    case "vessel":
      return <Vessel companyId={selectedRole.company.id} />;
    case "carrier":
      return <Carrier companyId={selectedRole.company.id} />;
    case "consolida":
      return <Consolida companyId={selectedRole.company.id} />;
    case "forwarder":
      return <Forwarder companyId={selectedRole.company.id} />;
    case "notify":
      return <Notify companyId={selectedRole.company.id} />;
    case "deposit":
      return <Deposit companyId={selectedRole.company.id} />;
    default:
      return <AssetView />;
  }
}