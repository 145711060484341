import React, { useEffect, useState } from "react";
import { Button, Grid, Box, Typography, Avatar } from "@mui/material";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import GenericLoader from "../components/generic-loader";
import {
  getCompanies,
  getAssociationRequest,
  acceptAssociationRequest,
} from "../actions/index";
import { API_ENDPOINT } from "../constants";
import { useTranslation } from "react-i18next";
import axios from "axios";

function ProfileForm() {
  // @ts-ignore
  const user = useSelector((state) => state.currentUser);
  const company = user.selectedRoleDisplay.company;
  const { t } = useTranslation();

  const getAccountStatus = (user) => {
    if (user.credentials.authSource != "AWS") {
      //Solicitamos la migración a AWS
      return (
        <>
          <Button variant="contained" component="button" color="warning">
            {t("profile.migrate")}
          </Button>
        </>
      );
    } else {
      <Button variant="contained" component="button" color="success">
        {t("profile.accountReady")}
      </Button>;
    }
  };

  const [companyLogo, setLogo] = useState("broken.png");
  const [companyData, setCompanyData] = useState({ links: [], members: [] });
  const [isLoading, setLoading] = useState(false);
  const companyId = company.id;

  const loadCompanyMembers = () => {
    axios
      .get(`${API_ENDPOINT}${"company/"}${companyId}${"/members"}`)
      .then(function (response) {
        setCompanyData({ ...companyData, members: response.data });
      });
  };

  const loadCompanyLinks = () => {
    axios
      .get(`${API_ENDPOINT}${"company/"}${companyId}${"/links"}`)
      .then(function (response) {
        setCompanyData({ ...companyData, links: response.data });
      });
  };

  const loadCompanyLogo = () => {
    //Optenemos el logo de la empresa
    setLoading(true);
    axios
      .get(`${API_ENDPOINT}${"company/"}${companyId}${"/logo"}`)
      .then(function (response) {
        setLogo(response.data.url);
      })
      .catch(() => setLogo("broken.png"))
      .finally(() => setLoading(false));
  };

  const uploadNewLogo = (logoFile) => {
    setLoading(true);
    var formData = new FormData();
    formData.append("logo", logoFile);
    axios
      .post(`${API_ENDPOINT}${"company/"}${companyId}${"/logo"}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        //refresh
        loadCompanyLogo();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadCompanyLogo();
    loadCompanyMembers();
    loadCompanyLinks();
  }, []);

  return (
    <Grid container sx={{ width: "100%" }}>
      <GenericLoader
        title={"Loading company data..."}
        message={""}
        buttons={{ cancel: "Cancelar" }}
        openState={isLoading}
      />
      <Grid container justifyContent="center">
        <Avatar
          sx={{ bgcolor: "deepskyblue", height: "20vh", width: "20vh" }}
          alt={company.name}
          src={companyLogo}
        >
          <Typography variant="h2" component="h2">
            {" "}
            {company.name[0]}
          </Typography>
        </Avatar>
      </Grid>
      <Grid item sm={12} sx={{ textAlign: "center" }}>
        <Typography variant="h4" component="h4">
          {company.name}
        </Typography>
      </Grid>
      <Grid item sm={12} sx={{ textAlign: "center" }}>
        <Button variant="contained" component="label">
          {t("profile.changeLogo")}
          <input
            type="file"
            accept=".png"
            hidden
            onChange={(e) => uploadNewLogo(e.target.files[0])}
          />
        </Button>
      </Grid>

      <Grid item sm={4}>
        <Typography variant="h5" component="h5">
          {t("profile.accountState")}
        </Typography>
      </Grid>

      <Grid item sm={9}>
        {getAccountStatus(user)}
      </Grid>
      <Grid item sm={12} sx={{ paddingTop: 2 }}>
        <Typography variant="h5" component="h5">
          {t("profile.linkedAccounts")}
        </Typography>
        <Grid container>
          <Grid item sm={4}>
            <b>Email</b>
          </Grid>
          <Grid item sm={4}>
            <b>Role</b>
          </Grid>
          <Grid item sm={4}></Grid>
        </Grid>
        {companyData.members.map((m, i) => (
          <Grid container key={i}>
            <Grid item sm={4}>
              {m.user}
            </Grid>
            <Grid item sm={4}>
              {t("common.userAccessType." + m.access)}
            </Grid>
            <Grid item sm={4}></Grid>
          </Grid>
        ))}
      </Grid>
      {companyData.links.length > 0 && (
        <Grid item sm={12} sx={{ paddingTop: 2 }}>
          <Typography variant="h5" component="h5">
            {t("profile.linkedCompanies")}
          </Typography>
          <Grid container>
            <Grid item sm={4}>
              Razón social
            </Grid>
            <Grid item sm={4}>
              Role
            </Grid>
            <Grid item sm={4}>
              Tipo
            </Grid>
            {companyData.links.map((l, i) => (
              <Grid container key={i}>
                <Grid item sm={4}>
                  {l.company}
                </Grid>
                <Grid item sm={4}>
                  {t("common.companyAccessType." + l.access)}
                </Grid>
                <Grid item sm={4}></Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

function mapStateToProps(store) {
  return {
    currentUser: store.currentUser,
    companies: store.companies,
  };
}

const mapDispatchToProps = {
  getCompanies,
  getAssociationRequest,
  acceptAssociationRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
