import { GET_ASSET, SET_ASSET, GET_ALL_ASSET } from '../actions/index'

const initialState = {
    exporters: [],
    packings: [],
    brokers: [],
    consignees: [],
    customAgents: [],
    shippingAgents: [],
    shippingCompanies: [],
    receivers: [],
    harbours: [],
    countries: [],
    species: [],
    packagings: [],
    growers: [],
    colors: [],
    sizes: [],
    costs: [],
    varieties: [],
    vessels: [],
    carriers: [],
    consolidas: [],
    forwarders: [],
    notifies: [],
    deposits: []
}

function setAsset(state, assetType, assets) {
    let updatedState = { ...state };
    switch (assetType) {
      case 'notify':
          updatedState = {
              ...updatedState,
              notifies: assets
          }
          break;
      case 'deposit':
          updatedState = {
              ...updatedState,
              deposits: assets
          }
          break;
      case 'consolida':
          updatedState = {
              ...updatedState,
              consolidas: assets
          }
          break;
      case 'forwarder':
          updatedState = {
              ...updatedState,
              forwarders: assets
          }
          break;
      case 'vessel':
          updatedState = {
              ...updatedState,
              vessels: assets
          }
          break;
      case 'carrier':
          updatedState = {
              ...updatedState,
              carriers: assets
          }
          break;
      case 'cost':
          updatedState = {
              ...updatedState,
              costs: assets
          }
          break;
      case 'color':
          updatedState = {
              ...updatedState,
              colors: assets
          }
          break;
      case 'size':
          updatedState = {
              ...updatedState,
              sizes: assets
          }
          break;
      case 'variety':
          updatedState = {
              ...updatedState,
              varieties: assets
          }
          break;
      case 'packaging':
          updatedState = {
              ...updatedState,
              packagings: assets
          }
          break;
      case 'grower':
          updatedState = {
              ...updatedState,
              growers: assets
          }
          break;
      case 'specie':
          updatedState = {
              ...updatedState,
              species: assets
          }
          break;
        case 'exporter':
            updatedState = {
                ...updatedState,
                exporters: assets
            }
            break;
        case 'packing':
            updatedState = {
                ...updatedState,
                packings: assets
            }
            break;
        case 'broker':
            updatedState = {
                ...updatedState,
                brokers: assets
            }
            break;
        case 'consignee':
            updatedState = {
                ...updatedState,
                consignees: assets
            }
            break;
        case 'customsAgent':
            updatedState = {
                ...updatedState,
                customAgents: assets
            }
            break;
        case 'shippingAgent':
            updatedState = {
                ...updatedState,
                shippingAgents: assets
            }
            break;
        case 'shippingCompany':
            updatedState = {
                ...updatedState,
                shippingCompanies: assets
            }
            break;
        case 'receiver':
            updatedState = {
                ...updatedState,
                receivers: assets
            }
            break;
        case 'harbour':
            updatedState = {
                ...updatedState,
                harbours: assets
            }
            break;
        case 'country':
            updatedState = {
                ...updatedState,
                countries: assets
            }
            break;

        default:
            break;
    }

    return updatedState;
}

export default function AssetReducer(state = initialState, action) {
    const assets = action.payload && action.payload.assets || [];
    const assetType = action.payload && action.payload.assetType || 'NA';

    switch (action.type) {
        case GET_ALL_ASSET:
            //Cloneamos el estado corriente
            let updatedAssets = { ...state };
            //Recogimos el array
            for (let k in action.payload) {
                const assetCollection = action.payload[k];
                const collectionType = assetCollection.assetType;
                updatedAssets = setAsset(updatedAssets, collectionType, assetCollection.assets);
            }

            return {
                ...updatedAssets
            }
        case GET_ASSET:
        case SET_ASSET:
            return setAsset(state, assetType, assets);
        default:
            return state
    }
}
