import React, { Component } from 'react'
import { selectReservation, getReservations, setReservationState as doSetReservationState } from '../actions/index'
import { useDispatch, useSelector } from 'react-redux'
import { faCheckCircle, faUndo, faHome, faBoxOpen, faTruck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function ReservationStateButton({ reservation, onClick }) {
    //@ts-ignore
    const companyId = useSelector(s => s.currentUser.selectedRoleDisplay.company.id);
    const dispatch = useDispatch();

    const setReservationState = (reservationId, newStateId) => {
        //@ts-ignore
        dispatch(doSetReservationState(reservationId, newStateId));
        if (typeof (onClick) === 'function') {
            onClick(reservationId, newStateId)
        }
    }

    if (reservation.shipper_id === companyId) {
        //Somos el despachador, podemos cambiar de estado
        switch (reservation.state_id) {
            case 1:
                //En espera
                return <button type='button' className='btn btn-success btn-sm button-green-action ' data-tip=" Aprobar Reserva" onClick={() => setReservationState(reservation.id, 2)}><FontAwesomeIcon icon={faCheckCircle} size='sm' color="#ffffff " /></button>
            case 2:
                //Verificada
                return (<div>
                    <div className="row">
                        <div className="container">
                            <button type='button'  className='btn btn-warning btn-sm btn-yw col-10' data-tip="Rectificar Reserva" onClick={() => setReservationState(reservation.id, 1)}><FontAwesomeIcon icon={faUndo} size='sm' color=" #ffffff " /></button>
                            <button type='button'  className='btn btn-primary btn-sm button-blue mx-1 col-10' data-tip="Contenedor en Planta" onClick={() => setReservationState(reservation.id, 3)}><FontAwesomeIcon icon={faBoxOpen} size='sm' color=" #ffffff " /></button>
                        </div>
                    </div>
                </div>)
            case 3:
                //En planta
                return (<div>
                    <div className="row">
                        <div className="container">
                            <button type='button'  className='btn btn-primary btn-sm button-blue  col-10' data-tip="Contenedor en Planta" onClick={() => setReservationState(reservation.id, 2)}><FontAwesomeIcon icon={faBoxOpen} size='sm' color=" #ffffff " /></button>
                            <button type='button'  className='btn btn-success btn-sm btn-sm button-green-action col-10 mx-1 ' data-tip=" Despachar Contenedor" onClick={() => setReservationState(reservation.id, 4)}><FontAwesomeIcon icon={faTruck} size='sm' color="#ffffff " /></button>
                        </div>
                    </div>
                </div>)
            case 4:
                //Despachada
                return <button type='button'  className='btn btn-secondary btn-sm btn-gray' data-tip="Regresar a Planta" onClick={() => setReservationState(reservation.id, 3)}><FontAwesomeIcon icon={faHome} size='sm' color=" #ffffff " /></button>
            default:
                return <h2>No implementado</h2>
        }
    }
    else {
        //Solamente mostrar estado
        switch (reservation.state_id) {
            case 1:
                //En espera
                return <label><i>En espera</i></label>
            case 2:
                //Verificada
                return <label className='lbl lbl-primary'>Verificada</label>
            case 3:
                //En planta
                return <label className='lbl lbl-success'>En planta</label>
            case 4:
                //Despachada
                return <label className='lbl lbl-secondary'>Despachada</label>
            default:
                return <label className='lbl lbl-danger'>No implementado</label>
        }
    }
}

export default ReservationStateButton;
