import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { useLocation, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_ENDPOINT } from "../constants";
import { useSelector } from "react-redux";
import GenericLoader from "../components/generic-loader";

const Paywall = ({
  moduleType,
  moduleName,
  moduleDesc,
  moduleImage,
  allowTrial,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      padding={4}
      sx={{ backgroundColor: "gray" }}
    >
      <Card sx={{ width: "40vw" }}>
        <CardMedia
          sx={{ height: 280, objectFit: "scale-down" }}
          image={moduleImage}
          title={moduleName}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {moduleName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {moduleDesc}
          </Typography>
        </CardContent>
        <CardActions>
          <Grid container spacing={5}>
            {allowTrial && (
              <Grid item xs={6}>
                <Button variant="contained" color={"secondary"}>
                  {t("common.trial")}
                </Button>
              </Grid>
            )}
            <Grid item xs={6}>
              <Button variant="contained" color={"primary"} href={`mailto:venta@daia.cl`}>
                {t("common.buy")}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  );
};
/*
 * Averiguar si el usuaio tiene acceso (pagado)
 */
export default function RequirePayment({ licenseType, children }) {
  //Tenemos un usuario loggeado y un role selecionado, mostramos la pagina
  const [licenceData, setLicenceData] = React.useState();
  const { t } = useTranslation();

  //Recuperamos el UID de la empresa
  const companyUid = useSelector(
    //@ts-ignore
    (c) => c.currentUser.selectedRoleDisplay.company.code
  );

  React.useEffect(() => {
    if (licenseType !== "free") {
      axios
        .get(API_ENDPOINT + "license?c=" + companyUid + "&lt=" + licenseType)
        .then((res) => {
          if (res.data && res.data.valid == 1) {
            setLicenceData(res.data);
          }
        })
        .catch((err) => {
          setLicenceData({ valid: 0 });
        });
    }
  }, [licenseType]);

  if (licenseType !== "free") {
    //Si no está 'free', tenemos que tener licensia
    if (licenceData && licenceData.valid == 1) {
      return children;
    } else if (!licenceData) {
      //Loading
      return <GenericLoader message={'Buscando información de licencia...'} title={''} openState={!licenceData} />;
    } else {
      return (
        <Paywall
          allowTrial={true}
          moduleName={t("module." + licenseType + ".name")}
          moduleDesc={t("module." + licenseType + ".description")}
          moduleImage={t("module." + licenseType + ".image")}
          moduleType={licenseType}
        />
      );
    }
  } else {
    return children;
  }
}
