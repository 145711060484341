import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, useGridApiRef } from '@mui/x-data-grid-pro';
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import {
    SaveSharp,
    ArrowDownwardSharp,
    ClearSharp
} from "@material-ui/icons";


function MUIDataGrid({ rows, columns, lang="eng", isLoaded, exportToExcel, componentsProps}) {
    const apiRef = useGridApiRef();
    const [mustSaveView, setMustSave] = useState(false);

    const { t } = useTranslation();

    const saveView = () => {
        const gridState = apiRef.current.exportState();
        localStorage.setItem("grid_state", JSON.stringify(gridState));
        setMustSave(false);
    }

    const resetView = () => {
        localStorage.setItem("grid_state", JSON.stringify({}));
        apiRef.current.restoreState({});
        setMustSave(false);
    }

    const loadState = () => {
        const previousGridState = JSON.parse(localStorage.getItem("grid_state")) || {};
        apiRef.current.restoreState(previousGridState);
        setMustSave(false);
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <Button variant="text" onClick={() => exportToExcel()}>
                    <ArrowDownwardSharp />
                    {t("grid.export")}
                </Button>
                <Button variant={mustSaveView ? "contained" : "text"} onClick={() => saveView()}>
                    <SaveSharp />
                    {t("grid.saveView")}
                </Button>
                <Button variant={"text"} color={"warning"} onClick={() => resetView()}>
                    <ClearSharp />
                    {t("grid.resetView")}
                </Button>
            </GridToolbarContainer>
        );
    }

    useEffect(() => loadState(), [rows.length]);

    return(<>
    <DataGridPro rows={rows} 
    localeText={ lang != 'eng' ?
        {
            // Root
            noRowsLabel: 'Sin resultados',
            noResultsOverlayLabel: 'Sin resultados',
            errorOverlayDefaultLabel: 'Hubo un error.',

            // Density selector toolbar button text
            toolbarDensity: 'Densidad',
            toolbarDensityLabel: 'Densidad',
            toolbarDensityCompact: 'Compacto',
            toolbarDensityStandard: 'Estandard',
            toolbarDensityComfortable: 'Comfortable',

            // Columns selector toolbar button text
            toolbarColumns: 'Columnas',
            toolbarColumnsLabel: 'Seleciona columnas',

            // Filters toolbar button text
            toolbarFilters: 'Filtros',
            toolbarFiltersLabel: 'Mostrar filtros',
            toolbarFiltersTooltipHide: 'Ocultar filters',
            toolbarFiltersTooltipShow: 'Mostrar filters',
            toolbarFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} filtros activos` : `${count} filtros activos`,

            // Quick filter toolbar field
            toolbarQuickFilterPlaceholder: 'Buscar�',
            toolbarQuickFilterLabel: 'Buscar',
            toolbarQuickFilterDeleteIconLabel: 'Volver',

            // Export selector toolbar button text
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Bajar a CSV',
            toolbarExportPrint: 'Imprimir',
            toolbarExportExcel: 'Bajar a Excel',

            // Columns panel text
            columnsPanelTextFieldLabel: 'Buscar columnas',
            columnsPanelTextFieldPlaceholder: 'Titulo columna',
            columnsPanelDragIconLabel: 'Reorganizar columna',
            columnsPanelShowAllButton: 'Mostrar todo',
            columnsPanelHideAllButton: 'Ocultar todo',

            // Filter panel text
            filterPanelAddFilter: 'Agregar filtro',
            filterPanelDeleteIconLabel: 'Eliminar',
            filterPanelLinkOperator: 'Operador logicor',
            filterPanelOperators: 'Operador', // TODO v6: rename to filterPanelOperator
            filterPanelOperatorAnd: 'Y',
            filterPanelOperatorOr: 'O',
            filterPanelColumns: 'Columnas',
            filterPanelInputLabel: 'Valor',
            filterPanelInputPlaceholder: 'Filtrar valor',

            // Filter operators text
            filterOperatorContains: 'contiene',
            filterOperatorEquals: 'igual a',
            filterOperatorStartsWith: 'empieza con',
            filterOperatorEndsWith: 'termina con',
            filterOperatorIs: 'es',
            filterOperatorNot: 'no es',
            filterOperatorAfter: 'est� despues',
            filterOperatorOnOrAfter: 'is on or after',
            filterOperatorBefore: 'is before',
            filterOperatorOnOrBefore: 'is on or before',
            filterOperatorIsEmpty: 'is empty',
            filterOperatorIsNotEmpty: 'is not empty',
            filterOperatorIsAnyOf: 'is any of',

            // Filter values text
            filterValueAny: 'any',
            filterValueTrue: 'true',
            filterValueFalse: 'false',

            // Column menu text
            columnMenuLabel: 'Menu',
            columnMenuShowColumns: 'Mostrar columna',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Ocultar',
            columnMenuUnsort: 'Sin ordenar',
            columnMenuSortAsc: 'Ordenar ABC',
            columnMenuSortDesc: 'Ordenar ZYX',

            // Column header text
            columnHeaderFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} active filters` : `${count} active filter`,
            columnHeaderFiltersLabel: 'Show filters',
            columnHeaderSortIconLabel: 'Sort',

            // Rows selected footer text
            footerRowSelected: (count) =>
                count !== 1
                    ? `${count.toLocaleString()} rows selected`
                    : `${count.toLocaleString()} row selected`,

            // Total row amount footer text
            footerTotalRows: 'Total Rows:',

            // Total visible row amount footer text
            footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

            // Checkbox selection text
            checkboxSelectionHeaderName: 'Checkbox selection',
            checkboxSelectionSelectAllRows: 'Select all rows',
            checkboxSelectionUnselectAllRows: 'Unselect all rows',
            checkboxSelectionSelectRow: 'Select row',
            checkboxSelectionUnselectRow: 'Unselect row',

            // Boolean cell text
            booleanCellTrueLabel: 'yes',
            booleanCellFalseLabel: 'no',

            // Actions cell more text
            actionsCellMore: 'more',

            // Column pinning text
            pinToLeft: 'Pin to left',
            pinToRight: 'Pin to right',
            unpin: 'Unpin',

            // Tree Data
            treeDataGroupingHeaderName: 'Group',
            treeDataExpand: 'see children',
            treeDataCollapse: 'hide children',

            // Grouping columns
            groupingColumnHeaderName: 'Group',
            groupColumn: (name) => `Group by ${name}`,
            unGroupColumn: (name) => `Stop grouping by ${name}`,

            // Master/detail
            detailPanelToggle: 'Detail panel toggle',
            expandDetailPanel: 'Expand',
            collapseDetailPanel: 'Collapse',

            // Used core components translation keys
            MuiTablePagination: {},

            // Row reordering text
            rowReorderingHeaderName: 'Row reordering',

            // Aggregation
            aggregationMenuItemHeader: 'Aggregation',
            aggregationFunctionLabelSum: 'sum',
            aggregationFunctionLabelAvg: 'avg',
            aggregationFunctionLabelMin: 'min',
            aggregationFunctionLabelMax: 'max',
            aggregationFunctionLabelSize: 'size',
        } : {}
    } 
    
    components={{
        Toolbar: CustomToolbar,
      }}    
    apiRef={apiRef}
    columns={columns}
    initialState={{
        columns: {
          columnVisibilityModel: columns.map((column) => column.field),
        },
      }}
    onColumnOrderChange={() => setMustSave(true)}
    onColumnVisibilityModelChange={() => setMustSave(true)}
    /* Para pasar las propriedades de row (contextmenu) */
    componentsProps={componentsProps}
     />
     </>)
}

export default MUIDataGrid;
