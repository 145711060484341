import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useOutletContext, useNavigate } from "react-router-dom";

function ConfirmCodeForm() {
    const navigate = useNavigate();
    //@ts-ignore
    const [t, credencial, setCredencial, signIn, passwordRecovery, signUp, confirmSignUp, resendConfirmCode, confirmNewPassword] = useOutletContext();

    return(
        <>
            <TextField
    margin="normal"
    required
    fullWidth
    id="email"
    label={t('singin.email')}
    name="email"
    autoComplete="email"
    value={credencial.username}
    autoFocus
    onChange={(e) => {
      setCredencial({
        ...credencial,
        username: e.target.value
      })
    }}
    />
        <TextField
            margin="normal"
            required
            fullWidth
            name="confirmCode"
            label={t('signUp.confirmCode')}
            type="text"
            id="confirmCode"
            value={credencial.confirmCode}
            onChange={(e) => {
            setCredencial({
                ...credencial,
                confirmCode: e.target.value
            })
            }}
            />
                <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => confirmSignUp()}
            >
            {t('signUp.finish')}
            </Button>

            <Button
            type="button"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => resendConfirmCode()}
            >
            {t('signUp.resendCode')}
            </Button>

            <Button
    type="button"
    fullWidth
    variant="outlined"
    sx={{ mt: 3, mb: 2 }}
    onClick={() => navigate(-1)}
    >
    {t('common.goBack')}
    </Button>

        </>)
}

export default ConfirmCodeForm;