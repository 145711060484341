import React, { Component } from 'react'
import { API_ENDPOINT, COMPANY_URL } from '../constants'
import RUTPicker from '../components/rut-picker'
import axios from 'axios'

class CompanyWizard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            company: {
                name: '',
                rut: '',
                display_name: '',
                email: '',
                company_type_id: -1,
            },
            selectedCompanyType: this.props.companyTypeId,
            creationSuccess: false,
            message: ''
        }
    }

    validateProperties(company) {
        if (company.name.length < 4) {
            this.setState({
                ...this.state,
                message: "La razón social de su empresa debe contar con un minimo de 4 caracteres.",
                creationSuccess: false
            })
            return false
        }

        if (company.company_type_id <= 0) {
            this.setState({
                ...this.state,
                message: "Debe seleccionar un tipo de empresa.",
                creationSuccess: false
            })
            return false
        }

        if (company.display_name.length < 4) {
            this.setState({
                ...this.state,
                message: "El nombre de la empresa debe tener un minimo de 4 caracteres.",
                creationSuccess: false
            })
            return false
        }

        /*
        if (company.rut.indexOf("-") < 0) {
            this.setState({
                ...this.state,
                message: "El formato de su RUT es incorrecto, debe contar con puntos y guión.",
                creationSuccess: false
            })
            return false
        }
        */

        if (company.email.indexOf("@") < 0 ||
            company.email.indexOf(".") < 0) {
            this.setState({
                ...this.state,
                message: "El formato de su dirección email es incorrecto.",
                creationSuccess: false
            })
            return false
        }

        return true
    }

    handleCompanyCreateRequest() {
        let self = this

        if (!this.validateProperties(this.state.company)) {
            return
        }
        else {
            axios.post(`${API_ENDPOINT}${COMPANY_URL}`,
                self.state.company)
                .then(function (response) {
                    self.setState({
                        ...self.state,
                        creationSuccess: (response.status === 200)
                    })
                }).catch((err) => {
                    self.setState({
                        ...self.state,
                        creationSuccess: false,
                        message: err.response.data
                    })
                })
        }
    }

    setRut(rut) {
        this.setState({
            ...this.state,
            company: {
                ...this.state.company,
                rut: rut
            }
        })
    }

    onChange(e) {
        switch (e.target.id) {
            case 'companyFullname':
                this.setState({
                    ...this.state,
                    company: {
                        ...this.state.company,
                        name: e.target.value
                    }
                })
                break;
            case 'rut':
                this.setState({
                    ...this.state,
                    company: {
                        ...this.state.company,
                        rut: e.target.value
                    }
                })
                break;
            case 'companyShortName':
                this.setState({
                    ...this.state,
                    company: {
                        ...this.state.company,
                        display_name: e.target.value
                    }
                })
                break;
            case 'email':
                this.setState({
                    ...this.state,
                    company: {
                        ...this.state.company,
                        email: e.target.value
                    }
                })
                break;
            default:
                break;
        }
    }

    handleCompanyTypeChange(e) {
        this.setState({
            company: {
                ...this.state.company,
                company_type_id: e.target.id
            },
            selectedCompanyType: e.target.id
        })
    }

    render() {
        return (
            <form>
                {
                    this.state.creationSuccess
                    && <div className="alert alert-success" role="alert">
                        {"Empresa creada con éxito. Para poder ver la nueva empresa, por favor desconectarse y volver a iniciar sesión."}
                    </div>
                }
                {
                    this.state.message.length > 0 && !this.state.creationSuccess
                    && <div className="alert alert-danger" role="alert">
                        {this.state.message}
                    </div>
                }
                <div className="form-group">
                    <label>Razón social</label>
                    <input type="text" maxLength='100' className="form-control input" id="companyFullname" aria-describedby="companyFullnameHelp" placeholder="Razón social de la empresa" onChange={(event) => this.onChange(event)} />
                    <small id="companyFullnameHelp" className="form-text text-muted">La razón social de la empresa tal como registrada en el SII.</small>
                </div>
                <br />
                <div className="form-group">
                    <label>VAT/RUT</label>
                    <RUTPicker checkDuplicity={true} mustExists={false} onValidate={(rut) => { this.setRut(rut) }} describedby="rutHelp"/>
                </div>
                <br />
                <div className="form-group">
                    <label>Nombre corto</label>
                    <input type="text" maxLength='30' className="form-control" id="companyShortName" aria-describedby="companyShortNameHelp" placeholder="" onChange={(event) => this.onChange(event)} />
                    <small id="companyShortNameHelp" className="form-text text-muted">Un nombre corto o de fantasia para identificar la empresa.</small>
                </div>
                <br />
                <div className="form-group">
                    <label>E-mail</label>
                    <input type="email" maxLength='30' className="form-control" id="email" aria-describedby="emailHelp" placeholder="" onChange={(event) => this.onChange(event)} />
                    <small id="emailHelp" className="form-text text-muted">Una dirección de correo valida para recibir notificación y confirmación de reservación.</small>
                </div>
                <br />

                <div className="form-group">
                    <label>Tipo de empresa</label>
                    <br /><br />
                    <div className='flex-row'>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="btnradio"
                                value={1} autoComplete="off" checked={this.state.selectedCompanyType === '1'}
                                onChange={() => { }} />
                            <label className="btn btn-outline-primary" id={1}
                                onClick={this.handleCompanyTypeChange.bind(this)}>Exportador</label>

                            <input type="radio" className="btn-check" name="btnradio"
                                value={2} autoComplete="off" checked={this.state.selectedCompanyType === '2'}
                                onChange={() => { }} />
                            <label className="btn btn-outline-primary" id={2}
                                onClick={this.handleCompanyTypeChange.bind(this)}>Empacador</label>

                            <input type="radio" className="btn-check" name="btnradio"
                                value={3} autoComplete="off" checked={this.state.selectedCompanyType === '3'}
                                onChange={() => { }} />
                            <label className="btn btn-outline-primary" id={3}
                                onClick={this.handleCompanyTypeChange.bind(this)}>Productor</label>

                            <input type="radio" className="btn-check" name="btnradio"
                                value={4} autoComplete="off" checked={this.state.selectedCompanyType === '4'}
                                onChange={() => { }} />
                            <label className="btn btn-outline-primary" id={4}
                                onClick={this.handleCompanyTypeChange.bind(this)}>Recibidor</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <button type='button' className='btn btn-success' onClick={() => { this.handleCompanyCreateRequest() }} disabled={this.state.creationSuccess && 'true' }> Crear </button>
                </div>
            </form>
        );
    }
}

export default CompanyWizard;
