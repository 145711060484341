import React, { useEffect, useState } from 'react'
import { TextField, InputLabel, Button, MenuItem, FormControl } from "@mui/material";
import { API_ENDPOINT } from '../constants'
import { exporterSetAsset, exporterGetAsset, exporterDeleteAsset } from '../actions';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios'
import '../app.css'


function Customer({ companyId, companyName, companyType }) {
    const [newAsset, setNewAsset] = useState({});
    const dispatch = useDispatch();

    const assets = useSelector(state => state.assets.consignees) || [];
    useEffect(() => {
      dispatch(exporterGetAsset('consignee', companyId));
    }, [companyId]);

    const saveAsset = () => {
      dispatch(exporterSetAsset('consignee', newAsset, companyId));
    }

    const deleteAsset = (id) => {
      dispatch(exporterDeleteAsset('consignee', id, companyId));
    }

return (
            <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField fullWidth id="filled-basic"
                    label="Código" variant="filled"
                    style={{ margin: '4px' }}
                    value={newAsset.code || ''}
                    onInput={(e) => {
                        const newValue = e.target.value;
                        setNewAsset({
                            ...newAsset,
                            code: newValue
                        })
                    }}
                />
                <TextField fullWidth id="filled-basic"
                    label="Razón social" variant="filled"
                    style={{ margin: '4px' }}
                    value={newAsset.name || ''}
                    onInput={(e) => {
                        const newValue = e.target.value;
                        setNewAsset({
                            ...newAsset,
                            name: newValue
                        })
                    }}
                />
                <TextField fullWidth id="filled-basic"
                    label="RUT/USCI/VAT/TAX ID" variant="filled"
                    style={{ margin: '4px' }}
                    value={newAsset.rut || ''}
                    onInput={(e) => {
                        const newValue = e.target.value;
                        setNewAsset({
                            ...newAsset,
                            rut: newValue
                        })
                    }}
                />
            </div>
            <div>
            <TextField fullWidth id="filled-basic"
                label="Dirección" variant="filled"
                style={{ margin: '4px' }}
                value={newAsset.address || ''}
                onInput={(e) => {
                    const newValue = e.target.value;
                    setNewAsset({
                        ...newAsset,
                        address: newValue
                    })
                }}
            />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField fullWidth id="filled-basic"
                label="Pais" variant="filled"
                style={{ margin: '4px' }}
                value={newAsset.country || ''}
                onInput={(e) => {
                    const newValue = e.target.value;
                    setNewAsset({
                        ...newAsset,
                        country: newValue
                    })
                }}
            />

            <TextField fullWidth id="filled-basic"
                label="Contacto" variant="filled"
                style={{ margin: '4px' }}
                value={newAsset.email || ''}
                onInput={(e) => {
                    const newValue = e.target.value;
                    setNewAsset({
                        ...newAsset,
                        email: newValue
                    })
                }}
            />
            <Button variant="contained" color="success" onClick={() => { saveAsset(); }}>Crear</Button>
            </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Razón social</th>
                            <th>RUT/USCI/VAT/TAX ID</th>
                            <th>País</th>
                            <th>Contacto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assets.map((a, i) => {
                                return <tr key={i}>
                                    <td>{a.code}</td>
                                    <td>{a.name}</td>
                                    <td>{a.rut}</td>
                                    <td>{a.country}</td>
                                    <td>{a.email}</td>
                                    <td><Button variant="contained" color="warning" onClick={() => { deleteAsset(a.id); }}>X</Button></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </>
        )
}

export default Customer;
