import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthRouter from "./routers/router_auth";
import ExporterRouter from "./routers/router_exporter";
import BrokerRouter from "./routers/router_broker";
import AdminRouter from "./routers/router_admin";
import CostRouter from "./routers/router_cost";
import GrowerRouter from "./routers/router_grower";
import PackingRouter from "./routers/router_packing";
import RequireAuth from "./routers/router_require_auth";

import App from "./containers/app";

import RoleMain from "./containers/role-main";
import ProfileForm from "./containers/profile-form";

import LogoutForm from "./containers/logout-form";
import CompanyWizard from "./containers/company-wizard";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { LicenseInfo } from "@mui/x-license-pro";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

LicenseInfo.setLicenseKey(
  //2023: "7ee484327f88621d86c114886dbd93a3Tz01NjA2MCxFPTE3MDI1NTgwMTcxNTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  "275ee87c09c94f997955e45e156aa754Tz04MzQ3MyxFPTE3Mzg1MDU3NzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

//Traducción
const ressourceES = require("./i18n/es/translation.json");
const ressourceEN = require("./i18n/en/translation.json");
const ressourceFR = require("./i18n/fr/translation.json");
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: ressourceEN,
      },
      fr: {
        translation: ressourceFR,
      },
      es: {
        translation: ressourceES,
      },
      "es-CL": {
        translation: ressourceES,
      },
      "es-419": {
        translation: ressourceES,
      },
    },
    order: ["navigator"],
    caches: ["localStorage"],
    whitelist: ["es", "en", "fr", "es-CL", "es-419"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

i18n.changeLanguage();

const theme = createTheme({
  palette: {
    success: {
      main: "#26745B",
    },
    action: {
      main: "#003662"
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#C0801A",
    },
    primary: {
      main: "#003865",
    },
    secondary: {
      main: "#6388B2",
    },
  },
});

//Thunk permite tener acceso al dispatch para enviar varias acciones por actions
const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

serviceWorkerRegistration.register();

ReactDOM.render(
  <Provider
    store={createStoreWithMiddleware(
      reducers,
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )}
  >
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <RequireAuth>
                <App />
              </RequireAuth>
            }
          >
            {PackingRouter}
            {ExporterRouter}
            {BrokerRouter}
            {GrowerRouter}
            {AdminRouter}
            {CostRouter}

            <Route
              path="profile"
              element={
                <RequireAuth>
                  <ProfileForm />
                </RequireAuth>
              }
            />

            <Route path="logout" element={<LogoutForm />} />

            <Route
              path="role"
              element={
                <RequireAuth>
                  <RoleMain />
                </RequireAuth>
              }
            />

            <Route path="company">
              <Route
                path="create"
                element={
                  <RequireAuth>
                    <CompanyWizard />
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <RequireAuth>
                  <div>No se encontró la página.</div>
                </RequireAuth>
              }
            />
          </Route>
          {AuthRouter}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
