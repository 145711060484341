import React, { Component } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Box,
  ListItemText,
  ListItemIcon,
  ListItem,
  Drawer,
} from "@mui/material";
import {
  HomeOutlined,
  InboxOutlined,
  BookTwoTone,
  LocalShippingOutlined,
  AccountCircle,
  CloseOutlined,
  Settings,
  NatureOutlined,
  DirectionsBoatOutlined,
  StarBorderOutlined,
  MapOutlined,
  ShareOutlined,
  MoneyOutlined,
  MonetizationOnSharp,
} from "@material-ui/icons";
import "../exporter.css";

import { useTheme } from "@mui/material/styles";

function MainNav({ setTitle, width }) {
  const user = useSelector((state) => state.currentUser);
  const [currentButton, setCurrent] = React.useState("");
  const { t } = useTranslation();
  const theme = useTheme();

  const menu = [
    {
      name: t("menu.dashboard"),
      icon: (c) => <HomeOutlined color={c} />,
      link: "/modules/packing/dashboard",
      role: ["ADM", "SPE", "CLI"],
      companyType: ["EX", "PK"],
    },
    {
      name: t("menu.reservations"),
      icon: (c) => <InboxOutlined color={c} />,
      link: "/modules/packing/reservation",
      role: ["ADM", "SPE", "CLI"],
      companyType: ["EX", "PK"],
    },
    {
      name: t("menu.documents"),
      icon: (c) => <BookTwoTone color={c} />,
      link: "/modules/packing/documents",
      role: ["ADM", "SPE", "CLI"],
      companyType: ["EX", "PK"],
    },
    {
      name: "Portal productor",
      icon: (c) => <ShareOutlined color={c} />,
      link: "/modules/grower/portal",
      role: ["ADM", "SPE", "CLI"],
      companyType: ["EX", "PK"],
    },
    {
      name: t("menu.exporter"),
      icon: (c) => <LocalShippingOutlined color={c} />,
      link: "/modules/exporter/boarding",
      role: ["ADM", "SPE", "SUP"],
      companyType: ["EX", "PK"],
    },
    {
      name: t("Liquidaciones"),
      icon: (c) => <MonetizationOnSharp color={c} />,
      link: "/modules/cost/portal",
      role: ["ADM", "SPE", "SUP"],
      companyType: ["EX", "PK"],
    },
    {
      name: t("menu.boardings"),
      icon: (c) => <DirectionsBoatOutlined color={c} />,
      link: "/modules/broker/boarding",
      role: ["SPE"],
      companyType: ["RC"],
    },
    {
      name: t("menu.expoter-menu.tracking"),
      icon: (c) => <MapOutlined color={c} />,
      link: "/modules/broker/tracking",
      role: ["SPE"],
      companyType: ["RC"],
    },

    /* Producteur */
    {
      name: "Tablero",
      icon: (c) => <MapOutlined color={c} />,
      link: "/modules/grower/dashboard",
      role: ["ADM", "SPE", "CLI"],
      companyType: ["PR"],
    },
    {
      name: "Calidad",
      icon: (c) => <NatureOutlined color={c} />,
      link: "/modules/grower/quality",
      role: ["ADM", "SPE", "CLI"],
      companyType: ["PR"],
    },
    {
      name: "Procesos",
      icon: (c) => <Settings color={c} />,
      link: "/modules/grower/process",
      role: ["ADM", "SPE", "CLI"],
      companyType: ["PR"],
    },
    {
      name: "Documentos",
      icon: (c) => <InboxOutlined color={c} />,
      link: "/modules/grower/documents",
      role: ["ADM", "SPE", "CLI"],
      companyType: ["PR"],
    },

    {
      name: "Super Admin",
      icon: (c) => <StarBorderOutlined color={c} />,
      link: "/modules/admin",
      role: ["SUP"],
      companyType: ["EX", "PK"],
    },
  ];

  const role =
    (user.selectedRoleDisplay.access && user.selectedRoleDisplay.access.code) ||
    "";
  const companyType =
    (user.selectedRoleDisplay.company &&
      user.selectedRoleDisplay.company.company_type.code) ||
    "";
  const companyName =
    (user.selectedRoleDisplay.company &&
      user.selectedRoleDisplay.company.display_name) ||
    "";

  const getDrawerItems = (withTitle) => (
    <Box sx={{ marginTop: "12vh" }}>
      <ListItem>
        <ListItemText
          primary={companyName}
          sx={{ display: { xs: "none", sm: "block" }, maxWidth:  '204px'}}
        />
      </ListItem>

      {menu.map((item, index) => {
        if (
          item.role.indexOf(role) >= 0 &&
          item.companyType.indexOf(companyType) >= 0
        ) {
          return (
            <Box
              sx={{
                backgroundColor:
                  currentButton === item.link
                    ? theme.palette.action.main
                    : "none",
              }}
              key={index}
            >
              <Link
                to={item.link}
                key={index}
                style={{
                  textDecoration: "none",
                  color: currentButton === item.link ? "white" : "black",
                }}
              >
                <ListItem
                  onClick={() => {
                    setTitle(item.name);
                    setCurrent(item.link);
                  }}
                >
                  <ListItemIcon>
                    {item.icon(
                      currentButton === item.link ? "primary" : "action"
                    )}
                  </ListItemIcon>
                  {withTitle && <ListItemText primary={item.name} />}
                </ListItem>
              </Link>
            </Box>
          );
        }
      })}
    </Box>
  );

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        sx={{
          minWidth: "50px",
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "75px" },
        }}
      >
        {getDrawerItems(false)}
      </Drawer>
      <Drawer
        variant={"permanent"}
        anchor={"left"}
        sx={{
          width: width + "px",
          display: { xs: "none", sm: "block" },
        }}
      >
        {getDrawerItems(true)}
      </Drawer>
    </React.Fragment>
  );
}

export default MainNav;
