import React from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Skeleton,
  Grid,
  Paper,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  exporterGetAsset,
  setAppBarOptions,
  getGrowerDocument,
} from "../actions";
import GrowerProcess from "../components/grower-process";
import GrowerDocumentUpload from "../components/grower-document-upload";

interface IGrowerPortalProps {
  mode: string;
}

export default function GrowerPortal(props: IGrowerPortalProps) {
  const appBarOptions = [
    props.mode && props.mode === "exporter" ? (
      <Button
        key="5"
        variant="contained"
        color="primary"
        LinkComponent={Link}
        sx={{ ":hover": { color: "white" } }}
        href="/modules/exporter/asset/grower"
      >
        Productores
      </Button>
    ) : (
      <> </>
    ),
    props.mode && props.mode === "exporter" ? (
      <Button
        key="3"
        variant="contained"
        color="primary"
        LinkComponent={Link}
        sx={{ ":hover": { color: "white" } }}
        href="/modules/exporter/asset/packing"
      >
        Packings
      </Button>
    ) : (
      <> </>
    ),
    props.mode && props.mode === "exporter" ? (
      <Button
        key="4"
        variant="contained"
        color="primary"
        LinkComponent={Link}
        sx={{ ":hover": { color: "white" } }}
        href="/modules/exporter/asset/variety"
      >
        Variedades
      </Button>
    ) : (
      <> </>
    ),
    <Button key="1" variant="contained" color="primary">
      Procesos
    </Button>,
    <Button key="2" variant="contained" color="primary">
      Mensajes
    </Button>,
  ];

  const dispatch = useDispatch();

  //Selecionamos la empresa utilizada
  const company = useSelector(
    //@ts-ignore
    (state) => state.currentUser.selectedRoleDisplay.company
  );
  const companyId = company.id;

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [openUploadModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    //Cargamos los productores
    if (props.mode && props.mode === "exporter") {
      //@ts-ignore
      dispatch(exporterGetAsset("grower", companyId));
      //@ts-ignore
      dispatch(exporterGetAsset("packing", companyId));
      //@ts-ignore
      dispatch(exporterGetAsset("exporter", companyId));
      //@ts-ignore
      dispatch(exporterGetAsset("specie", companyId));
      //@ts-ignore
      dispatch(exporterGetAsset("variety", companyId));
    }
    //Agregamos los botones a la barra de estado
    //@ts-ignore
    dispatch(setAppBarOptions(appBarOptions));
    //@ts-ignore
    dispatch(getGrowerDocument("growerProcess", companyId));
    setIsLoaded(true);
  }, [companyId]);

  //@ts-ignore
  const growers = useSelector((state) => state.assets.growers) || [];
  //Cantidad de productores vinculados
  const linkedGrowersCount = growers.filter((g) => g.company_ref).length;
  //Los exportadores son las empresas que tienen acceso a nuestra empresa
  const linkedExportersCount =
    (company && company.br_company_access.length) || 0;
  //Cargamos los documentos para mostrar la cantidad
  //@ts-ignore
  const documents = useSelector((state) => state.growerDocuments.list) || [];

  return (
    <Box>
      {isLoaded ? (
        <Grid container spacing={2}>
          {props.mode && props.mode === "exporter" ? (
            <Grid item xs={4} md={4}>
              <Paper elevation={3} sx={{ padding: "8px" }}>
                <Typography variant="h5" component="h1" gutterBottom>
                  Productores enlazados
                </Typography>
                <Typography variant="h3" component="h1" gutterBottom>
                  {linkedGrowersCount}
                </Typography>
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={4} md={4}>
              <Paper elevation={3} sx={{ padding: "8px" }}>
                <Typography variant="h5" component="h1" gutterBottom>
                  Exportadores
                </Typography>
                <Typography variant="h3" component="h1" gutterBottom>
                  {linkedExportersCount}
                </Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs={4} md={4}>
            <Paper elevation={3} sx={{ padding: "8px" }}>
              <Typography variant="h5" component="h1" gutterBottom>
                Documentos
              </Typography>
              <Typography variant="h3" component="h1" gutterBottom>
                {documents.length}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4} md={4}>
            <Paper elevation={3} sx={{ padding: "8px" }}>
              <Typography variant="h5" component="h1" gutterBottom>
                Nuevos mensajes
              </Typography>
              <Typography variant="h3" component="h1" gutterBottom>
                {2}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <GrowerProcess mode={props.mode} documents={documents} />
          </Grid>
          <Grid item xs={4} md={4}>
            {props.mode && props.mode === "exporter" ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ ":hover": { color: "white" } }}
                onClick={() => setOpenModal(true)}
              >
                Subir nuevo proceso
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rounded" width="100%" height="100px" />
          </Grid>
          <Grid item xs={8} md={8}>
            <Skeleton variant="rounded" width="100%" height="250px" />
          </Grid>
          <Grid item xs={4} md={4}>
            <Skeleton variant="rounded" width="100%" height="250px" />
          </Grid>
          <Grid item xs={4} md={4}>
            <Skeleton variant="rounded" width="150px" height="100px" />
          </Grid>
        </Grid>
      )}

      <GrowerDocumentUpload
        isOpen={openUploadModal}
        handleClose={() => setOpenModal(false)}
        documentType="growerProcess"
      />
    </Box>
  );
}
