import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservation,
  deleteReservation as doDeleteReservation,
  deleteReservationDocument,
} from "../actions/index";
import Document from "../components/document";
import GenericDialog from "../components/generic-dialog";
import ReservationStateButton from "./reservation-state-button";
import Reservation from "../models/reservation";
import moment from "moment"
import ReservationContainerDialog from "../components/reservation-container-dialog";
import axios from "axios";
import { API_ENDPOINT } from "../constants";



function ReservationView({ reservationId }) {
  const [reservation, setReservation] = useState<Reservation>({ id: -1, code: '', state_id: -1, documents: [], items: [], booking: "", dus: "", shipping_date: "", sps: "", stacking_date: "", updated_date: "" })
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [selectedForDelete, setSelectedForDelete] = useState(-1);
  const [deleteReservation, setDeleteReservation] = useState(false);
  const [showContainerDialog, setShowContainerDialog] = useState(false);
  const [refreshReservation, setRefreshReservation] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    //@ts-ignore
    dispatch(getReservation(reservationId, (response) => {
      if (response.status === 200) {
        //Dato disponible, mostrar
        setReservation(response.data);
        setShowModal(true);
        setRefreshReservation(false);
      } else if (response.status === 401) {
        //No tiene derecho
        setError("El usuario actual no tiene derecho a ver esa reservación.");
      } else {
        setError("Occurió un error al visualizar la reservación.");
      }
    }))

  }, [reservationId, refreshReservation])

  const tryDeleteReservation = () => {
    //Eliminar la reserva si está en el estado correspondiente
    if (reservation.state_id === 1) {
      setDeleteReservation(true);
    } else {
      console.log("El estado no permite eliminar.", reservation.state_id);
    }
  };

  if (reservation && reservation.id > 0) {
    return (
      <div>
        <GenericDialog
          message={"Seguro desea eliminar el documento selecionado?"}
          title="Eliminar documento"
          buttons={{ cancel: "Cancelar", confirm: "Confirmar" }}
          openState={selectedForDelete > 0}
          assetId={selectedForDelete}
          onClose={(status) => {
            if (status === 1) {
              //@ts-ignore
              dispatch(deleteReservationDocument(selectedForDelete))
            }
            setSelectedForDelete(-1);
          }}
        />
        <GenericDialog
          message={"Seguro desea eliminar esta reservación?"}
          title="Eliminar reservación"
          buttons={{ cancel: "Cancelar", confirm: "Confirmar" }}
          openState={deleteReservation}
          assetId={reservation.id}
          onClose={(status) => {
            if (status === 1) {
              //@ts-ignore
              dispatch(doDeleteReservation(reservation.id))
            }
            setDeleteReservation(false);
          }}
        />

        <ReservationContainerDialog assetId={reservation.id} onClose={(status, container) => {
          //Actualizamos el contenedor
          if (container.length > 0) {
            axios
              .put(`${API_ENDPOINT}${"reservation/" + reservation.id + "/container/" + container}`)
              .then(function (response) {
                setShowContainerDialog(false);
              });
          }
        }} openState={showContainerDialog} />
        <form>
          <div className="my-2 py-2 bg-white det-view-min">
            <div className="container  my-3 text-center head-print">
              <div className="flex-row-single-element">
                <div className="col-4  ">
                  <p className="fs-6 my-1">
                    {" "}
                    Reservación{" "}
                    <b>
                      {" "}
                      #{reservation.id}-{reservation.code}
                    </b>{" "}
                  </p>
                </div>
                <div className="col-4 "></div>
                <div className="col-4 ">
                  <div className="row">
                    <div className="col-3 my-1">
                      <b>Estado</b>
                    </div>
                    <div className="col-6 my-1">
                      <ReservationStateButton reservation={reservation} onClick={(reservationId, newStateId) => {
                        //Revisamos si se paso´"En planta"
                        if (newStateId === 3) {
                          setShowContainerDialog(true);
                        }
                        setRefreshReservation(true);
                      }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row-single-element">
              <h1 className="display-6 mb-3 my-3">
                {reservation.owner?.name}
              </h1>
            </div>

            <div className="container ">
              <div className="text-center border-top ">
                <div className="row">
                  <div className="col-4  py-1">
                    <b>Emisión</b>
                  </div>
                  <div className="col-4  py-1">
                    <b>Carga</b>
                  </div>
                  <div className="col-4  py-1">
                    <b>Stacking</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4  py-1">
                    {moment.utc(reservation.updated_date).local().format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div className="col-4  py-1">
                    {moment(reservation.shipping_date).format('YYYY-MM-DD HH:mm')}{" "}
                  </div>
                  <div className="col-4  py-1">
                    {moment(reservation.stacking_date).format('YYYY-MM-DD HH:mm')}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4  py-1">
                    {" "}
                    <b>DUS</b>
                  </div>
                  <div className="col-4  py-1">
                    {" "}
                    <b>SPS</b>
                  </div>
                  <div className="col-4  py-1">
                    {" "}
                    <b>BOOKING</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4  py-1">{reservation.dus}</div>
                  <div className="col-4  py-1">{reservation.sps}</div>
                  <div className="col-4  py-1">{reservation.booking}</div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="text-center my-2 ">
                <div className="row">
                  <div className="col-6 border-bottom border-top border-end table-print">
                    <h5 className="mb-3 my-4 count">Detalle de carga </h5>
                  </div>
                  <div className="col-6 border-bottom  border-top table-print">
                    <h5 className="mb-3 my-4 btn-hide ">
                      Documentos adjuntos{" "}
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 border-end ">
                    <table className="table table-striped table-borderless table-sm my-1 table-hover table-light"
                      style={{ height: '150px', overflow: "auto" }}
                    >
                      <thead>
                        <tr>
                          <th>Folio pallet</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reservation.items.map((p, i) => {
                          return (
                            <tr key={i}>
                              <td>{p.item_code}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-6  my-1 btn-hide table-print">
                    {reservation.documents.map((d) => {
                      return (
                        <Document
                          document={d}
                          onDelete={() =>
                            this.setState({
                              ...this.state,
                              selectedForDelete: d.id,
                            })
                          }
                        />
                      );
                    })}
                    {reservation.documents.length === 0 && (
                      <i>Sin documentos</i>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row text-center border-top btn-hide">
                <div className="col-2 my-3">
                  <button
                    type="button"
                    className="btn btn-danger btn-sm btn-red"
                    onClick={() => {
                      tryDeleteReservation();
                    }}
                  >
                    Eliminar
                  </button>
                </div>
                <div className="col-2 my-3">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      document.location =
                        "/modules/packing/reservation/" + reservation.id + "/edit";
                    }}
                    disabled={reservation.state_id > 1}
                  >
                    Editar
                  </button>
                </div>
                <div className="col-3"></div>
                <div className="col-3"></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    return <div>{error}</div>;
  }
}

export default ReservationView;
