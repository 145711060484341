export const API_ENDPOINT = 'https://api.daia.cl/';
//export const API_ENDPOINT = 'http://127.0.0.1:8080/';
export const RESERVATION_URL = 'reservation';
export const RESERVATION_ITEM_URL = 'reservation/item';
export const RESERVATION_STATE_SHIPPED = 4
export const LOGIN_URL = 'login';
export const LOGOUT_URL = 'logout';
export const COMPANY_URL = 'company'
export const COMPANY_ASSOCIATE_URL = 'company/requestLink'
export const REGISTER_TYPE_URL = 'reservation/document/type'
export const APP_VERSION = '2.24.11.28'
export const BOARDING_STATES = [
  { id: 1, desc: 'Borrador' },
  { id: 2, desc: 'Validado' },
  { id: 3, desc: 'Despachado (público)' },
  { id: 4, desc: 'Documentado (público)'}
]
//Documents state
export const DOC_PENDING = 'D_P'
export const DOC_SENT = 'D_S'
export const COMPANY_TYPE_PACKING = 2
export const normalizeRut = (x) => {
    let strTrimmed = x.replace(" ", "").replace(/\./gi, "").replace("-", "");
    return strTrimmed.substr(0, 2) + "." + strTrimmed.substr(2, 3) + "." + strTrimmed.substr(5, 3) + "-" + strTrimmed.substr(8, 1);
}
export const normalizeCase = (x) => {
    return x.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
