import React, { Component, useState } from "react";
import { API_ENDPOINT } from "../constants";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";
import "../exporter.css";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import GenericDialog from "./generic-dialog";
import { useTranslation } from "react-i18next";

export function ShippingPrice() {
  //Recuperamos el id desde la URL (según el match de la ruta)
  const { shippingId } = useParams();
  const [shipping, setShipping] = React.useState({ details: [] });
  const [prices, setPrices] = React.useState({
    shippingId: shippingId,
    total: 0,
    matrix: {},
    state: 0,
    customCosts: [],
    transport: 0,
    paymentType: "FOB",
  });
  const [selectedCost, setCost] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);

  const companyId = useSelector(
    //@ts-ignore
    (c) => c.currentUser.selectedRoleDisplay.company.id
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const shippingStates = [
    { id: 0, desc: t("shipping.states.0") },
    { id: 1, desc: t("shipping.states.1") },
    { id: 2, desc: t("shipping.states.2") },
  ];

  React.useEffect(() => {
    //Cargamos el shipping
    axios
      .get(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/shipping?id="}${shippingId}${"&detail=true"}`
      )
      .then(function (responseShip) {
        const shipping = responseShip.data;
        setShipping(shipping);

        //Cargamos los costos personalizados
        axios
          .get(
            `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/cost"}`
          )
          .then(function (responseCost) {
            //Revisamos los costos seteados
            const newMatrix = detailsToKeyMatrix(shipping.prices ?? {});
            setPrices({
              ...prices,
              customCosts: updateShippingCustomPrices(shipping.customPrices, responseCost.data),
              shippingId: shipping.id,
              matrix: newMatrix,
              transport: shipping.transport_value ?? 0,
              paymentType: shipping.payment_type ?? "FOB",
              total: updateTotals(newMatrix),
              state: shipping.state
            });
          });
      });
  }, []);



  const updateShippingCustomPrices = (alreadySetCosts, customCosts) => {
    let updatedCosts = [];
    for (let k in customCosts) {
      let cost = customCosts[k];

      const target = alreadySetCosts.filter((x) => x.cost_id === cost.id)[0];
      if (target) {
        cost.value = target.cost_value;
        cost.required = (target.cost_value > 0 && 1) || cost.required;
      } else {
        cost.value = 0;
      }
      updatedCosts.push(cost);
    }
    return updatedCosts;
  };

  /**
   * Armamos la matriz de precios seg�n lo que nos llega de la API
   * @param {any} prices
   */
  const detailsToKeyMatrix = (prices) => {
    let result = {};
    for (var k in prices) {
      let p = prices[k];
      let key =
        p.shipping_id +
        "-" +
        p.specie_id +
        "-" +
        p.size_id +
        "-" +
        p.color_id +
        "-" +
        p.packaging_id +
        "-" +
        p.variety_id;
      result[key] = p.price;
    }

    return result;
  };

  /*
   * Mostrar costos personalizados
   */
  const displayCost = (costId) => {
    let targetCost = prices.customCosts.filter((c) => c.id == costId)[0];
    let clonedCosts = prices.customCosts.filter((c) => c.id != costId);
    targetCost.required = 1;
    clonedCosts.push(targetCost);
    setPrices({ ...prices, customCosts: clonedCosts });
  };

  const updateMatrixPrice = (key, price) => {
    const matrix = prices.matrix;
    matrix[key] = price;

    setPrices({
      ...prices,
      matrix: matrix,
      total: updateTotals(matrix)
    });
  };

  const updateTotals = (priceList) => {
    const sumValues = (obj) =>
      Object.values(obj).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    let newTotal = sumValues(priceList);

    return newTotal;
  };

  const updateProperty = (prop, value) => {
    switch (prop) {
      case "transport":
        setPrices({
          ...prices,
          transport: parseFloat(value),
        });
        break;
      case "paymentType":
        setPrices({
          ...prices,
          paymentType: value,
        });
        break;
      default:
        break;
    }
  };

  var helper = [];
  const shippingDetailUniqueEntries = shipping.details.reduce(function (r, o) {
    var key = "";
    
    if (!o.size || !o.color || !o.packaging || !o.variety)
    {
      return r;
    }

    key =
      o.item_code +
      "-" +
      o.size && o.size.code || "" +
      "-" +
      o.color && o.color.code || "" +
      "-" +
      o.packaging && o.packaging.code || "" +
      "-" +
      o.variety && o.variety.id;

    if (!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    } else {
      helper[key].item_qnt += o.item_qnt;
    }

    return r;
  }, []);

  const totalWeight = shippingDetailUniqueEntries.reduce((a, b) => {
    return parseInt(a) + parseFloat(b.item_qnt * b.packaging.net_weight);
  }, 0);

  /*
   * Calculamos el equivalente FOB del total (en FOB no se considera el flete)
   */
  const equFobTot = () => {
    const fobTotWithoutCustomCosts =
      prices.total - (prices.paymentType === "FOB" ? 0 : prices.transport);
    let customCostsTotValue = 0;
    for (let c in prices.customCosts) {
      const cost = prices.customCosts[c];
      customCostsTotValue =
        customCostsTotValue +
        (cost.apply == 2 ? cost.value || 0 : -1 * cost.value || 0);
    }
    //Agregamos los costos persoanlizados
    const valorToDisplay = fobTotWithoutCustomCosts + customCostsTotValue;

    return valorToDisplay
  };

  const setCostValue = (cost, value) => {
    let clonedCosts = prices.customCosts.filter((c) => c.id != cost.id);
    cost.value = parseFloat(value);
    clonedCosts.push(cost);
    setPrices({ ...prices, customCosts: clonedCosts });
  };

  /*
   * Acciones
   */

  const onSave = (prices) => {
    axios
      .post(
        `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/priceMatrix"}`,
        prices
      )
      .then(function () {
        navigate(-1);
      });
  };

  const onDelete = () => {
    setShowDelete(true);
  };

  const onClose = () => {
    navigate("/modules/cost/prices");
  };

  return (
    <Box className={"container"}>
      <GenericDialog
        message={"Seguro desea eliminar este despacho?"}
        title={"Eliminar despacho"}
        buttons={{ cancel: "Cancelar", confirm: "Eliminar" }}
        onClose={(result) => {
          if (result === 1) {
            axios
              .delete(
                `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/shipping/"}${shipping.id}`
              )
              .then(function () {
                navigate(-1);
              });
          }
          setShowDelete(false);
        }}
        openState={showDelete}
        assetId={shipping.id}
      />

      <Box sx={{ padding: 4, border: 1, borderRadius: 0.75 }} >
        <Grid container sx={{ textAlign: "center" }} spacing={4}>
          <Grid item xs={4}>
            <Typography variant={"h5"} component={"h5"}>
              <b>Shipping</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"h5"} component={"h5"}>
              {shipping?.code}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"h5"} component={"h5"}>
              {shipping?.customer?.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"h5"} component={"h5"}>
              <b>Estado</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              id="state-select"
              value={prices?.state || 0}
              style={{ width: "50%" }}
              onChange={(e) => {
                setPrices({ ...prices, state: e.target.value });
              }}
            >
              {shippingStates.map((e) => {
                return (
                  <MenuItem key={e.id} value={e.id}>
                    {e.desc}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <table className="table">
        <thead>
          <tr>
            <th>Variedad</th>
            <th>Calibre</th>
            <th>Color</th>
            <th>Categoria</th>
            <th>Embalaje</th>
            <th>Bultos</th>
            <th>Peso Neto</th>
            <th>Monto (bruto)</th>
            <th>USD/kg</th>
          </tr>
        </thead>
        <tbody>
          {shippingDetailUniqueEntries.map((x) => {

            //Revisemos que todos los campos esten definidos
            if (!x.specie || !x.size || !x.color || !x.packaging || !x.variety) {
              return;
            }

            let matrixKey =
              shipping.id +
              "-" +
              x.specie && x.specie.id || "" +
              "-" +
              x.size && x.size.id || "" +
              "-" +
              x.color && x.color.id || "" +
              "-" +
              x.packaging && x.packaging.id || "" +
              "-" +
              x.variety && x.variety.id || "";
            let currentValue = 0;
            if (prices.matrix.hasOwnProperty(matrixKey)) {
              currentValue = prices.matrix[matrixKey];
            }

            return (
              <tr key={x.id} className={"table-primary"}>
                <td>{x.variety.name}</td>
                <td>{x.size.name}</td>
                <td>{x.color.name}</td>
                <td>{x.category}</td>
                <td>{x.packaging.name}</td>
                <td>{x.item_qnt}</td>
                <td>
                  {(x.item_qnt * x.packaging.net_weight).toLocaleString(
                    "es-CL"
                  )}
                </td>
                <td>
                  <input
                    type="number"
                    className="price-cell"
                    value={currentValue}
                    onChange={(e) => {
                      updateMatrixPrice(matrixKey, e.target.value);
                    }}
                  />{" "}
                  $USD
                </td>
                <td>
                  {(
                    currentValue /
                    (x.item_qnt * x.packaging.net_weight)
                  ).toLocaleString("es-CL")}
                </td>
              </tr>
            );
          }, [])}
          <tr key="tot">
            <td>
              <b>Total</b>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{totalWeight.toLocaleString("es-CL")} kg</td>
            <td>
              {prices.total.toLocaleString("es-CL")} $USD <br />
              <i>
                ({(prices.total / totalWeight).toLocaleString("es-CL")} USD/kg)
              </i>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <Box sx={{ border: 0.5, borderRadius: 0.75, borderColor: "gray" }}>
        <table className="table">
          <thead></thead>
          <tbody>
            <tr>
              <td>Valor flete</td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  style={{ maxWidth: 150 }}
                  value={prices.transport}
                  onInput={(e) => {
                    updateProperty("transport", e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Tipo venta</td>
              <td>
                <select
                  className="form-control"
                  style={{ maxWidth: 150 }}
                  value={prices.paymentType}
                  onChange={(e) => {
                    updateProperty("paymentType", e.target.value);
                  }}
                >
                  <option value="FOB">FOB </option>
                  <option value="CIF">CIF </option>
                  <option value="OTRO">Otro </option>
                </select>
              </td>
            </tr>
            {prices?.customCosts
              .sort((a, b) => a.id - b.id)
              .map((c) => {
                if (c.required === 1 || c.value > 0) {
                  return (
                    <tr key={c.id}>
                      <td>
                        {c.description} {c.apply === 2 ? " (+)" : " (-)"}
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          style={{ maxWidth: 150 }}
                          value={c.value}
                          onInput={(e) => setCostValue(c, e.target.value)}
                        />
                      </td>
                    </tr>
                  );
                }
              })}
            {/* Para agregar otros costos */}
            <tr>
              <td>
                <select
                  className="form-control"
                  onChange={(e) => setCost(e.target.value)}
                >
                  <option key={-1}>--</option>
                  {prices.customCosts.map((c) => {
                    if (c.required === 0) {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.description}
                        </option>
                      );
                    }
                  })}
                </select>
              </td>
              <td>
                <Button
                  type="button"
                  variant="contained"
                  color="info"
                  onClick={() => displayCost(selectedCost)}
                >
                  Agregar Costo/Aporte
                </Button>
              </td>
            </tr>
            <tr>
              <td>
                Equivalente FOB
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  style={{ maxWidth: 150, fontWeight: "bolder" }}
                  value={equFobTot().toLocaleString("es-CL")}
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>

      </Box>
      <div className="flex-row">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            onSave(prices);
          }}
        >
          Guardar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="warning"
          onClick={() => {
            onDelete();
          }}
        >
          Eliminar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          Cerrar
        </Button>
      </div>
    </Box>
  );
}

export default ShippingPrice;
