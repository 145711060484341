import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button } from "@mui/material";
import { waitOnResponse } from "../actions/utils";
import { API_ENDPOINT } from "../constants";
import axios from "axios";

import { LibraryBooksOutlined } from "@material-ui/icons";
import { saveAs } from "file-saver";
import * as Excel from "exceljs";
import MUIDataGrid from "./MUI_DataGrid";
import "../app.css";
import { useTranslation } from "react-i18next";

const generateExcel = async (boardingCollection) => {
  if (boardingCollection.length > 0) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Export. Boardings.");

    worksheet.columns = Object.keys(boardingCollection[0]).map((k) => {
      return { header: k, key: k };
    });
    worksheet.addRows(boardingCollection);

    const buffer = await workbook.xlsx.writeBuffer();
    var blob = new Blob([buffer]);
    saveAs(blob, "daiahub-export-boarding.xlsx");
  }
};

function BoardingView({ boardings, display, isLoaded }) {
  /* Propriedades de la data grid */
  const columns = [
    {
      field: "actionView",
      headerName: "View",
      sortable: false,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Link to={"/modules/exporter/boarding/" + params.id + "/documents"}>
            <LibraryBooksOutlined />
          </Link>
        );
      },
    },
    { field: "code", headerName: "#", width: 50 },
    { field: "state", headerName: "Status", width: 170 },
    { field: "booking", headerName: "Booking", width: 150 },
    { field: "vessel", headerName: "Vessel/Flight", width: 150 },
    { field: "consigneeName", headerName: "Consignee", width: 300 },
    { field: "portIn", headerName: "Port", width: 400 },
    { field: "exporterName", headerName: "Exporter", width: 400 },
    { field: "receiverName", headerName: "Broker", width: 150 },
    {
      field: "shippingCompanyName",
      headerName: "Shipping Company",
      width: 150,
    },
    { field: "container", headerName: "Container", width: 150 },
    { field: "specie", headerName: "Specie", width: 100 },
    { field: "product", headerName: "Product", width: 100 },
    { field: "ETD", headerName: "ETD", width: 100 },
    { field: "ETA", headerName: "ETA", width: 100 },
    { field: "boxes", headerName: "Boxes", width: 100 },
  ];

  const { t } = useTranslation();

  const formatedRows = boardings.map((b) => {
    const stateDisplay = t("boarding.states." + b.state_id);
    return {
      id: b.id,
      code: b.code,
      state: stateDisplay,
      dateInPacking: b.dateInPacking,
      exporterName: b.exporterName || (b.exporter && b.exporter.name),
      receiverName: b.receiverName || (b.broker && b.broker.name),
      consigneeName: b.consigneeName || (b.consignee && b.consignee.name),
      shippingCompanyName: b.shippingCompanyName,
      portIn: b.portIn || (b.harbourOut && b.harbourOut.name),
      vessel: b.vesselName || (b.vessel && b.vessel.name),
      booking: b.booking,
      container: b.container,
      specie:
        b.specie ||
        (b.details && b.details.length > 0 && b.details[0].specie.name),
      product: b.product,
      boxes: b.boxes,
      ETD: moment(b.ETD).format("Y-MM-DD"),
      ETA: moment(b.ETA).format("Y-MM-DD"),
    };
  });

  const company = useSelector(
    (state) => state.currentUser.selectedRoleDisplay.company
  );

  const getBoardingForExcel = () => {
    axios
      .get(
        `${API_ENDPOINT}${
          "broker/company/" + company.id + "/async/boarding?format=export"
        }`
      )
      .then((result) => {
        waitOnResponse(result.data, (result) =>  generateExcel(result));
      });
  };

  return (
    <div>
      <div style={{ height: "450px" }}>
        <MUIDataGrid
          rows={formatedRows}
          columns={columns}
          lang={"eng"}
          isLoaded={isLoaded}
          exportToExcel={getBoardingForExcel}
        />
      </div>
    </div>
  );
}

export default BoardingView;
