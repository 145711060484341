import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import { Outlet } from "react-router-dom";
import { doLogin } from "../actions/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Icon from "../logo-hub";
import { APP_VERSION, API_ENDPOINT } from "../constants";
import NotionNews from "../components/notion-news";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";

function Copyright(props: any) {
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright "}
        <Link color="inherit" href="https://daia.cl/">
          Daia Systems
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
        {APP_VERSION}
      </Typography>

      <Typography align="center">
        <Link
          href={"https://www.linkedin.com/company/daia-systems-spa/"}
          target="_blank"
        >
          <LinkedInIcon />
        </Link>

        <Link href={"https://www.daia.cl"} target="_blank">
          <LanguageIcon />
        </Link>
      </Typography>
    </>
  );
}

const theme = createTheme();

interface SignInProps {
  defaultLogin: string;
}

interface CustomLoginProperties {
  defined: boolean;
  logoUri?: string;
  companyName?: string;
}

export default function SignInSide() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credencial, setCredencial] = React.useState({
    username: "",
    password: "",
    confirm: "",
    confirmCode: "",
  });

  const [customProperties, setCustomProperties] =
    React.useState<CustomLoginProperties>({ defined: false });

  const [message, setMessage] = React.useState({ type: "", content: "" });

  const { t } = useTranslation();

  React.useEffect(() => {
    //Recuperar el parametro de URL si existe
    const urlParams = new URLSearchParams(window.location.search);
    const companyUid = urlParams.get("company");

    //Si tenemos una personalización del login para esa empresa
    if (companyUid) {
      axios
        .get(API_ENDPOINT + "company/" + companyUid + "/customLoginProps")
        .then(function (response) {
          setCustomProperties(response.data);
        });
    }

    //Revisar si hay un usuario en el link
    const user = urlParams.get("u");
    if (user) {
      setCredencial({ ...credencial, username: user });
    }
  }, []);

  const signIn = () => {
    //Intentamos el login con los datos del usuario, si funciona pasamos un callback que actualiza
    //el estado de la vista y provoca el redirect
    dispatch(
      //@ts-ignore
      doLogin(
        credencial,
        () => {
          navigate("/role");
        },
        (err) => {
          setMessage({ type: "error", content: "Usuario o contraseña invalido." });
        }
      )
    );
  };

  const signUp = () => {
    //Creamos la cuenta
    if (credencial.password === credencial.confirm) {
      axios
        .post(API_ENDPOINT + "register", credencial)
        .then(function (response) {
          if (response.status === 200) {
            setCredencial({ ...credencial, password: "" });
            //Mostramos la pantalla de confirmación
            navigate("/auth/confirmRegister");
          } else {
            console.log(response.statusText);
          }
        });
    } else {
      console.log("Conf fail");
    }
  };

  const confirmSignUp = () => {
    //Enviamos el codigo de validación
    axios
      .post(API_ENDPOINT + "confirmRegister", credencial)
      .then(function (response) {
        if (response.status === 200) {
          setCredencial({ ...credencial, password: "" });
          setMessage({ type: "success", content: "Cuenta creada!" });
          navigate("/auth/login");
        } else {
          console.log(response.statusText);
        }
      });
  };

  const passwordRecovery = () => {
    axios
      .post(API_ENDPOINT + "passwordRecoveryCode", credencial)
      .then(function () {
        //Mostramos la pantalla de recovery
        setMessage({
          type: "info",
          content:
            "Te acabmos de enviar un correo con un código de validación para recuperar tu contraseña.",
        });
        navigate("/auth/passwordRecovery");
      })
      .catch(function (error) {
        console.log(error.response.data);
        const status = error.response.data;
        switch (status) {
          case "PASS_POLICY":
            //La contraseña no cumple los requisitos
            setMessage({
              type: "error",
              content: "La contraseña no cumple los requisitos",
            });
            break;
          case "EXPIRED":
            //Código venció, solicitar uno nuevo
            setMessage({
              type: "error",
              content: "El código de verificación expiró, solicita uno nuevo.",
            });
            break;
          case "RESEND_LIMIT":
            //Se exedió el limite de renvio de codigo
            setMessage({
              type: "error",
              content:
                "Superó el limite de envío de códigos, intenta más tarde.",
            });
            break;
          default:
            setMessage({ type: "error", content: status });
            break;
        }
      });
  };

  const confirmNewPassword = () => {
    axios
      .post(API_ENDPOINT + "passwordRecovery", credencial)
      .then(function () {
        //Mostramos la pantalla de recovery
        setMessage({ type: "success", content: "Contraseña restablecida!" });
        navigate("/auth/login");
      })
      .catch(function (error) {
        const status = error.response.data;
        switch (status) {
          case "PASS_POLICY":
            //La contraseña no cumple los requisitos
            setMessage({
              type: "error",
              content: "La contraseña no cumple los requisitos",
            });
            break;
          case "EXPIRED":
            //Código venció, solicitar uno nuevo
            setMessage({
              type: "error",
              content: "El código de verificación expiró, solicita uno nuevo.",
            });
            break;
          case "RESEND_LIMIT":
            //Se exedió el limite de renvio de codigo
            setMessage({
              type: "error",
              content:
                "Superó el limite de envío de códigos, intenta más tarde.",
            });
            break;
          default:
            setMessage({ type: "error", content: status });
            break;
        }
      });
  };

  const resendConfirmCode = () => {
    axios
      .post(API_ENDPOINT + "resendCode", credencial)
      .then(function (response) {
        if (response.status === 200) {
          setCredencial({ ...credencial, password: "" });
        } else {
          console.log(response.statusText);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={0}
          sm={0}
          md={8}
          sx={{
            backgroundImage: 'url("../background.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Noticias desde Notion */}
          <Paper
            sx={{
              display: { xs: "none", sm: "none", md: "inherit", lg: "inehrit" },
              width: "80%",
              overflow: 'hidden',
              marginLeft: "10%",
              marginRight: "10%",
              padding: 2,
              backgroundColor: "rgba(255, 255, 255, 0.6)",
            }}
          >
            <NotionNews />
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {customProperties.defined ? (
              <Avatar
                sx={{
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  height: '20vh',
                  width: '20vh',
                  padding: '2vh'
                }}
                alt={customProperties.companyName}
                src={customProperties.logoUri}
              ></Avatar>
            ) : (
              <Icon size="200" />
            )}

            <Typography component="h1" variant="h5"></Typography>
            {message.type.length > 0 && (
              <Alert severity={message.type}>{message.content}</Alert>
            )}
            <Outlet
              context={[
                t,
                credencial,
                setCredencial,
                signIn,
                passwordRecovery,
                signUp,
                confirmSignUp,
                resendConfirmCode,
                confirmNewPassword,
              ]}
            />
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
