import { GET_COMPANIES, GET_ASSOCIATION_REQUEST } from '../actions/index'

const initialState = { list: [], associationRequests: [] }

export default function CompanyReducer(state=initialState, action) {
    switch (action.type) {
        case GET_COMPANIES:
            return {
                ...state,
                list: action.payload
            }
        case GET_ASSOCIATION_REQUEST:
            return {
                ...state,
                associationRequests: action.payload
            }
        default:
            return state
    }
}