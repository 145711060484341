import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Skeleton,
  Grid,
  Paper,
  Modal,
  TextField,
  MenuItem,
} from "@mui/material";
import { use } from "i18next";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { API_ENDPOINT } from "../constants";

import axios from "axios";
import { json } from "react-router-dom";

type GrowerDocumentUploadForm = {};

interface IGrowerDocumentUpload {
  isOpen: boolean;
  documentType: string;
  handleClose: () => void;
}

/*

  id: number;
  code: string;
  uuid: string;
  exporterName: string;
  growerName: string;
  fechaPacking: Date;
  fileType: string;
  packingName: string;
  result: string;
  specieName: string;
  varietyName: string;
  newMessages: number;

  */

export default function GrowerDocumentUpload(props: IGrowerDocumentUpload) {
  const { t } = useTranslation();

  const companyId = useSelector(
    //@ts-ignore
    (state) => state.currentUser.selectedRoleDisplay.company.id
  );
  //@ts-ignore
  const packings = useSelector((state) => state.assets.packings);
  //@ts-ignore
  const growers = useSelector((state) => state.assets.growers);
  //@ts-ignore
  const species = useSelector((state) => state.assets.species);
  //@ts-ignore
  const varieties = useSelector((state) => state.assets.varieties);

  const [selectedSpecie, setSelectedSpecieId] = React.useState(1);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var data = new FormData(event.currentTarget);
    var formData = new FormData();

    data.append("exporterId", companyId);

    //Solicitamos un link prefirmado para subir documento a AWS
    axios
      .post(`${API_ENDPOINT}document/uploadUrl?t=` + props.documentType, data)
      .then((s3Fields) => {
        //Copiamos las claves de S3
        Object.keys(s3Fields.data.fields).forEach(key => {
          formData.append(key, s3Fields.data.fields[key]);
        });

        formData.append("file", data.get("file") as Blob);

        //Enviamos el documento a AWS
        fetch(s3Fields.data.url, {
          method: "POST",
          mode: "cors",
          body: formData,
        }).then((res) => {
          //OK subido!
          props.handleClose();
        });
      });
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="Upload a document"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper sx={{ width: "50vw", padding: "12px" }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h1" gutterBottom>
                Subir nuevo proceso
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={2}>
              <TextField
                fullWidth
                name="processCode"
                label="Nro. de proceso"
                placeholder={"123"}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <TextField select fullWidth label="Productor" name="growerId">
                {growers.map((asset, i) => (
                  <MenuItem key={i} value={asset.id}>
                    {asset.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <TextField select fullWidth label="Packing" name="packingId">
                {packings.map((asset, i) => (
                  <MenuItem key={i} value={asset.id}>
                    {asset.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={2}>
              <TextField
                select
                fullWidth
                label="Especie"
                name="specieId"
                onChange={(e) => setSelectedSpecieId(parseInt(e.target.value))}
              >
                {species.map((asset, i) => (
                  <MenuItem key={i} value={asset.id}>
                    {asset.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <TextField select fullWidth label="Variedad" name="varietyId">
                {varieties
                  .filter((asset) => asset.specie_id === selectedSpecie)
                  .map((asset, i) => (
                    <MenuItem key={i} value={asset.id}>
                      {asset.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={5}>
              <TextField
                fullWidth
                type="number"
                name="result"
                label="Exportable"
                placeholder={"98%"}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button variant="contained" component="label" color="primary">
                Selecionar archivo
                <input type="file" hidden name="file" />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              display={"flex"}
              alignItems="space-between"
              justifyContent="space-between"
            >
              <Button type="submit" variant="contained" color="success">
                Subir
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={props.handleClose}
                autoFocus
              >
                {t("common.close")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
}
