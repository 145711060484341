import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { API_ENDPOINT } from '../constants';
import { Button, Grid, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Document from '../components/document';
import ExporterChat from '../components/exporter-chat';
import GenericDialog from '../components/generic-dialog'
import GenericLoader from '../components/generic-loader';
import axios from 'axios';
import moment from 'moment'
import '../exporter.css';

const DOC_STATES = {
  DOC_PENDING: 0,
  DOC_SENT: 1,
}

function ExporterBoardingDocuments({ currentUser, maxView, boardingId, layout }) {
    const [documents, setDocuments] = useState([]);
    const [boarding, setBoarding] = useState({});
    const [selectedForDelete, setSelected] = useState(-1);
    const [isLoading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => loadDocuments(), [boardingId]);

    const loadDocuments = () => {
        axios.get(`${API_ENDPOINT}${'exporter/asset/company/'}${currentUser.selectedRoleDisplay.company.id}${'/boarding/' + boardingId + '/documents'}${'?limit='}${maxView || 1000}`)
            .then(function (response) {
                setBoarding(response.data);
                setDocuments(response.data.documents || []);
            })
            .catch(() => {
                setDocuments([]);
            });
    }

    const handleAddDocument = () => {
          setDocuments([
              ...documents,
              {
                id: -1,
                type: '',
                type_id: '',
                state: DOC_STATES.DOC_PENDING,
                display_name: '',
                key: Date.now(),
                file: {}
              }
          ]);
    }

    const handleFileChanged = (event, documentKey) => {
        //Selecionamos el archivo
        var document = documents.find((doc) => { return doc.key === documentKey });
        document.file = event.target.files[0];
        document.display_name = event.target.files[0].name;
    }

    const handleFileUpload = (documentKey) => {
        var self = this
        var formData = new FormData();
        var document = documents.find((doc) => { return doc.key === documentKey })

        formData.append("doc", document.file);
        formData.append("doc_name", document.display_name);
        formData.append("doc_type_id", document.type_id);
        formData.append("doc_key", document.key);
        formData.append("boarding_id", boardingId);

        setLoading(true);
        axios.post(`${API_ENDPOINT}${'exporter/asset/company/'}${currentUser.selectedRoleDisplay.company.id}${'/boarding/' + boardingId + '/documents'}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
          //refresh
          loadDocuments();
        })
        .finally(() => setLoading(false));
    }

    const deleteDocument = (documentId) => {
      axios.delete(`${API_ENDPOINT}${'document/id/' + documentId + '?type=boarding'}`)
      .then(function (response) {
        //refresh
        loadDocuments();
      });
    }

    return (<div>
      <GenericLoader
      title={"Uploading file..."}
      buttons={{cancel: 'Cancelar'}}
      openState={isLoading} />

      <GenericDialog
      message={"Seguro desea eliminar el documento selecionado?"}
      title="Eliminar documento"
      buttons={{cancel: 'Cancelar', confirm:'Confirmar'}}
      openState={selectedForDelete > 0}
      assetId={selectedForDelete}
      onClose={(status) => {
        if (status === 1) {
         deleteDocument(selectedForDelete);
         }

         setSelected(-1);

       }} />
       {/* Resumen del boarding */}
       <Box sx={{ border: 1, padding: 2, margin: 1, borderRadius: 1, borderColor: 'primary.main', borderStyle: 'dashed' }}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Typography variant="body">Boarding Ref.</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body">Vessel</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body">Container</Typography>
          </Grid>

        <Grid item sm={4}>
            <Typography variant="h5" component="h5">#{boarding.code}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="h5" component="h5">{boarding.vessel && boarding.vessel.name}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="h5" component="h5">{boarding.container}</Typography>
          </Grid>

          <Grid item sm={4}>
            <Typography variant="body">Consignee</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body">Port</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body">ETA</Typography>
          </Grid>

          <Grid item sm={4}>
            <Typography variant="h5" component="h5">{boarding.consignee && boarding.consignee.name}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="h5" component="h5">{boarding.harbourOut && boarding.harbourOut.name}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="h5" component="h5">{moment(boarding.ETA).format("YYYY-MM-DD")}</Typography>
          </Grid>

          <Grid item sm={8}>
            <Typography variant="body">Broker</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body"></Typography>
          </Grid>

          <Grid item sm={8}>
            <Typography variant="h5" component="h5">{boarding.broker && boarding.broker.name}</Typography>
          </Grid>
          <Grid item sm={4}>
          </Grid>
       </Grid>
       </Box>
            <Typography variant="h5" component="h5">
            {t('boarding.documents.title')} ({documents.length})
            </Typography>
            <Button variant="contained" color="success" onClick={() => {
                handleAddDocument();
             }}>{t('boarding.documents.addDocument')}</Button>

             <Grid container>
              {documents.map((doc, x) => {
                return (<Grid item xs={3} sx={{ marginTop: 2, marginBottom: 2}} key={x}>
                          <Box sx={{ border: 1, width: 1, margin: 0.2, padding: 1, borderRadius: 1, borderColor: 'primary.main', borderStyle: 'dashed' }}>
                            <Document display={'boarding'}
                            document={doc && doc.id > 0 ? doc : {}}
                            documentKey={doc.key}
                            onDelete={() => {
                              setSelected(doc.id);
                            }}
                            onFileChanged={(e, documentKey) => {
                                handleFileChanged(e, doc.key);
                            }} />
                            {
                              doc.state === DOC_STATES.DOC_PENDING &&
                              <Button variant="contained" color="success" onClick={() => { handleFileUpload(doc.key); }}>{t('boarding.documents.upload')}</Button>
                            }
                          </Box>
                      </Grid>)
              })}
            </Grid>

            <Typography variant="h5" component="h5">
            {t('boarding.documents.messages')}
            </Typography>

            <ExporterChat boardingId={boardingId}/>
    </div>)
}

function mapStateToProps(store) {
    return {
        currentUser: store.currentUser
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ExporterBoardingDocuments);
