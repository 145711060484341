import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { API_ENDPOINT } from '../constants'
import axios from 'axios'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import '../app.css'
import { TextField, MenuItem } from '@mui/material';


interface Company {
    code: string
    name: string
    id: number
    company_type: CompanyType
}

interface CompanyType {
  id: number
  code: string
}

interface Access {
    code: string
    id: number
}

interface AccessType {
    access: Access
    acccess_to_company: Company
}

const accessTypes = [
  {id: 1, name: 'Link'},
  {id: 2, name: 'Cliente'},
  {id: 3, name: 'Full'},
]

function AdminCompanyAccess({ company, open, handleClose, companyList }) {
    /* Propriedades de la data grid */
    const [access, setAccess] = useState<AccessType[]>([]);
    const [newAccess, setNewAccess] = useState({access_to: (company && company.id) || 1, access_type: 1});

    const loadUserAccess = (c) => {
        axios.get(`${API_ENDPOINT}${'admin/company/' + c.id + '/access'}`, )
        .then(function (response) {
            setAccess(response.data);
        });
    }

    useEffect(() => {
        if (company) {
            loadUserAccess(company);
        }
    }, [company && company.id]);

    return ( <div>
        {company &&
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            {"Accesos empresa [" + company.display_name + "]"}
          </DialogTitle>
          <DialogContent>
            <Grid container sx={{marginBottom: 5}}>
              <Grid item sm={3}><b>Acceso</b></Grid><Grid item sm={6}><b>Empresa</b></Grid><Grid item sm={3}><b>Tipo</b></Grid>
                {
                  access.map((a) => {
                      return <Grid container>
                          <Grid item sm={3}>({a.access.id}){a.access.code}</Grid><Grid item sm={6}>({a.acccess_to_company.id}){a.acccess_to_company.name}</Grid><Grid item sm={3}>({a.acccess_to_company.company_type.id}){a.acccess_to_company.company_type.code}</Grid>
                          </Grid>
                  })
                }
            </Grid>
            <Grid container>
              <Grid item sm={12}>
                Nuevo acceso
              </Grid>
              <Grid item sm={3}>
                <TextField select
                  id="at-select"
                  value={newAccess.access_type || 1}
                  label="Acceso"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setNewAccess({
                      ...newAccess,
                      access_type: parseInt(e.target.value)
                    })
                  }}
                  >
                  {accessTypes.map((at) => {
                    return <MenuItem value={at.id}>({at.id}) {at.name}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item sm={6}>
                <TextField select
                  id="at-select"
                  value={newAccess.access_to || company.id}
                  label="Empresa"
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setNewAccess({
                      ...newAccess,
                      access_to: parseInt(e.target.value)
                    })
                  }}
                  >
                  {companyList.map((cl) => {
                    return <MenuItem value={cl.id}>({cl.id}) {cl.display_name} {cl.company_type.code}</MenuItem>
                  })}
                </TextField>
              </Grid>
              <Grid item sm={3}>
              <Button variant='contained' onClick={() => {
                /* Enviamos un JSON para crear el nuevo acceso */
                const json = JSON.stringify({granted_to: company.id, ...newAccess});

                axios.post(`${API_ENDPOINT}${'admin/company/' + company.id + '/access'}`,
                json, {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                })
                .then(function (response) {
                  loadUserAccess(company);
                });
                }
              } fullWidth sx={{height: '100%'}}>
                  {"Agregar"}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => {
            handleClose(0)
          }
        } autoFocus>
            {"Cerrar"}
          </Button>
          </DialogActions>
        </Dialog>}
      </div>)
}

export default AdminCompanyAccess;
