import { useLocation, Navigate } from "react-router-dom";
/*
 * Averiguar si el usuaio está loggeado y escogió un role
 */
export default function RequireAuth({ children }) {
    let location = useLocation();
    //Recuperamos el usuario conectado
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null;

    //Recuperamos el role
    const selectedRole = localStorage.getItem('selectedRole')
        ? JSON.parse(localStorage.getItem('selectedRole'))
        : {}

    if (!user) {
        // Redirecionamos a la pagina de login y remplazamos la entrada en el historial, para que el "Precedente" no lo tome en consideración
        return <Navigate to="/auth" state={{ from: location }} replace />;
    }
    else if (!selectedRole.id &&
        (location.pathname !== '/role' && location.pathname !== '/company/create' && location.pathname !== '/logout')) {
        //Selección del role
        return <Navigate to="/role" state={{ from: location }} replace />;
    }

    //Tenemos un usuario loggeado y un role selecionado, mostramos la pagina
    return children;
}
