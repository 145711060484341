import React, {useEffect, useState} from 'react';
import { Paper } from "@mui/material";
import { API_ENDPOINT } from '../constants'

import axios from 'axios'

function NotionBlockRender({blockMd}) {
    const textBlocks = ['heading_1', 'bulleted_list_item', 'paragraph']
    const blockType = blockMd.type;

    if (blockType === 'image') {
        const s3Uri = blockMd[blockType]['file']
        return <img src={s3Uri.url} />
    }
    else if (textBlocks.includes(blockType)) {
        if(blockMd[blockType]['rich_text'].length > 0) {
            const blockRichText = blockMd[blockType]['rich_text'][0];
            const blockPlainText =  blockRichText['plain_text'];
            
            switch(blockType) {
                case 'heading_1':
                    return <h4>{blockPlainText}</h4> 
                case 'bulleted_list_item':
                    return <li style={{fontSize: 12}}>{blockPlainText}</li> 
                case 'paragraph':
                    return <i style={{fontSize: 12}}>{blockPlainText}</i> 
            }

            return <p style={{fontSize: 10}}>{blockPlainText}</p>
        }
        else {
            return <p style={{fontSize: 10}}>{blockType}</p>
        }
    }
    else {
        return <p style={{fontSize: 10}}>{blockType}</p>
    }
}

function markDownToReact(markDownList) {    
    const result = markDownList.results.map((md, k) => {
        return <NotionBlockRender key={k} blockMd={md}/>
    });

    return result;
}

function NotionNews() {
    const [latestNews, setNews] = useState({results: []});

    useEffect(() => {
        //No cargar noticias de notion por el momento
        //axios.get(`${API_ENDPOINT}${'news'}`).then((res) => setNews(res.data));
    }, [])

    return (
    <>
        {markDownToReact(latestNews)}
    </>
    )
  }

export default NotionNews;