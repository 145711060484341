import React from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import moment from "moment";
import { ProcessImport } from "../components/exporter-process-import";

import { API_ENDPOINT } from "../constants";
import axios from "axios";
import "../exporter.css";
import MUIDataGrid from "../components/MUI_DataGrid";
import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  DeleteForeverOutlined,
  LibraryBooksOutlined,
  SearchOutlined,
} from "@material-ui/icons";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { saveAs } from "file-saver";
import * as Excel from "exceljs";
import GenericLoader from "../components/generic-loader";

export function ExporterPriceMatrix() {
  const [isLoaded, setLoaded] = React.useState(true);
  const [selectedShipping, setSelected] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [entries, setEntries] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const companyId = useSelector(
    //@ts-ignore
    (c) => c.currentUser.selectedRoleDisplay.company.id
  );

  const { t } = useTranslation();
  const shippingStates = [
    { id: 0, desc: t("shipping.states.0") },
    { id: 1, desc: t("shipping.states.1") },
    { id: 2, desc: t("shipping.states.2") },
  ];

  /* 
  !Columnas y lineas para la grid MUI 
  */
  const columns = [
    { field: "code", headerName: t("boarding.grid.code"), width: 150 },
    {
      field: "shipping_date",
      headerName: t("boarding.grid.dateInPacking"),
      width: 110,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return moment(value).format("YYYY-MM-DD");
      },
    },
    {
      field: "packing",
      headerName: t("boarding.grid.packingName"),
      width: 250,
    },
    {
      field: "customer",
      headerName: t("boarding.grid.receiverName"),
      width: 250,
    },
    {
      field: "actionView",
      headerName: "Ver",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Link to={"/modules/cost/prices/" + params.id}>
            <LibraryBooksOutlined />
          </Link>
        );
      },
    },
  ];

  //Formateamos las entradas
  const formatedRows = entries.map((e) => {
    return {
      code: e.code,
      customer: e.customer.name,
      packing: e.packing.name,
      shipping_date: e.shipping_date,
      state: t("shipping.states." + e.state),
      id: e.id,
    };
  });

  const loadShippings = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const shippingCodeQuery = data.get("code");
    const shippingStateQuery = data.get("state");

    setLoaded(false);

    if (shippingCodeQuery) {
      /* Busquemos por código */
      axios
        .get(
          `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${
            "/shipping?code=" + shippingCodeQuery
          }`
        )
        .then(function (response) {
          setEntries(response.data);
        })
        .finally(() => setLoaded(true));
    } else {
      axios
        .get(
          `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${
            "/shipping?state=" + shippingStateQuery
          }`
        )
        .then(function (response) {
          setEntries(response.data);
        })
        .finally(() => setLoaded(true));
    }
  };

  const handleExportToExcel = () => {
    setLoaded(false);
    axios({
      url: `${API_ENDPOINT}${"exporter/asset/company/"}${companyId}${"/priceMatrix?format=file"}${"&timestamp="}${new Date().getTime()}`,
      method: "GET",
      responseType: "json",
    })
      .then(function (response) {
        const jsonArray = response.data;
        if (jsonArray.length > 0) {
          // A new Excel Work Book
          const firstElement = jsonArray[0];
          var workbook = new Excel.Workbook();
          var sheet = workbook.addWorksheet("DaiaHub - Export");
          const headers = Object.keys(firstElement).map((h) => {
            return { key: h, header: h };
          });
          sheet.columns = headers;
          // Create a sheet

          // Add rows in the above header
          for (let k in jsonArray) {
            const entry = jsonArray[k];
            sheet.addRow(entry);
          }

          sheet.columns.forEach((column) => {
            const lengths = column.values.map((v) => v.toString().length);
            const maxLength = Math.max(
              ...lengths.filter((v) => typeof v === "number")
            );
            column.width = maxLength;
          });

          const buffer = workbook.xlsx.writeBuffer().then((buffer) => {
            var blob = new Blob([buffer]);
            saveAs(blob, "daiahub_valorizacion.xlsx");
          });
        } else {
          //Sin contenido
        }
      })
      .finally(() => setLoaded(true));
  };

  return (
    <div className="exporter-mod">
      <GenericLoader
        message={"Loading..."}
        title={""}
        buttons={[]}
        onClose={() => {}}
        openState={!isLoaded}
        assetId={() => {}}
      />
      <Box
        component={"form"}
        className="flex-row"
        onSubmit={(e) => loadShippings(e)}
      >
        <Grid container spacing={2} padding={1}>
          <Grid item sm={3}>
            <TextField
              select
              id="stateSelector"
              fullWidth
              name="state"
              label="Estado"
              defaultValue={1}
            >
              {shippingStates.map((x) => {
                return (
                  <MenuItem key={x.id} value={x.id}>
                    {x.desc}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item sm={5}>
            <TextField
              fullWidth
              type="text"
              name="code"
              variant="outlined"
              label="Id. Despacho"
            />
          </Grid>
          <Grid item sm={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ height: "100%" }}
            >
              <SearchOutlined />
              Buscar
            </Button>
          </Grid>
          <Grid item sm={2}>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ height: "100%" }}
              onClick={() => setOpenModal(true)}
            >
              <UploadFileIcon />
              Importar
            </Button>
          </Grid>
        </Grid>
      </Box>
      {searchParams.get("success") === "1" && (
        <Alert color="success">Masiva importada con exito! Los despachos fueron agregados con el estado "Abierto"</Alert>
      )}
      <Box height={720}>
        <MUIDataGrid
          rows={formatedRows}
          columns={columns}
          exportToExcel={() => {
            handleExportToExcel();
          }}
          isLoaded={isLoaded}
        />
      </Box>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Paper
          variant="outlined"
          sx={{ height: "80vh", width: "80vw", m: "5%", p: 2 }}
        >
          <ProcessImport onClose={() => setOpenModal(false)}/>
        </Paper>
      </Modal>
    </div>
  );
}

export default ExporterPriceMatrix;
