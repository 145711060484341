import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { API_ENDPOINT } from '../constants'
import axios from 'axios'
import GenericLoader from '../components/generic-loader';

import AdminUserList from '../components/admin-user-list'
import '../exporter.css'


function AdminUsers({ currentUser, maxView }) {
    const [isLoading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const loadUsers = () => {
        axios.get(`${API_ENDPOINT}${'admin/user'}`,)
            .then(function (response) {
                setUsers(response.data);
            });
    }

    useEffect(() => {
        setLoading(true);
        loadUsers();
        setLoading(false);
    }, [currentUser.id]);

    return (<div>
        <GenericLoader
            title={"Recuperando usuarios..."}
            buttons={{ cancel: 'Cancelar' }}
            message={""}
            assetId={1}
            onClose={() => { }}
            openState={isLoading} />
        <AdminUserList users={users} display={'compact'} />
    </div>)
}

function mapStateToProps(store) {
    return {
        currentUser: store.currentUser
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
