import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCompanies } from '../actions/index'

class CompanySelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filteredCompanies: []
        }
    }

    componentDidMount() {

    }

    render() {
        let filteredCompanies = this.props.companies.list;
        let self = this
        if (!this.props.showAll) {
            //Filtro por tipo de empresa, si es que est� definido
            if (this.props.companyTypeId > 0) {
                if (filteredCompanies.length > 0) {
                    filteredCompanies = filteredCompanies.filter((c) => {
                        return c.company_type_id === self.props.companyTypeId
                    })
                }
            }
        }

        if (filteredCompanies.length > 0) {
            return (<div>
                <select className='form-control input-min-width form-control-sm' id='companySelect'
                    onChange={(e) => this.props.onChange(e, this.props.companySelectKey)}
                    value={ this.props.currentValue }>
                    <option value='-1'> --- </option>
                    {
                        filteredCompanies.map(company => {
                            return (<option value={company.id} key={company.id}> {company.rut} - {company.name} </option>)
                        })
                    }
                </select>
            </div>);
        }
        else {
            return (
                <div>
                    <select className='form-control form-control-sm' disabled>
                        <option value={-1} key={-1}>Sin resultados</option>
                    </select>
                </div>);
        }
    }
}

function mapStateToProps(store) {
    return {
        currentUser: store.currentUser,
        companies: store.companies
    }
}

const mapDispatchToProps = {
    getCompanies
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelect)