import * as React from 'react';
import { ReactComponent as LoaderAnimation } from './loader.svg';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function GenericLoader({message, title, buttons, onClose, openState, assetId}) {
  const [open, setOpen] = React.useState(openState);

  React.useEffect(() => { setOpen(openState)}, [openState, assetId]);


  const handleClose = (status:number) => {
    setOpen(false);
    if (typeof onClose !== 'undefined')
    {
      onClose(status);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="loader-dialog-title"
        aria-describedby="loader-dialog-description"
      >
        <DialogTitle id="loader-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="loader-dialog-description">
          <LoaderAnimation />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/*
        <Button onClick={() => {
          handleClose(0)
        }
      } autoFocus>
          {buttons.cancel}
    </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
