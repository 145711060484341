import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectRole } from "../actions/index";
import RoleCard from "../components/role-card";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NewRoleCard from "../components/role-new";
import Box from "@mui/material/Box";

export default function RoleSelect() {
  const roles = useSelector(
    //@ts-ignore
    (c) => c.currentUser.credentials.roles
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div style={{ overflow: "visible" }}>
      <div className="flex-ro0w py-3">
        <div className="alert alert-info alert-text">
          <div className="col-12 ">
            <div className="row">
              <div className="col-10 mx-5">
                <strong style={{fontSize: '1.5vw'}}>{t("role.important")}</strong>
                <div className="col-12 mx-1" style={{fontSize: '1vw'}}>{t("role.messageSelect")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Box
        sx={{
          minWidth: "360px",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "row",
          padding: "12px",
          overflow: "visible",
          overflowX: "scroll",
        }}
      >
        {roles.map((r) => (
          <RoleCard
            key={r.id}
            role={{
              companyName: r.company.display_name || r.company.name,
              companyId: r.company.id,
              companyTypeId: r.company.company_type.id,
              accessTypeId: r.access_type,
            }}
            onRoleSelected={() =>
              dispatch(
                //@ts-ignore
                selectRole(r, () => {
                  navigate("/");
                })
              )
            }
          />
        ))}
        {!roles ||
          (roles.length === 0 && (
            <NewRoleCard
              onNewRoleSelected={() => navigate("/company/create")}
            />
          ))}
      </Box>
    </div>
  );
}
