import React, { useEffect, useState } from 'react'
import { TextField, InputLabel, Button, MenuItem, FormControl } from "@mui/material";
import { API_ENDPOINT } from '../constants'
import { exporterSetAsset, exporterGetAsset, exporterDeleteAsset } from '../actions';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios'
import '../app.css'

const costTypes = [
  {id: 1, desc:'Gasto'},
  {id: 2, desc:'Ingreso'}
]

function Cost({ companyId, companyName, companyType }) {
    const [newAsset, setNewAsset] = useState({});
    const dispatch = useDispatch();

    const assets = useSelector(state => state.assets.costs) || [];
    useEffect(() => {
      dispatch(exporterGetAsset('cost', companyId));
    }, [companyId]);

    const saveAsset = () => {
      dispatch(exporterSetAsset('cost', newAsset, companyId));
    }

    const deleteAsset = (id) => {
      dispatch(exporterDeleteAsset('cost', id, companyId));
    }

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField fullWidth id="filled-basic"
                        label="Descripción" variant="filled"
                        style={{ margin: '4px' }}
                        value={newAsset.description || ''}
                        onInput={(e) => {
                            const newValue = e.target.value;
                            setNewAsset({
                                ...newAsset,
                                description: newValue
                            })
                        }}
                    />
                    <TextField select
                        id="state-select"
                        value={newAsset.apply || 1}
                        label="Tipo"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            setNewAsset({
                                ...newAsset,
                                apply: e.target.value,
                            })
                        }}
                    >
                        {costTypes.map((e) => {
                            return <MenuItem value={e.id}>{e.desc}</MenuItem>
                        })}
                    </TextField>
                    <TextField fullWidth id="filled-basic"
                        label="Valor ($)" variant="filled"
                        style={{ margin: '4px' }}
                        value={newAsset.value || ''}
                        onInput={(e) => {
                            const newValue = e.target.value;
                            setNewAsset({
                                ...newAsset,
                                value: newValue
                            })
                        }}
                    />

                    <TextField select
                        id="state-select"
                        value={newAsset.required}
                        label="Tipo"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            setNewAsset({
                                ...newAsset,
                                required: e.target.value,
                            })
                        }}
                    >
                            <MenuItem value={0}>{'Opcional'}</MenuItem>
                            <MenuItem value={1}>{'Obligatorio'}</MenuItem>
                    </TextField>

                    <Button variant="contained" color="success" onClick={() => { saveAsset(); }}>Crear</Button>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Descripción</th>
                            <th>Tipo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assets.map((a, i) => {
                                return <tr key={i}>
                                    <td>{a.description}</td>
                                    <td>{a.apply}</td>
                                    <td><Button variant="contained" color="warning" onClick={() => { deleteAsset(a.id); }}>X</Button></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </>
        )
}

export default Cost;
