import React, { useEffect, useState, useRef } from "react";
import {
  exporterGetBoardingMessages,
  exporterSendBoardingMessages,
} from "../actions";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { API_ENDPOINT } from "../constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CardActionArea } from "@mui/material";

interface NewRoleCardProps {
  onNewRoleSelected: Function;
}

export default function NewRoleCard({ onNewRoleSelected }: NewRoleCardProps) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        maxWidth: 240,
        minWidth: "36vmin",        
        backgroundColor: "white",
        height: "30vh",
        display: "flex",
        flexDirection: "column",
        marginLeft: "8px",
        marginRight: "8px",
        boxShadow: "5px 0px 5px 0px rgba(0,0,0,0.75)",
      }}
    >
      <CardActionArea onClick={() => onNewRoleSelected()} sx= {{ height: "100%"}}>
      <AddCircleOutlineIcon fontSize="large"/>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" color={"green"}>
            Nueva empresa
          </Typography>
          <Typography variant="body1"  color={"white"}>
          
          </Typography>
          <Typography variant="body1"  color={"white"}>
          
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
