import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function GenericDialog({message, title, buttons, onClose, openState, assetId}) {
  const [open, setOpen] = React.useState(openState);

  React.useEffect(() => { setOpen(openState)}, [openState, assetId]);


  const handleClose = (status:number) => {
    setOpen(false);
    if (typeof onClose !== 'undefined')
    {
      onClose(status);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {
          handleClose(0)
        }
      } autoFocus>
          {buttons.cancel}
        </Button>
          <Button onClick={() => {
            handleClose(1)
          }
        }>{buttons.confirm}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
