import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeErrorMessage } from "../actions";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface GenericErrorMessage {
  message: string;
  title: string;
  show: boolean;
}

export default function GenericErrorPopup({ errorMessage }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    //@ts-ignore
    dispatch(closeErrorMessage());
  };

  return (
    <div>
      {errorMessage != null && (
        <Dialog
          open={errorMessage != null}
          onClose={handleClose}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Alert variant="outlined" severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage.title}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMessage.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClose();
              }}
              autoFocus
            >
              {t("common.close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
