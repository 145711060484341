import { Route } from "react-router-dom";

import RequireAuth from './router_require_auth'
import AdminMain from './../containers/admin-main'
import AdminUsers from './../containers/admin-users'
import AdminCompanies from './../containers/admin-companies'

export default  <Route path="/modules/admin">
                                    <Route index element={
                                        <RequireAuth>
                                            <AdminMain>
                                                <AdminUsers />
                                            </AdminMain>
                                            </RequireAuth>
                                    } />
                                  <Route path="companies" element={
                                    <RequireAuth>
                                    <AdminMain>
                                        <AdminCompanies />
                                    </AdminMain>
                                    </RequireAuth>
                                  } />
                                  <Route path="users" element={
                                    <RequireAuth>
                                    <AdminMain>
                                        <AdminUsers />
                                    </AdminMain>
                                    </RequireAuth>
                                  } />
                                  <Route path="access" element={
                                    <RequireAuth>
                                    <AdminMain>
                                        Accesos
                                    </AdminMain>
                                    </RequireAuth>
                                  } />
                                  <Route path="logs" element={
                                    <RequireAuth>
                                    <AdminMain>
                                        Logs
                                    </AdminMain>
                                    </RequireAuth>
                                  } />
                              </Route>
