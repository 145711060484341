import React, { Component } from 'react'
import { API_ENDPOINT, RESERVATION_URL } from '../constants'
import axios from 'axios'
import moment from 'moment';
import '../app.css'

const zeroPad = (num, places) => { return String(num).padStart(places, '0') }

class DatePicker extends Component {
    constructor(props) {
        super(props)
        if (this.props.selectedDateTime) {
            //Format to ISO8601
            //Marcador de tiempo
            const momentDate = moment(this.props.selectedDateTime);
            console.log(momentDate);
            let formatedDate = this.props.selectedDateTime;
            let selectedDate = new Date(formatedDate)
            let availableHours = []
            availableHours.push(selectedDate.getHours())

            this.state = {
                selectedDateTime: {
                    date: selectedDate.getUTCFullYear() + "-" + zeroPad(selectedDate.getUTCMonth() + 1, 2) + "-" + zeroPad(selectedDate.getUTCDate(), 2),
                    time: selectedDate.getHours()
                },
                availableHours: availableHours,
                enableHourPicker: false
            }
        }
        else {
            this.state = {
                selectedDateTime: {},
                availableHours: [],
                enableHourPicker: false
            }
        }
    }

    loadAvailableHours(date) {
        let selectedDate = date
        let self = this
        axios.get(`${API_ENDPOINT}${RESERVATION_URL}${/packing/}${this.props.companyId}${/shippingHours/}${selectedDate}`)
            .then(function (response) {
                self.setState({
                    ...self.state,
                    availableHours: response.data
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() {
        return (
            <div className='input-group'>
                <input type="date" className='form-control form-control-sm' onChange={(e) => {
                    this.loadAvailableHours(e.target.value)
                    let newDateTime = {
                        ...this.state.selectedDateTime,
                        date: e.target.value
                    }
                    this.setState({
                        ...this.state,
                        selectedDateTime: newDateTime
                    })
                    this.props.onChange(
                        {
                            target: {
                                id: this.props.id,
                                value: newDateTime.date + "T" + newDateTime.time,
                            }
                        }
                    )
                }} value={this.state.selectedDateTime.date} />
                <select className='form-control form-control-sm' onChange={(e) => {
                    let newDateTime = {
                        ...this.state.selectedDateTime,
                        time: e.target.value
                    }
                    this.setState({
                        ...this.state,
                        selectedDateTime: newDateTime
                    })
                    this.props.onChange(
                        {
                            target: {
                                id: this.props.id,
                                value: newDateTime.date + "T" + newDateTime.time
                            }
                        }
                    )
                }}>
                    <option value={-1}> --:-- </option>
                    {
                        this.state.availableHours.map((h) => {
                            if (h === this.state.selectedDateTime.time) {
                                return (<option value={h} key={h} selected>{h}:00</option>)
                            }
                            else {
                                return (<option value={zeroPad(h, 2) + ":00"} key={h}>{zeroPad(h, 2)}:00</option>)
                            }
                        })
                    }
                </select>
            </div>
        )

    }
}

export default DatePicker;