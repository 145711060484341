import React, { useEffect, useState } from 'react'
import { TextField, InputLabel, Button, MenuItem, FormControl } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { API_ENDPOINT } from '../constants'
import { exporterSetAsset, exporterGetAsset, exporterDeleteAsset } from '../actions';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios'
import '../app.css'


function Vessel({ companyId, companyName, companyType }) {
    const [newAsset, setNewAsset] = useState({});
    const dispatch = useDispatch();

    const assets = useSelector(state => state.assets.vessels) || [];

    useEffect(() => {
      dispatch(exporterGetAsset('vessel', companyId));
    }, [companyId]);

    const saveAsset = () => {
      dispatch(exporterSetAsset('vessel', newAsset, companyId));
    }

    const deleteAsset = (id) => {
      dispatch(exporterDeleteAsset('vessel', id, companyId));
    }

        return (
              <LocalizationProvider dateAdapter={AdapterMoment}>
            <>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField fullWidth id="filled-basic"
                        label="Código" variant="filled"
                        style={{ margin: '4px', width: '200px' }}
                        value={newAsset.code || ''}
                        inputProps={{ maxLength: 15 }}
                        onInput={(e) => {
                            const newValue = e.target.value;
                            setNewAsset({
                                ...newAsset,
                                code: newValue
                            })
                        }}
                    />

                    <TextField fullWidth id="filled-basic"
                        label="Nombre" variant="filled"
                        style={{ margin: '4px', width: '280px' }}
                        value={newAsset.name || ''}
                        onInput={(e) => {
                            const newValue = e.target.value;
                            setNewAsset({
                                ...newAsset,
                                name: newValue
                            })
                        }}
                    />

                    <DateTimePicker label="Cut-off"
                    inputFormat="DD-MM-YYYY HH:mm"
                    value={newAsset.cutoff || ''}
                    onChange={(e) => {
                      const newValue = e.toISOString();
                      setNewAsset({
                        ...newAsset,
                        cutoff: newValue
                      })
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                    />
                    <DateTimePicker label="Stacking desde"
                    inputFormat="DD-MM-YYYY HH:mm"
                    value={newAsset.stacking_from || ''}
                    onChange={(e) => {
                      const newValue = e.toISOString();
                      setNewAsset({
                        ...newAsset,
                        stacking_from: newValue
                      })
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                    />
                    <DateTimePicker label="Stacking hasta"
                    inputFormat="DD-MM-YYYY HH:mm"
                    value={newAsset.stacking_to || ''}
                    onChange={(e) => {
                      const newValue = e.toISOString();
                      setNewAsset({
                        ...newAsset,
                        stacking_to: newValue
                      })
                    }}
                    renderInput={(params) => <TextField {...params} style={{ margin: '4px' }} />}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField fullWidth id="filled-basic"
                        label="IMO number" variant="filled"
                        style={{ margin: '4px', width: '200px' }}
                        value={newAsset.imo || ''}
                        inputProps={{ maxLength: 10 }}
                        onInput={(e) => {
                            const newValue = e.target.value;
                            setNewAsset({
                                ...newAsset,
                                imo: newValue
                            })
                        }}
                    />

                <Button variant="contained" color="success" size='large' onClick={() => { saveAsset(); }}>Crear</Button>
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Cut-Off</th>
                            <th>Stacking desde</th>
                            <th>Stacking hasta</th>
                            <th>IMO</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assets.map((a, i) => {
                                return <tr key={i}>
                                    <td>{a.code}</td>
                                    <td>{a.name}</td>
                                    <td>{a.cutoff}</td>
                                    <td>{a.stacking_from}</td>
                                    <td>{a.stacking_to}</td>
                                    <td>{a.imo}</td>
                                    <td><Button variant="contained" color="warning" onClick={() => { deleteAsset(a.id); }}>X</Button></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </>
            </LocalizationProvider>
        )
}

export default Vessel;
