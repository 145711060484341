import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { API_ENDPOINT } from '../constants'
import axios from 'axios'
import GenericLoader from '../components/generic-loader';

import AdminCompanyList from '../components/admin-company-list'
import '../exporter.css'


function AdminCompanies({ currentUser, maxView }) {
    const [isLoading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    

    const loadCompanies = () => {
        axios.get(`${API_ENDPOINT}${'admin/company'}`, )
        .then(function (response) {
            setCompanies(response.data);
        });
    }

    useEffect(() => 
    {
        setLoading(true);
        loadCompanies();
        setLoading(false);
    }, [currentUser.id]);

    return (<div>
            <GenericLoader
      title={"Recuperando usuarios..."}
      buttons={{cancel: 'Cancelar'}}
      message={""}
      assetId={1}
      onClose={() => {}}
      openState={isLoading} />
        <AdminCompanyList companies={companies} display={'compact'} />
    </div>)
}

function mapStateToProps(store) {
    return {
        currentUser: store.currentUser
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCompanies);
