import { GET_BOARDING, GET_BOARDINGS, GET_CURRENT_BOARDING_MESSAGES } from '../actions/index'

const initialState = { currentBoarding: {}, boardings: [], messages: [] }

export default function BoardingReducer (state=initialState, action) {
    switch (action.type) {
        case GET_BOARDING:
            //...state = el resto del state para no borrarlo
            return {
                ...state,
                currentBoarding: action.payload
            }
            case GET_BOARDINGS:
                //...state = el resto del state para no borrarlo
                return {
                    ...state,
                    boardings: action.payload
                }
            case GET_CURRENT_BOARDING_MESSAGES:
                //...state = el resto del state para no borrarlo
                return {
                    ...state,
                    messages: action.payload
                }
        default:
            return state
    }
}
