import { combineReducers } from 'redux'
import ReservationsReducer from './reducer_reservations'
import ActiveReservationReducer from './reducer_reservation_active'
import UserReducer from './reducer_user'
import CompanyReducer from './reducer_company'
import AssetReducer from './reducer_assets'
import LayoutReducer from './reducer_layout'
import BoardingReducer from './reducer_boarding'
import GrowerDocumentReducer from './reducer_grower_document'

const rootReducers = combineReducers({
    reservations: ReservationsReducer,
    activeReservation: ActiveReservationReducer,
    currentUser: UserReducer,
    companies: CompanyReducer,
    assets: AssetReducer,
    layout: LayoutReducer,
    boarding: BoardingReducer,
    growerDocuments: GrowerDocumentReducer,
});

export default rootReducers;
export type RootState = ReturnType<typeof rootReducers>