import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import Notifications from "@mui/icons-material/Notifications";
import DocumentScanner from "@mui/icons-material/DocumentScanner";
import DirectionsBoatOutlined from "@mui/icons-material/DirectionsBoatOutlined";
import BookOutlined from "@mui/icons-material/BookOutlined";
import Logout from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { updateNotificationState } from "../actions/index";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API_ENDPOINT } from "../constants";
import axios from "axios";

export default function AccountMenu({ company, user }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAccountMenu, setOpenAccountMenu] = React.useState<boolean>(false);
  const [openNotifications, setOpenNotificationMenu] =
    React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
    setOpenAccountMenu(false);
    setOpenNotificationMenu(false);
  };

  const handleChangeNotification = (notificationType: string) => {
    //Enviamos el update de estado de notificación para la empresa en curso
    axios
      .put(
        `${API_ENDPOINT}${"notification?"}${
          "c=" + company.id + "&nt=" + notificationType
        }`
      )
      .then(function (response) {
        //@ts-ignore
        dispatch(updateNotificationState(response.data));
      });
  };

  const showNotificationByCompanyType = (companyTypeId) => {
    switch (companyTypeId) {
      case 1:
        //Exportador
        return (
          <Box>
            <MenuItem style={{ justifyContent: "space-between" }}>
              <ListItemIcon>
                <BookOutlined fontSize="small" />
              </ListItemIcon>
              {t("notification.reservation")}
              <Switch
                onChange={() => handleChangeNotification("new_reservation")}
                checked={user.selectedRoleDisplay.inform_new_reservation === 1}
              />
            </MenuItem>
            <MenuItem style={{ justifyContent: "space-between" }}>
              <ListItemIcon>
                <DocumentScanner fontSize="small" />
              </ListItemIcon>
              {t("notification.document")}
              <Switch
                onChange={() => handleChangeNotification("new_document")}
                checked={user.selectedRoleDisplay.inform_new_document === 1}
              />
            </MenuItem>
          </Box>
        );
      case 2:
        //Packing
        return (
          <Box>
            <MenuItem style={{ justifyContent: "space-between" }}>
              <ListItemIcon>
                <BookOutlined fontSize="small" />
              </ListItemIcon>
              {t("notification.reservation")}
              <Switch
                onChange={() => handleChangeNotification("new_reservation")}
                checked={user.selectedRoleDisplay.inform_new_reservation === 1}
              />
            </MenuItem>
          </Box>
        );
      case 3:
        //Productor
        return (
          <Box>
            <MenuItem style={{ justifyContent: "space-between" }}>
              <ListItemIcon>
                <DocumentScanner fontSize="small" />
              </ListItemIcon>
              {t("notification.document_grower")}
              <Switch
                onChange={() => handleChangeNotification("new_document")}
                checked={user.selectedRoleDisplay.inform_new_document === 1}
              />
            </MenuItem>
          </Box>
        );
      case 4:
        //Recibidor/Broker
        return (
          <Box>
            <MenuItem style={{ justifyContent: "space-between" }}>
              <ListItemIcon>
                <DocumentScanner fontSize="small" />
              </ListItemIcon>
              {t("notification.reservation")}
              <Switch
                onChange={() => handleChangeNotification("new_document")}
                checked={user.selectedRoleDisplay.inform_new_document === 1}
              />
            </MenuItem>

            <MenuItem style={{ justifyContent: "space-between" }}>
              <ListItemIcon>
                <DirectionsBoatOutlined fontSize="small" />
              </ListItemIcon>
              {t("notification.boarding")}
              <Switch
                onChange={() => handleChangeNotification("new_boarding")}
                checked={user.selectedRoleDisplay.inform_new_boarding === 1}
              />
            </MenuItem>
          </Box>
        );
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Notifications settings">
          <Button
            variant="contained"
            onClick={(e) => {
              handleClick(e);
              setOpenAccountMenu(false);
              setOpenNotificationMenu(true);
            }}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openNotifications ? "notification-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openNotifications ? "true" : undefined}
          >
            <Notifications sx={{ width: 32, height: 32 }}></Notifications>
          </Button>
        </Tooltip>
        <Tooltip title="Account settings">
          <IconButton
            onClick={(e) => {
              handleClick(e);
              setOpenNotificationMenu(false);
              setOpenAccountMenu(true);
            }}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openAccountMenu ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openAccountMenu ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="notification-menu"
        open={openNotifications}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>Notifications</MenuItem>
        {showNotificationByCompanyType(company.company_type_id)}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openAccountMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar /> {user.credentials.email}
        </MenuItem>
        <Link
          to="/profile"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <LocationCityOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Mi empresa
          </MenuItem>
        </Link>
        <Divider />
        <Link to="/logout" style={{ textDecoration: "none", color: "inherit" }}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("menu.logout")}
          </MenuItem>
        </Link>
      </Menu>
    </React.Fragment>
  );
}
