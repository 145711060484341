import { GET_GROWER_DOCUMENT } from '../actions/index'
import Reservation from '../models/reservation'


interface GrowerDocumentReducerState {
    list: Reservation[],
    mustRefresh: boolean
}

const initialState:GrowerDocumentReducerState = { list: [], mustRefresh: false }

export default function GrowerDocumentReducer (state=initialState, action): GrowerDocumentReducerState {
    switch (action.type) {
        case GET_GROWER_DOCUMENT:
            //...state = el resto del state para no borrarlo
            return {
                ...state,
                list: action.payload,
                mustRefresh: false
            }
        default:
            return state
    }
}

