import * as React from "react";
import moment from "moment";
import "moment/locale/es";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TextareaAutosize,
  Divider,
  Button,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";
//Iconos
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import ShareIcon from "@mui/icons-material/Share";
import CloudDownloadRounded from "@mui/icons-material/CloudDownload";
import DeleteRounded from "@mui/icons-material/Delete";
import WarningRounded from "@mui/icons-material/Warning";
import CloseRounded from "@mui/icons-material/Close";

import { lighten, styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
//Solo para recuperar el documento
import { API_ENDPOINT } from "../constants";
import axios from "axios";
import GrowerDocumentUpload from "./grower-document-upload";

type ProcessDocumentRow = {
  id: number;
  code: string;
  uuid: string;
  exporterName: string;
  growerName: string;
  fechaPacking: Date;
  fileType: string;
  packingName: string;
  result: string;
  specieName: string;
  varietyName: string;
  newMessages: number;
  key: string;
};

interface IProcessDocumentView {
  mode: string;
  documents: [ProcessDocumentRow];
}

export default function ProcessDocumentsView(props: IProcessDocumentView) {
  //Según el modo, mostramos campos diferentes, la validación de estos (derecho de eliminar etc.) le
  //hará el backend
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] =
    React.useState<ProcessDocumentRow | null>(null);
  const [expanded, setExpanded] = React.useState(false);
  const [columns, setColumns] = React.useState<GridColDef<ProcessDocumentRow>[]>([]);
  const [rows, setRows] = React.useState<ProcessDocumentRow[]>([]);

  const getRowIcon = (fileType) => {
    switch (fileType) {
      case "pdf":
        return <PictureAsPdfIcon htmlColor="#fc5a55" />;
      default:
        return <ArticleIcon htmlColor="#10af59" />;
    }
  };

  const theme = useTheme();

  const GrowerProcessDataGrid = styled(DataGridPro)({
    "& .new-messages": {
      backgroundColor: lighten(theme.palette.warning.main, 0.7),
    },
  }) as typeof DataGridPro;

  React.useEffect(() => {
    setColumns([
      {
        field: "newMessages",
        headerName: "Mensajes",
        width: 75,
        hide: props.mode !== "exporter",
      },
      {
        field: "fileType",
        headerName: "",
        width: 45,
        renderCell: ({ value }: GridRenderCellParams<string>) => {
          return getRowIcon(value);
        },
      },
      { field: "code", headerName: t("boarding.grid.code"), width: 30 },
      {
        field: "fechaPacking",
        headerName: "Fecha de proceso",
        width: 150,
        valueGetter: ({
          row,
        }: GridValueGetterParams<string, ProcessDocumentRow>) => {
          const { fechaPacking } = row;
          return moment(fechaPacking).format("DD-MM-YYYY");
        },
      },
      {
        field: "packingName",
        headerName: t("boarding.grid.packingName"),
        width: 150,
      },
      {
        field: props.mode === "exporter" ? "growerName" : "exporterName",
        headerName: props.mode === "exporter" ? "Productor" : "Exportador",
        width: 150,
      },
      {
        field: "specieName",
        headerName: "Especie",
        width: 100,
      },
      {
        field: "varietyName",
        headerName: "Variedad",
        width: 100,
      },
      { field: "result", headerName: "Exportable", width: 100 },
    ]);

    setRows(props.documents);
  }, [props.mode, props.documents]);

  return (
    <React.Fragment>
      <Box height={"340px"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={7} height={"340px"}>
            <GrowerProcessDataGrid
              columns={columns}
              rows={rows}
              hideFooter={true}
              onRowClick={({ row }) => {
                setSelectedRow(row);
              }}
              getRowClassName={(params) => {
                return params.row.newMessages > 0 ? "new-messages" : "";
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={5} height={"340px"}>
            {selectedRow && (
              <Card sx={{ width: "100%" }}>
                <Box>
                  <IconButton onClick={() => setSelectedRow(null)}>
                    <CloseRounded />
                  </IconButton>
                </Box>
                <CardHeader
                  title={selectedRow.packingName + " #" + selectedRow.code}
                  subheader={moment(selectedRow.fechaPacking)
                    .locale("es")
                    .format("D, MMMM YYYY")}
                  avatar={getRowIcon(selectedRow["fileType"])}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    <b>Publicado el</b>{" "}
                    {moment().locale("es").format("DD-MM-YY HH:mm")} <br />
                    <b>Proceso de </b>
                    {selectedRow.specieName} {selectedRow.varietyName} en{" "}
                    {selectedRow.packingName} para {selectedRow.exporterName}{" "}
                    <br />
                    <b>Descargado el </b>
                    {moment().locale("es").format("DD-MM-YY")}{" "}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Tooltip title="Señalar un error">
                    {props.mode === "exporter" ? (
                      <IconButton
                        aria-label="Eliminar proceso"
                        color="error"
                        onClick={() => console.log("Delete" + selectedRow.id)}
                      >
                        <DeleteRounded />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="señalar un error"
                        color="warning"
                        onClick={() => setExpanded(!expanded)}
                      >
                        <WarningRounded />
                      </IconButton>
                    )}
                  </Tooltip>
                  <Tooltip title="Compartir documento">
                    <IconButton
                      aria-label="compartir"
                      color="info"
                      onClick={() => {
                        //Si tenemos el API Browser Share disponible, generamos la invit
                        if (navigator.share) {
                          navigator
                            .share({
                              title: "DaiHub: Proceso #" + selectedRow.code,
                              url:
                                "/modules/grower/process?uid=" + selectedRow.id,
                            })
                            .then(() => {})
                            .catch(console.error);
                        }
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Descargar documento">
                    <IconButton
                      aria-label="descargar"
                      color="success"
                      onClick={() => {
                        //Solicitar el link al API
                        axios
                          .get(
                            `${API_ENDPOINT}${"document/key/"}${
                              selectedRow.key
                            }${"?type=growerProcess"}`
                          )
                          .then(function (response) {
                            window.open(response.data.url, "_blank");
                          });
                      }}
                    >
                      <CloudDownloadRounded />
                    </IconButton>
                  </Tooltip>
                </CardActions>
                <Divider />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextareaAutosize
                          minRows={3}
                          placeholder="Su observación"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" color="success">
                          Enviar
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
