import React, { Component, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RESERVATION_STATE_SHIPPED } from "../constants";
import { selectReservation, getReservations } from "../actions/index";
import { RootState } from "../reducers/index";
import BookOutlined from "@mui/icons-material/BookOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Navigate, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Reservation from "../models/reservation";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

const DISPLAYED_RESERVATION_COUNT = 50;

interface ReservationListProps {
  displayStyle: string | undefined;
  displayCount: number | undefined;
  reservationStateFilter: string;
}

export default function ReservationList(props: ReservationListProps) {
  const displayStyle = props.displayStyle ?? "dash";
  const displayCount = props.displayCount ?? DISPLAYED_RESERVATION_COUNT;
  const reservationStateFilter = props.reservationStateFilter;
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedRole = useSelector(
    (state: RootState) => state.currentUser.selectedRole
  );
  const reservationList = useSelector(
    (state: RootState) => state.reservations.list
  );

  const mustRefresh = useSelector(
    (state: RootState) => state.reservations.mustRefresh
  );

  const [filter, setFilter] = useState(["shipped"]);
  const [reservation_id, setReservation_id] = useState(0);

  const isWithCustomFilter = filter.length > 0;
  let displayedReservations: Reservation[] = [];

  useEffect(() => {
    //@ts-ignore: Para thunk hay que tipear de forma especial los dispatch, todo pasar a Redux Toolkit
    dispatch(getReservations(selectedRole));
  }, [mustRefresh]);

  const getCurrentDateString = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
  };

  if (reservation_id > 0) {
    return <Navigate to={"/modules/packing/reservation/" + reservation_id} />;
  }

  if (reservationList.length > 0) {
    //Solamente mostrar los X primeros con el estado correspondiente
    let filteredArray = [...reservationList];

    if (typeof reservationStateFilter !== "undefined") {
      if (reservationStateFilter === "ultimas") {
        displayedReservations = filteredArray
          .sort((a, b) => a.id - b.id)
          .splice(0, displayCount);
        if (filter.indexOf("ultimas") != 0) {
          setFilter(["ultimas"]);
        }
      } else {
        displayedReservations = filteredArray
          .filter((r) => {
            return r.state_id === parseInt(reservationStateFilter);
          })
          .splice(0, displayCount);
      }
    } else {
      displayedReservations = filteredArray.splice(0, displayCount);
    }

    if (filter.length > 0) {
      //Aplicar filtros adicionales
      if (filter.indexOf("today") >= 0) {
        //Solamente lo de hoy
        displayedReservations = displayedReservations.filter((r) => {
          return (
            r.shipping_date.substring(0, 10) === this.getCurrentDateString()
          );
        });
      }
      if (filter.indexOf("shipped") >= 0) {
        //Solamente lo de hoy
        displayedReservations = displayedReservations.filter((r) => {
          return r.state_id !== RESERVATION_STATE_SHIPPED;
        });
      }
    }
  }

  let currentFilterDisplayName = () => {
    if (isWithCustomFilter) {
      let filterCode = filter[0];
      switch (filterCode) {
        case "today":
          return "Mostrar solo hoy";
        case "shipped":
          return "Ocultar despachadas";
        case "ultimas":
          return "Ultimas " + displayCount + " reservaciones";
        default:
          return "Not implemented";
      }
    }
  };

  const confirmedReservationCount = reservationList.filter(
    (x) => x.state_id > 1 && x.state_id < 4
  ).length;
  const pendingReservationCount = reservationList.filter(
    (x) => x.state_id <= 1
  ).length;
  const shippedReservationCount = reservationList.filter(
    (x) => x.state_id == 4
  ).length;

  const colorByState = (stateId) => {
    switch (stateId) {
      case 1:
        //Pendiente
        return "#1976d2";
      case 2:
        //Validado
        return "#4a6b46";
      case 3:
        //Contenedor en
        return "#628e5d";
      case 4:
        //Despachado
        return "#6fa068";
      default:
        return "#6c757d";
    }
  };

  const displayProgress = (stateId) => {
    const progressLeet = ["", "█", "█ █", "█ █ █", "█ █ █ █"];
    return progressLeet[stateId];
  };

  if (displayStyle === "table") {
    return (
      <div>
        <div className="flex-column my-3">
          <div className="flex-row">
            <div>
              <h4 style={{ color: colorByState(1), fontSize: "3vmin" }}>
                Pendientes: {pendingReservationCount}
              </h4>
            </div>
            <div>
              <h4 style={{ color: colorByState(2), fontSize: "3vmin" }}>
                Confirmadas: {confirmedReservationCount}
              </h4>
            </div>
            <div>
              <h4 style={{ color: colorByState(4), fontSize: "3vmin" }}>
                Despachadas: {shippedReservationCount}
              </h4>
            </div>
          </div>
        </div>

        <div className="flex-row">
          {!isWithCustomFilter && (
            <>
              <button
                type="button"
                className="btn btn-primary btn-sm button-blue"
                onClick={() => {
                  setFilter(["today"]);
                }}
              >
                Mostrar día en curso
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm button-blue"
                onClick={() => {
                  setFilter(["shipped"]);
                }}
              >
                Ocultar despachadas
              </button>
            </>
          )}
        </div>
        <Button
            variant="contained"
            LinkComponent={Link}
            onClick={() => navigate('/modules/packing/reservation/create')}
            color="primary"
          >
            <div className="flex-column" style={{ justifyContent: "center" }}>
              Nueva reservación
            </div>
          </Button>
        <table className="table table-striped table-sm  text-center table-light table-hover">
          {isWithCustomFilter && (
            <caption>Filtrado por {currentFilterDisplayName()}</caption>
          )}
          <thead>
            <tr>
              <th>Código</th>
              <th>Fecha de despacho</th>
              <th>Fecha de stacking</th>
              {window.innerWidth > 720 && <th>Exportador</th>}
              {window.innerWidth > 720 && <th>Despachador</th>}
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {displayedReservations.map((reservation) => {
              return (
                <tr key={reservation.id}>
                  <td>
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        color: colorByState(reservation.state_id),
                      }}
                      onClick={() => setReservation_id(reservation.id)}
                    >
                      <BookOutlined fontSize="small" />
                      {reservation.code}
                    </button>
                  </td>
                  <td>
                    {moment(reservation.shipping_date).format("DD-MM-YY HH:mm")}
                  </td>
                  <td>
                    {moment(reservation.stacking_date).format("DD-MM-YY HH:mm")}
                  </td>
                  {window.innerWidth > 720 && <td>{reservation.owner.name}</td>}
                  {window.innerWidth > 720 && (
                    <td>{reservation.shipper.name}</td>
                  )}

                  <td className="td-especial">
                    <div className="flex-column justify-content-center">
                      <span
                        style={{
                          border: "none",
                          background: "none",
                          color: colorByState(reservation.state_id),
                        }}
                      >
                        {displayProgress(reservation.state_id)}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {isWithCustomFilter && (
          <div>
            <div>
              <div className="col-12">
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  className="btn btn-primary btn-md button-blue"
                  onClick={() => setFilter([])}
                >
                  Ver todo
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else if (displayStyle === "resumen") {
    return (
      <div>
        <div className="flex-column my-3">
          <div
            className="flex-row"
            style={{
              borderWidth: "1px",
              borderStyle: "ridge",
              borderColor: "black",
              padding: "8px",
            }}
          >
            <h4 style={{ fontSize: "3vmin" }}>Reservaciones</h4>
          </div>
          <div className="flex-row">
            <div>
              <h4 style={{ color: colorByState(1), fontSize: "2.5vmin" }}>
                Pendientes
              </h4>
            </div>
            <div>
              <h4 style={{ color: colorByState(2), fontSize: "2.5vmin" }}>
                Confirmadas
              </h4>
            </div>
            <div>
              <h4 style={{ color: colorByState(4), fontSize: "2.5vmin" }}>
                Despachadas
              </h4>
            </div>
          </div>
          <div className="flex-row">
            <div>
              <h4 style={{ color: colorByState(1), fontSize: "3vmin" }}>
                {pendingReservationCount}
              </h4>
            </div>
            <div>
              <h4 style={{ color: colorByState(2), fontSize: "3vmin" }}>
                {confirmedReservationCount}
              </h4>
            </div>
            <div>
              <h4 style={{ color: colorByState(4), fontSize: "3vmin" }}>
                {shippedReservationCount}
              </h4>
            </div>
          </div>
          <table className="table table-striped table-sm  text-center table-light table-hover">
            {isWithCustomFilter && (
              <caption>{currentFilterDisplayName()}</caption>
            )}
            <thead>
              <tr>
                <th>Código</th>
                <th>Fecha de despacho</th>
                <th>Fecha de stacking</th>
                {window.innerWidth > 720 && <th>Exportador</th>}
                {window.innerWidth > 720 && <th>Despachador</th>}
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {displayedReservations.map((reservation) => {
                return (
                  <tr key={reservation.id}>
                    <td>
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          color: colorByState(reservation.state_id),
                        }}
                        onClick={() => setReservation_id(reservation.id)}
                      >
                        <BookOutlined fontSize="small" />
                        {reservation.code}
                      </button>
                    </td>
                    <td>
                      {moment(reservation.shipping_date).format(
                        "DD-MM-YY HH:mm"
                      )}
                    </td>
                    <td>
                      {moment(reservation.stacking_date).format(
                        "DD-MM-YY HH:mm"
                      )}
                    </td>
                    {window.innerWidth > 720 && (
                      <td>{reservation.owner.name}</td>
                    )}
                    {window.innerWidth > 720 && (
                      <td>{reservation.shipper.name}</td>
                    )}

                    <td className="td-especial">
                      <div className="flex-column justify-content-center">
                        {
                          //   <ReservationStateButton reservation={reservation} />{" "}
                        }
                        <span
                          style={{
                            border: "none",
                            background: "none",
                            color: colorByState(reservation.state_id),
                          }}
                        >
                          {displayProgress(reservation.state_id)}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="flex-row">
          <div>
            <h4 style={{ color: colorByState(1), fontSize: "2.5vmin" }}>
              Pendientes
            </h4>
          </div>
          <div>
            <h4 style={{ color: colorByState(2), fontSize: "2.5vmin" }}>
              Confirmadas
            </h4>
          </div>
          <div>
            <h4 style={{ color: colorByState(4), fontSize: "2.5vmin" }}>
              Despachadas
            </h4>
          </div>
        </div>
        <div className="flex-row">
          <div>
            <h4 style={{ color: colorByState(1), fontSize: "3vmin" }}>
              {pendingReservationCount}
            </h4>
          </div>
          <div>
            <h4 style={{ color: colorByState(2), fontSize: "3vmin" }}>
              {confirmedReservationCount}
            </h4>
          </div>
          <div>
            <h4 style={{ color: colorByState(4), fontSize: "3vmin" }}>
              {shippedReservationCount}
            </h4>
          </div>
        </div>

        <div className="flex-row">
          {displayedReservations.map((reservation) => {
            return (
              <div
                className={"card r-state-" + reservation.state_id}
                key={reservation.id}
              >
                <h5
                  className="card-header"
                  style={{ color: colorByState(reservation.state_id) }}
                >
                  <span>
                    <b>{reservation.code}</b>
                  </span>
                </h5>
                <div className="card-body">
                  <h5 className="card-title">
                    {reservation.owner.display_name ?? reservation.owner.name}
                  </h5>

                  <p className="card-text">
                    Stacking <br />
                    {moment(reservation.stacking_date).format(
                      "DD-MM-YY HH:mm"
                    )}{" "}
                    <br />
                  </p>
                  <button
                    className="btn btn-primary btn-sm button-blue"
                    onClick={() => setReservation_id(reservation.id)}
                  >
                    {" "}
                    Ver{" "}
                  </button>
                </div>
              </div>
            );
          })}
          <Button
            variant="contained"
            LinkComponent={Link}
            onClick={() => navigate('/modules/packing/reservation/create')}
            color="primary"
          >
            <div className="flex-column" style={{ justifyContent: "center" }}>
              <AddCircleOutlineIcon fontSize="large" />
              Nueva reservación
            </div>
          </Button>
        </div>
      </div>
    );
  }
}
