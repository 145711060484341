import React from 'react';
import { Link } from "react-router-dom";
import { Card, Typography } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

import '../app.css'

const CARD_PER_ROW = 5;

function AssetView({ assetModule }) {
    const theme = useTheme();
    const { t } = useTranslation();

    const assets = [
        { name: 'Packings', code: 'packing', icon: '' },
        { name: 'Exportadores', code: 'exporter', icon: '' },
        { name: 'Brokers (Clientes)', code: 'broker', icon: '' },
        { name: 'Notifies', code: 'notify', icon: '' },
        { name: 'Consignatarios', code: 'consignee', icon: '' },

        { name: 'Navieras', code: 'shippingCompany', icon: '' },
        { name: 'Agentes de aduana', code: 'customsAgent', icon: '' },
        { name: 'Agentes embarcadores', code: 'shippingAgent', icon: '' },
        { name: 'Agentes logisticos', code: 'forwarder', icon: '' },
        { name: 'Agentes de consolidacion', code: 'consolida', icon: '' },


        { name: 'Productores', code: 'grower', icon: '' },
        { name: 'Embalajes', code: 'packaging', icon: '' },
        { name: 'Variedades', code: 'variety', icon: '' },
        { name: 'Colores', code: 'color', icon: '' },
        { name: 'Calibres', code: 'size', icon: '' },

        { name: 'Empresas de transporte', code: 'carrier', icon: '' },
        { name: 'Depositos', code: 'deposit', icon: '' },
        { name: 'Naves', code: 'vessel', icon: '' },
        { name: 'Costos personalizados', code: 'cost', icon: '' },
    ]

    const buildAssetCards = () => {
        let display = [];
        let i = 0;
        while (i < assets.length) {
            const batch = assets.slice(i, i + CARD_PER_ROW);
            const batchDisplay = batch.map((a, index) => {
                return <Link to={'/modules/' + assetModule + '/asset/' + a.code} style={{ textDecoration: 'none' }}>
                    <Card key={index} sx={{
                        margin: '5px', alignSelf: 'center',
                        height: '150px', width: '220px',
                        backgroundColor: theme.palette.grey[50], color: theme.palette.text.primary,
                        borderRadius: '5px'
                    }}>
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>
                            {t('assets.' + a.code)}
                        </Typography>
                    </Card>
                </Link>
            });
            display.push(<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>{batchDisplay}</div>);
            i = i + CARD_PER_ROW;
        }

        return display;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            {buildAssetCards()}
        </div>)
}

export default AssetView;
