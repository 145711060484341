import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { exporterGetBoardingMessages, exporterSendBoardingMessages } from '../actions';
import { TextField, Button, Paper, Grid, Box } from "@mui/material";
import {
SendRounded
} from "@material-ui/icons";

import Moment from 'moment'

import '../exporter.css';

function ChatMessage({message, sentByMe, fecha, userDisplay}) {
    const classForSender = {
        background: (sentByMe == 1 && "rgb(37, 150, 190)") ||  "rgb(234,182,118)"
    }
    const MIN_LENGTH = 150;
    const messageLength = message.length * 24 > MIN_LENGTH ? message.length * 24 : MIN_LENGTH;
    return (<Paper sx={{ ...classForSender, width: messageLength, margin: 1, padding: 1 }}>

        {message}
        <br />
        <i style={{fontSize: 10}}>{Moment(fecha).format("YYYY-MM-DD HH:mm")}</i>
        <br />
        <i style={{fontSize: 10}}>{userDisplay}</i>
        </Paper>)
}

function ExporterChat({ boardingId }) {
    const dispatch = useDispatch();
    const messagesEndRef = useRef(null);
    const messageField = useRef(null);

    //Recuperamos la empresa corriente
    const companyId = useSelector(state => state.currentUser.selectedRoleDisplay.company.id);
    //Recuperamos el id del usuario en curso
    const currentUserId = useSelector(state => state.currentUser.id);
    //Recuperamos los mensajes
    const boardingMessages = useSelector(state => state.boarding.messages) || []; 

    //Cargamos los mensajes al iniciar el
    useEffect(() => loadMessages(), [boardingId]);

    const loadMessages = () => {
        dispatch(exporterGetBoardingMessages(companyId, boardingId));
    }

    const sendMessage = (message) => {
        dispatch(exporterSendBoardingMessages(companyId, boardingId, message));
    }

    /* Scroll hasta el final de la conversación */
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
 
    return (<Paper>
        <Grid container>
            {/* Conversación */}
            <Grid item sm={8}>
                <Box height={200} border={0.3} borderRadius={1} marginBottom={1}   style={{
                    maxHeight: "100vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    overflow: "auto",
                }}>
                    {
                        boardingMessages.map((x, i) => {
                            return  <ChatMessage key={i} 
                            message={x.message} 
                            sentByMe={x.sent_by_id == currentUserId} 
                            fecha={x.sentDate}
                            userDisplay={x.sent_by.email}/>
                        })
                    }
                    <div ref={messagesEndRef} />
                </Box>
            </Grid>
        </Grid>
        <Grid container>
            {/* Nuevo mensaje */}
            <Grid item sm={7.3}>
            <TextField fullWidth
                label="Mensaje" variant="outlined"
                type="text"
                onInput={(e) => {
                    const newValue = e.target.value;
                }}
                inputRef={messageField}
                inputProps={{ maxLength: "100" }}
                />
            </Grid>
            <Grid item sm={0.7} alignItems="stretch" style={{ display: "flex" }}>
                <Button variant='contained' onClick={() => 
                    sendMessage(messageField.current.value)
                    } ><SendRounded /></Button>
            </Grid>
        </Grid>
    </Paper>)
}

export default ExporterChat;
