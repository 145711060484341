import React, { useEffect, useState } from 'react'
import { TextField, InputLabel, Button, MenuItem, FormControl } from "@mui/material";
import { API_ENDPOINT } from '../constants'
import { exporterSetAsset, exporterGetAsset, exporterDeleteAsset, getSpecies } from '../actions';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios'
import '../app.css'


function Variety({ companyId, companyName, companyType }) {
    const [newAsset, setNewAsset] = useState({});
    const dispatch = useDispatch();

    const assets = useSelector(state => state.assets.varieties) || [];
    const species = useSelector(state => state.assets.species) || [];
    useEffect(() => {
      dispatch(getSpecies());
      dispatch(exporterGetAsset('variety', companyId));
    }, [companyId]);

    const saveAsset = () => {
      dispatch(exporterSetAsset('variety', newAsset, companyId));
    }

    const deleteAsset = (id) => {
      dispatch(exporterDeleteAsset('variety', id, companyId));
    }

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField fullWidth id="filled-basic"
                        inputProps={{ maxLength: 15 }}
                        label="Código" variant="filled"
                        style={{ margin: '4px' }}
                        value={newAsset.code || ''}
                        onInput={(e) => {
                            const newValue = e.target.value;
                            setNewAsset({
                                ...newAsset,
                                code: newValue
                            })
                        }}
                    />
                    <TextField fullWidth id="filled-basic"
                        label="Nombre" variant="filled"
                        style={{ margin: '4px' }}
                        value={newAsset.name || ''}
                        onInput={(e) => {
                            const newValue = e.target.value;
                            setNewAsset({
                                ...newAsset,
                                name: newValue
                            })
                        }}
                    />
                    <TextField select
                        id="specie-select"
                        requiered="true"
                        value={newAsset.specie_id || ''}
                        label="Especie"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            setNewAsset({
                                ...newAsset,
                                specie_id: newValue
                            })
                        }}
                    >
                        {species.map((e) => {
                            return <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                        })}
                    </TextField>

                    <Button variant="contained" color="success" onClick={() => { saveAsset(); }}>Crear</Button>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                        <th>Especie</th>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assets.map((a, i) => {
                                return <tr key={i}>
                                  <td>{a.specie.name}</td>
                                    <td>{a.code}</td>
                                    <td>{a.name}</td>
                                    <td><Button variant="contained" color="warning" onClick={() => { deleteAsset(a.id); }}>X</Button></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </>
        )
}

export default Variety;
