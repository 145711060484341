import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SELECT_ROLE,
  CHANGE_NOTIFICATIONS,
  SET_PERIOD_ID,
} from "../actions/index";

const initialState = {
  isConnected: false,
  credentials: {},
  selectedRole: -1,
  periodId: null,
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isConnected: true,
        credentials: action.payload,
      };
    case SET_PERIOD_ID:
      return {
        ...state,
        periodId: action.payload,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isConnected: false,
        credentials: {
          error: "No existe el usuario o la contraseña es invalida.",
        },
      };

    case SELECT_ROLE:
      return {
        ...state,
        selectedRole: action.payload.id,
        selectedRoleDisplay: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isConnected: false,
        credentials: {},
      };

    case CHANGE_NOTIFICATIONS:
      return {
        ...state,
        selectedRoleDisplay: {
          ...state.selectedRoleDisplay,
          inform_new_document: action.payload.inform_new_document,
          inform_new_boarding: action.payload.inform_new_boarding,
          inform_new_reservation: action.payload.inform_new_reservation,
        },
      };
    default:
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {};

      const selectedRole = localStorage.getItem("selectedRole")
        ? JSON.parse(localStorage.getItem("selectedRole"))
        : {};

      return {
        ...state,
        isConnected: user.id,
        credentials: user,
        selectedRole: (selectedRole && selectedRole.id) || -1,
        selectedRoleDisplay: selectedRole,
      };
  }
}
