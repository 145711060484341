import React from "react";
import { Route, useParams } from "react-router-dom";

import RequireAuth from "./router_require_auth";
import RequirePayment from "./router_require_payment";
//Module exporter
import ExporterMain from "./../containers/exporter-main";
import ExporterIndex from "./../containers/exporter-index";
import ExporterTracking from "./../containers/exporter-tracking";

import ExporterBoarding from "./../containers/exporter-boarding";
import BoardingWizard from "./../containers/exporter-boarding-create";
import BoardingReport from "./../components/exporter-boarding-report";
import BoardingBill from "./../components/exporter-boarding-bill";

import ExporterBoardingDocuments from "./../containers/exporter-boarding-documents";
//Assets
import AssetView from "./../containers/exporter-asset-view";
import Grower from "./../containers/exporter-asset-grower";
import Packaging from "./../containers/exporter-asset-packaging";
import Variety from "./../containers/exporter-asset-variety";
import Customer from "./../containers/exporter-asset-customer";
import ShippingCompany from "./../containers/exporter-asset-shippingCompany";
import CustomsAgent from "./../containers/exporter-asset-customsAgent";
import Exporter from "./../containers/exporter-asset-exporter";
import Packings from "./../containers/exporter-asset-packings";
import Consignee from "./../containers/exporter-asset-consignee";
import ShippingAgent from "./../containers/exporter-asset-shippingAgent";
import Size from "./../containers/exporter-asset-size";
import Color from "./../containers/exporter-asset-color";
import Cost from "./../containers/exporter-asset-cost";
import Vessel from "./../containers/exporter-asset-vessel";
import Carrier from "./../containers/exporter-asset-carrier";
import Consolida from "./../containers/exporter-asset-consolida";
import Forwarder from "./../containers/exporter-asset-forwarder";
import Notify from "./../containers/exporter-asset-notify";
import Deposit from "./../containers/exporter-asset-deposit";

const EXPORTER_LICENSE_TYPE = "export_lic";

export default (
  <Route path="/modules/exporter">
    <Route
      index
      element={
        <RequireAuth>
          <RequirePayment licenseType={EXPORTER_LICENSE_TYPE}>
            <ExporterMain>
              <ExporterIndex />
            </ExporterMain>
          </RequirePayment>
        </RequireAuth>
      }
    />

    <Route path="asset">
      <Route
        index
        element={
          <RequireAuth>
            <ExporterMain>
              <AssetView assetModule={'exporter'} />
            </ExporterMain>
          </RequireAuth>
        }
      />

      <Route
        path=":asset_type"
        element={
          <RequireAuth>
            <ExporterMain>
              <ExporterAssetSwitch />
            </ExporterMain>
          </RequireAuth>
        }
      />
    </Route>

    <Route path="boarding">
      <Route
        index
        element={
          <RequireAuth>
            <RequirePayment licenseType={EXPORTER_LICENSE_TYPE}>
              <ExporterMain>
                <ExporterBoarding />
              </ExporterMain>
            </RequirePayment>
          </RequireAuth>
        }
      />
      <Route
        path="create"
        element={
          <RequireAuth>
            <RequirePayment licenseType={EXPORTER_LICENSE_TYPE}>
              <ExporterMain>
                <BoardingWizard />
              </ExporterMain>
            </RequirePayment>
          </RequireAuth>
        }
      />
      <Route
        path=":boardingId/edit"
        element={
          <RequireAuth>
            <RequirePayment licenseType={EXPORTER_LICENSE_TYPE}>
              <ExporterMain>
                <BoardingViewer format={"edit"} />
              </ExporterMain>
            </RequirePayment>
          </RequireAuth>
        }
      />
      <Route
        path=":boardingId/print"
        element={
          <RequireAuth>
            <BoardingViewer format={"print"} />
          </RequireAuth>
        }
      />

      <Route
        path=":boardingId/bill"
        element={
          <RequireAuth>
            <BoardingViewer format={"bill"} />
          </RequireAuth>
        }
      />

      <Route
        path=":boardingId/documents"
        element={
          <RequireAuth>
            <BoardingViewer format={"documents"} />
          </RequireAuth>
        }
      />
    </Route>

    <Route
      path="buyOrders"
      element={
        <RequireAuth>
          <ExporterMain>
            <div>Pedidos.</div>
          </ExporterMain>
        </RequireAuth>
      }
    />

    <Route
      path="tracking"
      element={
        <RequireAuth>
          <ExporterMain>
            <ExporterTracking />
          </ExporterMain>
        </RequireAuth>
      }
    />
    <Route
      path="shippings"
      element={
        <RequireAuth>
          <ExporterMain>
            <div>Shippings</div>
          </ExporterMain>
        </RequireAuth>
      }
    />

    <Route
      path="calendars"
      element={
        <RequireAuth>
          <ExporterMain>
            <div>Calendarios</div>
          </ExporterMain>
        </RequireAuth>
      }
    />

    <Route
      path="ext"
      element={
        <RequireAuth>
          <ExporterMain>
            <div>Ext.</div>
          </ExporterMain>
        </RequireAuth>
      }
    />
  </Route>
);

function ExporterAssetSwitch() {
  let { asset_type } = useParams();
  const selectedRole = localStorage.getItem("selectedRole")
    ? JSON.parse(localStorage.getItem("selectedRole"))
    : {};

  switch (asset_type) {
    case "grower":
      return <Grower companyId={selectedRole.company.id} />;
    case "packaging":
      return <Packaging companyId={selectedRole.company.id} />;
    case "variety":
      return <Variety companyId={selectedRole.company.id} />;
    case "broker":
      return <Customer companyId={selectedRole.company.id} />;
    case "packing":
      return <Packings companyId={selectedRole.company.id} />;
    case "exporter":
      return <Exporter companyId={selectedRole.company.id} />;
    case "customsAgent":
      return <CustomsAgent companyId={selectedRole.company.id} />;
    case "shippingCompany":
      return <ShippingCompany companyId={selectedRole.company.id} />;
    case "consignee":
      return <Consignee companyId={selectedRole.company.id} />;
    case "shippingAgent":
      return <ShippingAgent companyId={selectedRole.company.id} />;
    case "size":
      return <Size companyId={selectedRole.company.id} />;
    case "color":
      return <Color companyId={selectedRole.company.id} />;
    case "cost":
      return <Cost companyId={selectedRole.company.id} />;
    case "vessel":
      return <Vessel companyId={selectedRole.company.id} />;
    case "carrier":
      return <Carrier companyId={selectedRole.company.id} />;
    case "consolida":
      return <Consolida companyId={selectedRole.company.id} />;
    case "forwarder":
      return <Forwarder companyId={selectedRole.company.id} />;
    case "notify":
      return <Notify companyId={selectedRole.company.id} />;
    case "deposit":
      return <Deposit companyId={selectedRole.company.id} />;
    default:
      return <AssetView />;
  }
}

function BoardingViewer({ format }) {
  let { boardingId } = useParams();
  switch (format) {
    case "edit":
      return <BoardingWizard boardingId={boardingId} format={format} />;
    case "print":
      return <BoardingReport boardingId={boardingId} />;
    case "bill":
      return <BoardingBill boardingId={boardingId} />;
    case "documents":
      return <ExporterBoardingDocuments boardingId={boardingId} />;
    default:
      return <BoardingWizard boardingId={boardingId} format={format} />;
  }
}
