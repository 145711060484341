import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { exporterGetBoardings } from "../actions";
import GenericLoader from "../components/generic-loader";

import BoardingView from "../components/exporter-boarding-view";
import "../exporter.css";

function ExporterBoarding({ currentUser, maxView }) {
  const boardings = useSelector((s) => s.boarding.boardings);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const refreshBoardings = () => {
    if (currentUser.periodId != null) {
      setLoading(true);
      dispatch(
        exporterGetBoardings(
          currentUser.selectedRoleDisplay.company.id,
          () => {
            setLoading(false);
          },
          currentUser.periodId
        )
      );
    }
  }

  useEffect(() => {
    refreshBoardings();
  }, [currentUser.selectedRoleDisplay.company.id, currentUser.periodId]);

  return (
    <div>
      <GenericLoader
        title={"Recuperando instructivos..."}
        buttons={{ cancel: "Cancelar" }}
        openState={isLoading}
      />
      <BoardingView
        boardings={boardings}
        display={"full"}
        isLoaded={!isLoading}
        refreshBoardings={refreshBoardings}
      />
    </div>
  );
}

function mapStateToProps(store) {
  return {
    currentUser: store.currentUser,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExporterBoarding);
