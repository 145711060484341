import { ACTION_TYPE_SELECT_RESERVATION, SET_RESERVATION_OK } from '../actions/index'

export default function ActiveReservationReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_TYPE_SELECT_RESERVATION:
            return action.payload;
        case SET_RESERVATION_OK:
            console.log(state)
            console.log(action.payload)
            if (state.id === action.payload.id) {
                return action.payload
            }
            else {
                return state
            }
        default:
            return state;
    }
}