import {
  SET_LAYOUT,
  OPEN_ERROR_MESSAGE,
  CLOSE_ERROR_MESSAGE,
  SET_APP_BAR_OPTIONS,
} from "../actions/index";

const initialState = {
  type: "menu",
  error: null,
  appBarOptions: []
};

export default function LayoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LAYOUT:
      return {
        ...state,
        type: action.payload,
      };
    case OPEN_ERROR_MESSAGE:
      console.log("Received error", action);
      return {
        ...state,
        error: action.payload,
      };
    case CLOSE_ERROR_MESSAGE:
      return {
        ...state,
        error: null,
      };

    case SET_APP_BAR_OPTIONS:
      return {
        ...state,
        appBarOptions: action.payload,
      }
    default:
      return state;
  }
}
