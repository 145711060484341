import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { API_ENDPOINT } from "../constants";
import BoardingView from "../components/broker-boarding-view";
import GenericLoader from "../components/generic-loader";
import axios from "axios";
import "../exporter.css";
import { waitOnResponse } from "../actions/utils";

function BrokerBoarding({ currentUser, maxView }) {
  const [boardings, setBoardings] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => loadBoardings(), []);

  const loadBoardings = () => {
    setLoading(true);
    axios
      .get(
        `${API_ENDPOINT}${"broker/company/"}${
          currentUser.selectedRoleDisplay.company.id
        }${"/async/boarding"}${"?format=view-broker&limit="}${maxView || 1000}`
      )
      .then(function (response) {
        waitOnResponse(response.data, (result) => {
          setLoading(false);
          setBoardings(result || []);
        });
      })
      .catch(() => {
        setBoardings([]);
      });
  };

  return (
    <div>
      <GenericLoader
        title={"Loading boardings..."}
        buttons={{ cancel: "Cancelar" }}
        openState={isLoading}
      />

      <BoardingView
        boardings={boardings}
        display={"full"}
        isLoaded={!isLoading}
      />
    </div>
  );
}

function mapStateToProps(store) {
  return {
    currentUser: store.currentUser,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BrokerBoarding);
