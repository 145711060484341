import React, { Component } from 'react'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import '../app.css'

class DocumentFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyEntries: this.props.companyEntries,
            searchQuery: this.props.searchQuery,
            onRefreshResult: this.props.onRefreshResult
        }
    }

    refreshCompanies(companies) {
        this.setState({
            ...this.state,
            companyEntries: companies
        })
    }

    render() {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Empresa</th>
                        <th>CSG</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <select id="typeFilter" className="form-control" onChange={(e) => {
                                this.setState({ ...this.state, searchQuery: { ...this.state.searchQuery, company: e.target.value } })
                            }} value={this.state.searchQuery.company}>
                                <option key="" value="">---</option>
                                {
                                    this.state.companyEntries.map((item) => {
                                        if (item.key === this.state.searchQuery.company) {
                                            //Selecionnado
                                            return <option selected key={item.key} value={item.key}> {item.display} </option>
                                        }
                                        else {
                                            return <option key={item.key} value={item.key}> {item.display} </option>
                                        }
                                    })
                                }
                            </select>
                        </td>
                        <td>
                            <input type="text" className="form-control" onInput={(e) => {
                                this.setState({ ...this.state, searchQuery: { ...this.state.searchQuery, csg: e.target.value } })
                            }} value={this.state.searchQuery.csg} />
                        </td>
                        <td>
                            <Button variant='contained' className="btn btn-primary" onClick={() => this.state.onRefreshResult({ company_id: this.state.searchQuery.company, csg: this.state.searchQuery.csg })} > <FontAwesomeIcon icon={faEye} /> </Button>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

export default DocumentFilter;