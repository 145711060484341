import React, { Component } from "react";
import { Link } from "react-router-dom";
import RoleSelect from "./role-select";
import { connect } from "react-redux";
import { getCompanies } from "../actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faSmileBeam } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Grid, TextField } from "@mui/material";
import {
    AddCircleOutlineRounded,
    EmailOutlined
} from "@material-ui/icons";

class RoleMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedCompany: {},
    };
  }

  componentDidMount() {
    //Recuperamos las empresas asociadas
    this.props.getCompanies(this.props.currentUser.credentials.companies);
  }

  render() {
    const enabledCompanies = this.props.currentUser.credentials.br_companies;
    if (enabledCompanies.length > 0) {
      return (
        <div className="main-display">
          <div className="flex-row-single-element">
            <RoleSelect />
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-display">
          <div className="flex-row">
            <div className="alert alert-success alert-text">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-1  py-3 ">
                    <div className="col  text-center">
                      {" "}
                      <FontAwesomeIcon
                        icon={faSmileBeam}
                        size="3x"
                        color="primary"
                      />
                    </div>
                  </div>
                  <div className="col-10 ">
                    <strong>Bienvenido </strong>
                    <div className="col-12 ">
                      {" "}
                      Su usuario no está asociado a ninguna empresa, para
                      continuar debe que crear su empresa o ingresar un código de invitación para integrar una empresa existante.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box className="container">
            <Grid container spacing={4}>
              <Grid item sm={2}>

              </Grid>
              <Grid item sm={4}>
                <Link
                  to={"/company/create"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button type="button" variant="contained" color="primary" sx={{minHeight: "50vh", minWidth: "30vh"}}>
                    Crear empresa <br />
                    <AddCircleOutlineRounded />
                  </Button>
                </Link>
              </Grid>
              <Grid item sm={4}>
                <Link
                  to={"/"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button type="button" variant="contained" color="secondary" sx={{minHeight: "50vh" , minWidth: "30vh"}}>
                    Tengo una invitación <EmailOutlined />
                  </Button>
                </Link>
              </Grid>
              <Grid item sm={2}>
                
                </Grid>
            </Grid>
          </Box>
        </div>
      );
    }
  }
}

function mapStateToProps(store) {
  return {
    currentUser: store.currentUser,
    activeReservation: store.activeReservation,
  };
}

const mapDispatchToProps = {
  getCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleMain);
