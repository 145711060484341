import React, { Component } from 'react'
import { connect } from 'react-redux'
import { API_ENDPOINT, RESERVATION_URL, DOC_PENDING, DOC_SENT, REGISTER_TYPE_URL } from '../constants'
import { selectReservation, getCompanies, getReservation } from '../actions/index'
import { Link } from "react-router-dom";
import CompanySelect from './company-select'
import Document from '../components/document'
import DatePicker from '../components/date-picker'
import moment from 'moment';
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { faExclamationTriangle, faCheckCircle, faBan } from "@fortawesome/free-solid-svg-icons"

class ReservationWizard extends Component {
    constructor(props) {
        super(props)
        this.state = {};
        if (this.props.editionMode === true) {
            if (this.props.reservationId) {
                //Obtener la reservación
                this.props.getReservation(this.props.reservationId, (response) => {
                    let activeReservation = response.data;
                    activeReservation.reservation_items = activeReservation.items.map((i) => { return i.item_code })
                    //Pasar los documentos en un Array
                    activeReservation.documents = []

                    //Asignar al state
                    this.setState({
                        reservation: activeReservation,
                        selectedDocumentType: '',
                        creationSuccess: false,
                        documentTypes: [],
                        step: 1,
                        errorMessage: '',
                        triggerRefresh: false
                    });
                });
            }
        }
        else {
            //Initializar
            this.state = {
                reservation: {
                    code: '',
                    shipper_id: -1,
                    stacking_date: '',
                    shipping_date: '',
                    reservation_items: [],
                    owner_id: -1,
                    creation_date: '',
                    state_id: 1,
                    documents: []
                },
                selectedDocumentType: '',
                creationSuccess: false,
                documentTypes: [],
                step: 1,
                errorMessage: '',
                triggerRefresh: false
            }
        }
    }

    componentWillMount() {
        //Query tipo de documentos
        var self = this
        axios.get(`${API_ENDPOINT}${REGISTER_TYPE_URL}`)
            .then(function (response) {
                self.setState({
                    ...self.state,
                    documentTypes: response.data
                })
            })

        this.props.getCompanies(this.props.currentUser.credentials.companies)
    }

    handleReservationCreateRequest() {
        let self = this
        if (this.state.step === 1) {
            if (this.props.editionMode !== true) {
                //Crear y recuperar el id
                axios.post(`${API_ENDPOINT}${RESERVATION_URL}`,
                    this.state.reservation)
                    .then(function (response) {
                        self.setState({
                            ...self.state,
                            reservation: {
                                ...self.state.reservation,
                                id: response.data.id
                            },
                            step: 2,
                            errorMessage: ''
                        })
                    }).catch((error) => {
                        self.setState({
                            ...self.state,
                            errorMessage: error.response.data.error
                        })
                    })
            }
            else {
                //En modo de edicion, solo pasar a fase dos
                this.setState({ ...this.state, step: 2 })
            }
        }
        else if (this.state.step === 2) {
            //Completar con el id
            axios.post(`${API_ENDPOINT}${RESERVATION_URL}`,
                this.state.reservation)
                .then(function (response) {
                    self.setState({
                        ...self.state,
                        creationSuccess: true,
                        step: 3,
                        errorMessage: ''
                    })
                }).catch((error) => {
                    self.setState({
                        ...self.state,
                        errorMessage: error.response.data.error
                    })
                })
        }
    }

    onChange(e, key = null) {
        switch (e.target.id) {
            case 'reservationCode':
                this.setState({
                    ...this.state,
                    reservation: {
                        ...this.state.reservation,
                        code: e.target.value
                    }
                })
                break;
            case 'companySelect':
                switch (key) {
                    case 'shipperId':
                        this.setState({
                            ...this.state,
                            reservation: {
                                ...this.state.reservation,
                                shipper_id: e.target.value
                            }
                        })
                        break;
                    case 'onwerId':
                        this.setState({
                            ...this.state,
                            reservation: {
                                ...this.state.reservation,
                                owner_id: e.target.value
                            }
                        })
                        break;
                    default:
                        break;
                }
                break;
            case 'stackingDate':
                console.log(e.target.value)
                this.setState({
                    ...this.state,
                    reservation: {
                        ...this.state.reservation,
                        stacking_date: e.target.value + ":00"
                    }
                })
                break;
            case 'shippingDate':
                this.setState({
                    ...this.state,
                    reservation: {
                        ...this.state.reservation,
                        shipping_date: e.target.value + ":00"
                    }
                })
                break;
            case 'sps':
                this.setState({
                    ...this.state,
                    reservation: {
                        ...this.state.reservation,
                        sps: e.target.value
                    }
                })
                break;
            case 'dus':
                this.setState({
                    ...this.state,
                    reservation: {
                        ...this.state.reservation,
                        dus: e.target.value
                    }
                })
                break;
            case 'booking':
                this.setState({
                    ...this.state,
                    reservation: {
                        ...this.state.reservation,
                        booking: e.target.value
                    }
                })
                break;
            default:
                break;
        }
    }

    handleAddDocument() {
        if (this.state.selectedDocumentType.length > 0) {
            //Id del tipo de documento
            let selectedDocumentTypeId = parseInt(this.state.selectedDocumentType)
            //Descripción asociada
            let selectDocumentTypeName = this.state.documentTypes.filter((type) => {
                return type.id === selectedDocumentTypeId
            })[0].display_name

            this.setState({
                ...this.state,
                reservation: {
                    ...this.state.reservation,
                    documents: [
                        ...this.state.reservation.documents,
                        {
                            type: selectDocumentTypeName,
                            type_id: selectedDocumentTypeId,
                            state: DOC_PENDING,
                            name: '',
                            key: this.state.reservation.documents.length,
                            file: {}
                        }
                    ]
                }
            })
        }
    }

    handleFileChanged(event, documentKey) {
        //Selecionamos el archivo
        var document = this.state.reservation.documents.find((doc) => { return doc.key === documentKey })
        document.file = event.target.files[0]
    }

    handleFileUpload(documentKey) {
        var self = this
        var formData = new FormData();
        var document = this.state.reservation.documents.find((doc) => { return doc.key === documentKey })

        formData.append("doc", document.file);
        formData.append("doc_type_id", document.type_id);
        formData.append("doc_key", document.key);
        formData.append("reservation_id", this.state.reservation.id);

        axios.post(`${API_ENDPOINT}${'document'}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            //Asignar la key al documento en curso
            if (response.status === 200) {
                document.key = response.data.key
                document.state = DOC_SENT
            }
            else {
                //Mostrar error de upload
            }

            self.setState({ ...self.state, triggerRefresh: true })
        })
    }

    handleAddPayload(event) {
        if (this.state.reservation.reservation_items.includes(event.target.value)) {
            //Ya está ingresado, no agregarlo de nuevo
            this.setState({
                ...this.state,
                errorMessage: "Pallet " + event.target.value + " ya ingresado."
            })
        }
        else {
            //Agregamos al listado
            this.setState({
                ...this.state,
                reservation: {
                    ...this.state.reservation,
                    reservation_items: [...this.state.reservation.reservation_items, event.target.value]
                },
                errorMessage: ''
            })
        }
        //Reset
        event.target.value = ""
    }

    render() {
        const handlePayloadPaste = (pasted) => {
          const items = pasted.split('\r\n');
          var folios = [];
          for(let i in items) {
            const folio = items[i];
            if (folio.length > 0) {
              if (this.state.reservation.reservation_items.includes(folio)) {
                  //Ya está ingresado, no agregarlo de nuevo
                  this.setState({
                      ...this.state,
                      errorMessage: "Pallet " + folio + " ya ingresado."
                  })
              }
              else {
                  //Agregamos al listado
                  folios.push(folio);
              }
            }
          }

          this.setState({
              ...this.state,
              reservation: {
                  ...this.state.reservation,
                  reservation_items: [...this.state.reservation.reservation_items, ...folios]
              },
              errorMessage: ''
          })
        }

        return (
            <form>
                {
                    this.state.errorMessage != null &&
                    this.state.errorMessage.length > 0
                    &&
                    <div>
                        <div className="alert alert-danger alert-text my-3" role="alert">
                            <div class="col-12 ">
                                <div class="row">
                                    <div class="col-2  my-3 "><div class="col-2  text-center">   <FontAwesomeIcon icon={faBan} size="2x" color="#d32f2f" /></div></div>
                                    <div class="col-10 "><strong><span>&nbsp;&nbsp;</span> </strong>
                                        <div class="col-12 ">{this.state.errorMessage}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.creationSuccess
                    && <div>
                        <div className="alert alert-success alert-text my-3" role="alert">
                            <div class="col-12 ">
                                <div class="row">
                                    <div class="col-2  my-3 "><div class="col-2  text-center">   <FontAwesomeIcon icon={faCheckCircle} size="2x" color="#00986a" /></div></div>
                                    <div class="col-10 "><strong><span>&nbsp;&nbsp;</span> </strong>
                                        <div class="col-12 ">{"Reservación creada con éxito."} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.step === 1 &&
                    <div class="col-12  text-start my-2">
                        <div class="row">
                            <div class="col-6  py-2">
                                <div className="form-group ">
                                    <p class="fs-6">Código reserva</p>
                                    <div class="col-10">
                                        <input type="text" maxLength='30' className="form-control form-control-sm"
                                            id="reservationCode" aria-describedby="reservationCodeHelp"
                                            placeholder="Ingresar código de reserva"
                                            onChange={(event) => this.onChange(event)}
                                            value={this.state.reservation.code} />
                                    </div>
                                    <small id="reservationCodeHelp" className="form-text text-muted">Este código identificará su reserva en los siguientes procesos</small>
                                </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-3"></div>
                        </div>
                        <div class="row">
                            <div class="col-6 my-3 ">
                                <div className="form-group">
                                    <div class="col-10">
                                        <p class="fs-6">Exportador</p>
                                        <CompanySelect onChange={this.onChange.bind(this)} companyTypeId={1}
                                            companySelectKey='onwerId' currentValue={this.state.reservation.owner_id} />
                                        <small id="onwerId" className="form-text text-muted">Exportador asociado a la reserva</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 my-3">
                                <div className="form-group">
                                    <div class="col-10">
                                        <p class="fs-6">Asignado a</p>
                                        <CompanySelect onChange={this.onChange.bind(this)} companyTypeId={2}
                                            companySelectKey='shipperId' currentValue={this.state.reservation.shipper_id} />
                                        <small id="shipperIdHelp" className="form-text text-muted">Empresa responsable de preparar la carga</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6  py-2 my-3">
                                <div className="form-group">
                                    <p class="fs-6">Fecha y Hora de stacking</p>
                                    <div class="col-10">
                                        <input type='datetime-local' className='form-control form-control-sm' id='stackingDate'
                                            aria-describedby='stackingDateHelp'
                                            onChange={(event) => this.onChange(event)}
                                            value={this.state.reservation.stacking_date} />
                                        <small id="stackingDateHelp" className="form-text text-muted">Fecha y hora de reservación de stacking, permite priorizar las reservas</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6  py-2 my-3">
                                <div className="form-group">
                                    <div class="col-10">
                                        <p class="fs-6">Fecha y Hora de carga</p>
                                        <DatePicker onChange={this.onChange.bind(this)} id="shippingDate" companyId={this.state.reservation.shipper_id}
                                            selectedDateTime={this.state.reservation.shipping_date} />
                                        <small id="shippingDateHelp" className="form-text text-muted">Fecha y hora de carga según disponibilidad</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12  py-2">
                            <div className="form-group">
                                <button type='button' className='btn btn-primary btn-sm button-blue' onClick={() => this.handleReservationCreateRequest()}> Siguiente </button>
                            </div>
                        </div>

                    </div>
                }
                {
                    this.state.step === 2 &&

                    <div class="col-12  text-start my-2">

                        <div class="row">
                            <div class="col-6  my-3">
                                <div className="form-group">
                                    <p class="fs-6">Documento Único de Salida</p>
                                    <div class="col-10">
                                        <input type='text' className='form-control form-control-sm' id='dus'
                                            aria-describedby='dusHelp' onChange={(event) => this.onChange(event)}
                                            value={this.state.reservation.dus} />
                                        <small id="dusHelp" className="form-text text-muted">Código del documento único de salida</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6  my-3">
                                <div className="form-group">
                                    <p class="fs-6">Numero de planilla SPS</p>
                                    <div class="col-10">
                                        <input type='number' className='form-control form-control-sm' id='sps'
                                            aria-describedby='spsHelp' onChange={(event) => this.onChange(event)}
                                            value={this.state.reservation.sps} />
                                        <small id="spsHelp" className="form-text text-muted">Número de solicitud</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 my-2">
                                <div className="form-group">
                                    <p class="fs-6">Booking</p>
                                    <div class="col-10">
                                        <input type='text' className='form-control form-control-sm' id='booking'
                                            aria-describedby='bookingHelp' onChange={(event) => this.onChange(event)}
                                            value={this.state.reservation.booking} />
                                        <small id="bookingHelp" className="form-text text-muted">Identificador de reserva</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 my-2">
                                <div className="form-group">
                                    <p class="fs-6">Carga</p>
                                    <div class="col-10">
                                        <input id="payloadAdd" type='text' maxLength='20' className='form-control form-control-sm' onKeyPress={
                                            (e) =>
                                            (e.key === 'Enter' ?
                                                this.handleAddPayload(e) : false)
                                        }
                                        onPaste={(e) =>  {
                                          handlePayloadPaste(e.clipboardData.getData('text/plain'));
                                        }}
                                        />
                                        <small id="shipperIdHelp" className="form-text text-muted">Validar cada ingreso con la tecla Ente, puede copiar y pegar varios pallets desde un archivo Excel.</small>
                                        <ul id='payloadDetail' className='list-group'>
                                            {
                                                this.state.reservation.reservation_items.map((pallet, k) => {
                                                    return (
                                                        <li className='list-group-item' key={k}> {pallet} </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <button type="button" className="btn btn-danger"
                                            onClick={(e) => {
                                                this.setState({
                                                    ...this.state, reservation: {
                                                        ...this.state.reservation,
                                                        reservation_items: []
                                                    }
                                                })
                                            }}
                                        > Vaciar listado </button>
                                    </div>
                                </div>
                                <div class="col-6  py-2">

                                </div>
                            </div>
                        </div>
                        <div clasName="row">
                            <div class="col-6 my-2">
                                <div className="form-group">
                                    <p class="fs-6">Documentos</p>
                                    <div class="col-10">
                                        <div className="input-group">
                                            <select id="documentType" className="form-select form-select-sm" aria-describedby='documentTypeHelp' onChange={(e) => this.setState({ selectedDocumentType: e.target.value })}>
                                                <option value={''}>---</option>
                                                {
                                                    this.state.documentTypes.map((state, k) => {
                                                        return (
                                                            <option value={state.id}> {state.display_name} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <button type='button' className="btn btn-success btn-sm" onClick={this.handleAddDocument.bind(this)}> <FontAwesomeIcon icon={faPlus} /> </button>
                                        </div>
                                        <small id="documentTypeHelp" className="form-text text-muted">Agregar los documentos (PDF) relativos a la reserva</small>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="form-group">
                            {
                                this.state.reservation.documents.length === 0 &&
                                <div className="alert alert-warning alert-text" role="alert">
                                    <div class="col-12 ">
                                        <div class="row">
                                            <div class="col-1  py-3 "><div class="col  text-center">   <FontAwesomeIcon icon={faExclamationTriangle} size="2x" color="#f57f17" /></div></div>

                                            <div class="col-10 "><strong><span>&nbsp;&nbsp;</span> </strong>
                                                <div class="col-12 ">{"No hay documentos asociados a la reservación."}  </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                this.state.reservation.documents.map((doc, k) => {
                                    return (
                                        <div className='input-group'>
                                            <Document key={k}
                                            documentName=""
                                            documentType={doc.type}
                                            documentState={doc.state}
                                            documentKey={doc.key}
                                            onFileChanged={this.handleFileChanged.bind(this)}
                                            onDelete={() => {}} />
                                            <button type='button' className='btn btn-danger'> <FontAwesomeIcon icon={faTrash} /> </button>
                                            {
                                                doc.state === DOC_PENDING &&
                                                <button type='button' className='btn btn-info' onClick={() => { this.handleFileUpload(doc.key) }} ><FontAwesomeIcon icon={faUpload} /></button>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <br />
                        <div className="form-group">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-2 ">
                                        <button type='button' className='btn btn-secondary btn-sm' onClick={() => this.setState({ ...this.state, step: 1 })}> Volver </button>
                                    </div>
                                    <div class="col-2 ">
                                        <button type='button' className='btn btn-success btn-sm'
                                            onClick={this.handleReservationCreateRequest.bind(this)}
                                            disabled={
                                                this.state.reservation.shipper_id === -1 ||
                                                this.state.reservation.owner_id === -1
                                            }>Crear </button>
                                    </div>
                                    <div class="col-2 "></div>
                                    <div class="col-2 "></div>
                                    <div class="col-2 "></div>
                                    <div class="col-2 "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.step === 3 && <div>
                        <div className="form-group text-center">
                            <Link className="btn btn-success btn-sm button-green" to={"/modules/packing/reservation/" + this.state.reservation.id}> Ver reservación</Link>
                        </div>
                    </div>
                }
            </form >
        );
    }
}

function mapStateToProps(store) {
    return {
        currentUser: store.currentUser,
        activeReservation: store.activeReservation
    }
}

const mapDispatchToProps = {
    selectReservation, getCompanies, getReservation
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationWizard)
