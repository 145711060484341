import React, { Component } from 'react';
import { connect } from 'react-redux'
import { doLogOut } from '../actions/index'

class LogoutForm extends Component {

    render() {
        delete localStorage['user']
        this.props.doLogOut()
        return (
            <div className="app-container">
                <div className="main-display">
                    <div className="alert alert-info" role="alert">
                        Se cerró su sesión.
                        <br />Lo estamos redireccionando...
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        currentUser: store.currentUser,
        activeReservation: store.activeReservation
    }
}

const mapDispatchToProps = {
    doLogOut
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutForm);